import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Typography, CircularProgress, Button, Paper, TextField, Collapse, Switch, InputAdornment, Snackbar, IconButton, FormControlLabel, Radio, Dialog, DialogTitle, DialogContent, DialogActions,
} from '@material-ui/core';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  ExpandMore, Pageview, ExpandLess, Close,
} from '@material-ui/icons';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { Link, useRouteMatch } from 'react-router-dom';
import {
  GroupIcon, AssetListItem, AdminInnerExpansionPanel, AdminOuterExpansionPanel,
} from '../components/views';
import { drawerWidth } from '../constants';
import { UPDATE_PROFILE, GET_MY_PROFILE } from '../graphql/profile';
import TruckProfile from '../images/truck.png';
import {
  getFullName, intervalUntil, capitalize, photoURL,
} from '../libs/helpers';
import { REPORT_QUERY, GET_REPORT_QUERY_RESPONSE } from '../graphql/reports';
import {
  GET_RECENT_SETTINGS, SAVE_ASSET_SETTINGS, GET_WHOLETREE_WHICHIS, SAVE_IOPORT_SETTINGS,
} from '../graphql/whichi';
import { CHANGE_MY_PASSWORD } from '../graphql/users';
import { HiddenInput } from '../components/input';
import { UPLOAD_FILE } from '../graphql/file';


const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    padding: 0,
    paddingRight: 48,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    marginTop: 64,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const ExpansionPanel = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
    alignItems: 'center',
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles({
  root: {
    padding: 0,
    paddingLeft: 12,
    flexDirection: 'column',
  },
})(MuiExpansionPanelDetails);

const Contents = styled.div`
  display: flex;
  height: calc(100vh - 64px);
`;

const ContentLeft = styled.div`
  width: 300px;
  background: #0E0F1A;
  height: 100%;
  border-left: 4px solid black;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 16px;
  }
  ::-webkit-scrollbar-track {
    background: #0E0F1A;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.4);
    width: 16px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 8px;
  }
`;

const ContentRight = styled.div`
  height: 100%;
  flex-grow: 1;
  background: #fdfdfd;
  padding: 48px;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const RightInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 960px;
  > .MuiPaper-root {
    width: 100%;
    margin-bottom: 48px;
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.4);
    border: 1px solid #999999;
    padding: 24px;
  }

  > h6 {
    margin-bottom: 24px;
  }

  > * {
    flex-shrink: 0;
  }
`;


const OuterExpansionPanel = styled(ExpansionPanel)`
  background-color: transparent;
  box-shadow: none;

  > div {
    > div:first-child {
      margin-left: 32px;
    }
    > div + div {
      color: white;
      color: white;
      margin-right: 0;
      position: absolute;
      left: 12px;
    }
  }
`;

const InputBlock = styled.div`
  margin-bottom: 24px;

  > div > label {
    color: #666;
  }

  > p {
    display: inline-flex;
  }

  > p:first-child {
    width: 20%;
    margin-right: 5%;
  }

  > p + * {
    width: 75%;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const InnerExpansionPanel = styled(ExpansionPanel)`
  background-color: transparent;
  box-shadow: none;
  width: 100%;

  > div {
    > div:first-child {
      margin-left: 32px;
    }
    > div + div {
      color: white;
      color: white;
      margin-right: 0;
      position: absolute;
      left: 12px;
    }
  }
`;

const SwitchesOuter = styled.div`
  width: 100%;
  padding-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Switches = styled.div`
  width: 100%;
  max-width: 640px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SwitchBlock = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #777;
  width: 100%;
  margin-top: 16px;

  > label {
    > span:first-child {
      > span:first-child {
        color: #777;
      }
      &.Mui-checked {
        > span:first-child {
          color: ${props => props.theme.palette.secondary.main};
        }
      }
    }
  }

  > * {
    flex-shrink: 0;
  }

  > p {
    flex-grow: 1;
  }

  > div > label, > div > div > div > p {
    color: #666;
  }
`;

const ReportHolder = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  > div {
    width: 22.5%;
  }
`;

const ReportPaper = styled(Paper)`
  padding: 24px;
`;

let isSettingInitialized = false;

const DEFAULT_SETTING = {
  report1_enable: true,
  report1_utc_on: true,
  report1_gps_on: true,
  report1_alt_on: false,
  report1_speed_on: true,
  report1_course_on: true,
  report1_period_time: 120,
  report1_io1_on: false,
  report1_io2_on: false,
  report1_io3_on: false,
  report1_io4_on: false,
  report2_enable: false,
  report2_utc_on: false,
  report2_gps_on: false,
  report2_alt_on: false,
  report2_speed_on: false,
  report2_course_on: false,
  report2_period_time: 120,
  report2_io1_on: false,
  report2_io2_on: false,
  report2_io3_on: false,
  report2_io4_on: false,
  report3_enable: false,
  report3_utc_on: false,
  report3_gps_on: false,
  report3_alt_on: false,
  report3_speed_on: false,
  report3_course_on: false,
  report3_period_time: 120,
  report3_io1_on: false,
  report3_io2_on: false,
  report3_io3_on: false,
  report3_io4_on: false,
  report4_enable: false,
  report4_utc_on: false,
  report4_gps_on: false,
  report4_alt_on: false,
  report4_speed_on: false,
  report4_course_on: false,
  report4_period_time: 120,
  report4_io1_on: false,
  report4_io2_on: false,
  report4_io3_on: false,
  report4_io4_on: null,
  port1_out_status: '0',
  port1_io_status: '00',
  port1_ADC: null,
  port2_out_status: '0',
  port2_io_status: '00',
  port2_ADC: null,
  port3_out_status: '0',
  port3_io_status: '00',
  port3_ADC: null,
  port4_out_status: '0',
  port4_io_status: '00',
  port4_ADC: null,
};


export default function SettingPage(props) {
  const {
    open, assets, loading, profileData, isRightAssetEnabled,
  } = props;
  const { url } = useRouteMatch();
  const classes = useStyles();
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [isInitialized, setInitialized] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [profileInput, setProfileInput] = useState({});
  const [changePassword, setChangePassword] = useState({ password: '', confirm: '', previous: '' });
  const [passwordChangeModal, setPasswordChangeModal] = useState(false);
  const [commitUpdateProfile, { loading: updateProfileLoading }] = useMutation(UPDATE_PROFILE);
  const [recentAssets, setRecentAssets] = useState(localStorage.getItem(`${profileData ? profileData.id : 'null'}whichiten-settings-recent`) ? JSON.parse(localStorage.getItem(`${profileData ? profileData.id : 'null'}whichiten-settings-recent`)) : []);
  const [currentUserId, setCurrentUserId] = useState(profileData ? profileData.id : null);
  const [selectedSetting, setSelectedSetting] = useState(null);
  const [onConfirmSaveSettings, setOnComfirmSaveSettings] = useState(null);
  const [onSaveSettingsLoading, setOnSaveSettingsLoading] = useState(false);

  useEffect(() => {
    if (currentUserId !== (profileData ? profileData.id : null)) {
      setCurrentUserId(profileData ? profileData.id : null);
      setRecentAssets(localStorage.getItem(`${profileData ? profileData.id : 'null'}whichiten-settings-recent`) ? JSON.parse(localStorage.getItem(`${profileData ? profileData.id : 'null'}whichiten-settings-recent`)) : []);
    }
  });

  const [getRecentSettings, {
    data: recentSettingsData,
  }] = useLazyQuery(GET_RECENT_SETTINGS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      // console.log(data);

      const { GetRecentestSetting: { recentestSetting: recentSetting, ok, error } } = data;

      if (error || !ok) {
        // console.log(error, ok);
        alert('Failed fetch settings data from server.');
        return;
      }

      setSelectedSetting(recentSetting);
    },
  });

  let isSettingChangedMemoizer = false;

  const [emitSaveAssetSettings] = useMutation(SAVE_ASSET_SETTINGS);
  const [
    commitChangePassword,
    {
      loading: changePasswordLoading,
    },
  ] = useMutation(CHANGE_MY_PASSWORD);

  const changePasswordInput = (value, key) => {
    setChangePassword({
      ...changePassword,
      [key]: value,
    });
  };


  const groupedAssets = {};
  const groups = {};

  useEffect(() => {
    if (profileData && !Object.keys(profileInput).length) {
      setProfileInput({
        ...profileData,
      });
      if (isSettingInitialized) {
        setSnackbarOpen('Successfully Changed.');
      }
      isSettingInitialized = false;
    }
  });

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  const changeProfileInput = (value, key) => {
    setProfileInput({
      ...profileInput,
      [key]: value,
    });
  };

  const addOrCreateToGroup = (asset, groupName, group) => {
    if (!groupedAssets[groupName]) {
      groupedAssets[groupName] = [];

      if (groupName !== 'Ungroup') {
        groups[groupName] = group;
      }
    }
    groupedAssets[groupName].push(asset);
  };

  if (!loading && assets) {
    assets.map((asset) => {
      if (!asset.whichigroup) {
        addOrCreateToGroup(asset, 'Ungroup');
      } else {
        addOrCreateToGroup(asset, asset.whichigroup.name, asset.whichigroup);
      }
    });
  }

  const closeChangePassword = () => {
    setChangePassword({ password: '', confirm: '', previous: '' });
    setPasswordChangeModal(false);
  };

  const applyChangePassword = async () => {
    if (!changePassword.password) return alert('No password entered.');
    const result = await commitChangePassword({
      variables: {
        userid: profileData.id,
        previousPassword: changePassword.previous,
        newPassword: changePassword.password,
        confirmPassword: changePassword.confirm,
      },
    });

    // console.log(result);

    if (result.data.ChangeMyPassword.ok) {
      closeChangePassword();
    } else {
      alert('Current password not correct. Please check again');
    }
  };

  const addRecent = (whichiId) => {
    const savedRecents = localStorage.getItem(`${profileData ? profileData.id : 'null'}whichiten-settings-recent`);
    const currentRecent = savedRecents ? JSON.parse(savedRecents) : [];

    const newRecent = currentRecent.filter(recent => recent !== whichiId);
    newRecent.push(whichiId);
    localStorage.setItem(`${profileData ? profileData.id : 'null'}whichiten-settings-recent`, JSON.stringify(newRecent.slice(-5)));
    setRecentAssets(newRecent.slice(-5));
  };

  const loadAsset = async (asset) => {
    setSelectedSetting(null);
    setSelectedAsset(asset);
    addRecent(asset.id);

    await getRecentSettings({
      variables: {
        imei: asset.imei,
      },
    });
  };

  const getRecentAssets = () => recentAssets.map(whichiId => assets.find(asset => asset.id === whichiId)).filter(asset => asset).reverse();

  const mapAssets = innerAssets =>
    // console.log(innerAssets);
    innerAssets.filter(asset => asset).map(asset => <AssetListItem selected={selectedAsset && selectedAsset.id === asset.id} onClick={() => loadAsset(asset)} variant="text">{asset.name}</AssetListItem>);
  const mapGroupedAssets = () =>
    // console.log(groupedAssets);
    Object.keys(groupedAssets).sort((prev, next) => prev === 'Ungroup' && 1 || next === 'Ungroup' && -1 || 0).map((groupKey) => {
      const currentGroup = groups[groupKey] || {};
      return (
        <InnerExpansionPanel>
          <ExpansionPanelSummary
            style={{ position: 'relative' }}
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <GroupIcon style={{ color: 'white', fontWeight: 'bold' }} color={currentGroup.color}>{currentGroup && currentGroup.name ? currentGroup.name.slice(0, 1).toUpperCase() : ''}</GroupIcon>
            <Typography color="textSecondary" variant="body1">{groupKey}<GroupIcon color="white" style={{ color: 'black', marginLeft: 8, fontSize: '0.7rem' }}>+{groupedAssets[groupKey].length}</GroupIcon></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            {
              mapAssets(groupedAssets[groupKey])
            }
          </ExpansionPanelDetails>
        </InnerExpansionPanel>
      );
    });
  const saveProfile = async () => {
    await commitUpdateProfile({
      variables: {
        ...profileInput,
      },
      refetchQueries: [{ query: GET_MY_PROFILE }],
    });
    isSettingInitialized = true;
    setProfileInput({});
  };

  const handleChangeSetting = (key, value) => {
    if (key && /utc_on|gps_on|speed_on|course_on/.test(key)) {
      alert('Warning: You\'re modifying significant settings.');
    }
    setSelectedSetting({
      ...selectedSetting,
      [key]: value,
    });
  };

  window.selectedSetting = selectedSetting;
  const mapReports = reportNumber => (
    <ReportPaper>
      <Typography
        style={{
          display: 'flex',
          position: 'relative',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        Report{reportNumber}
        <span style={{ position: 'absolute', right: 0, display: 'inline-block' }}>
          <Switch
            checked={selectedSetting[`report${reportNumber}_enable`]}
            onChange={() => handleChangeSetting(`report${reportNumber}_enable`, !selectedSetting[`report${reportNumber}_enable`])}
          />
        </span>
      </Typography>
      <Collapse in={selectedSetting[`report${reportNumber}_enable`]} timeout="auto" unmountOnExit>
        <SwitchesOuter>
          <Switches>
            <SwitchBlock>
              <Typography>★ UTC</Typography>
              <Switch
                checked={selectedSetting[`report${reportNumber}_utc_on`]}
                onChange={() => handleChangeSetting(`report${reportNumber}_utc_on`, !selectedSetting[`report${reportNumber}_utc_on`])}
              />
            </SwitchBlock>
            <SwitchBlock>
              <Typography>★ GPS</Typography>
              <Switch
                checked={selectedSetting[`report${reportNumber}_gps_on`]}
                onChange={() => handleChangeSetting(`report${reportNumber}_gps_on`, !selectedSetting[`report${reportNumber}_gps_on`])}
              />
            </SwitchBlock>
            <SwitchBlock>
              <Typography>Altitude</Typography>
              <Switch
                checked={selectedSetting[`report${reportNumber}_alt_on`]}
                onChange={() => handleChangeSetting(`report${reportNumber}_alt_on`, !selectedSetting[`report${reportNumber}_alt_on`])}
              />
            </SwitchBlock>
            <SwitchBlock>
              <Typography>★ Speed</Typography>
              <Switch
                checked={selectedSetting[`report${reportNumber}_speed_on`]}
                onChange={() => handleChangeSetting(`report${reportNumber}_speed_on`, !selectedSetting[`report${reportNumber}_speed_on`])}
              />
            </SwitchBlock>
            <SwitchBlock>
              <Typography>★ Course</Typography>
              <Switch
                checked={selectedSetting[`report${reportNumber}_course_on`]}
                onChange={() => handleChangeSetting(`report${reportNumber}_course_on`, !selectedSetting[`report${reportNumber}_course_on`])}
              />
            </SwitchBlock>
            <SwitchBlock>
              <Typography>I/O 1</Typography>
              <Switch
                checked={selectedSetting[`report${reportNumber}_io1_on`]}
                onChange={() => handleChangeSetting(`report${reportNumber}_io1_on`, !selectedSetting[`report${reportNumber}_io1_on`])}
              />
            </SwitchBlock>
            <SwitchBlock>
              <Typography>I/O 2</Typography>
              <Switch
                checked={selectedSetting[`report${reportNumber}_io2_on`]}
                onChange={() => handleChangeSetting(`report${reportNumber}_io2_on`, !selectedSetting[`report${reportNumber}_io2_on`])}
              />
            </SwitchBlock>
            <SwitchBlock>
              <Typography>I/O 3</Typography>
              <Switch
                checked={selectedSetting[`report${reportNumber}_io3_on`]}
                onChange={() => handleChangeSetting(`report${reportNumber}_io3_on`, !selectedSetting[`report${reportNumber}_io3_on`])}
              />
            </SwitchBlock>
            <SwitchBlock>
              <Typography>I/O 4</Typography>
              <Switch
                checked={selectedSetting[`report${reportNumber}_io4_on`]}
                onChange={() => handleChangeSetting(`report${reportNumber}_io4_on`, !selectedSetting[`report${reportNumber}_io4_on`])}
              />
            </SwitchBlock>
            <SwitchBlock style={{ border: 'none', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Typography>Period Time</Typography>
              <TextField
                InputProps={{ endAdornment: <InputAdornment position="end">Min</InputAdornment> }}
                onChange={event => handleChangeSetting(`report${reportNumber}_period_time`, event.target.value)}
                value={selectedSetting[`report${reportNumber}_period_time`]}
                label="Period"
                variant="outlined"
                margin="dense"
              />
            </SwitchBlock>
          </Switches>
        </SwitchesOuter>
      </Collapse>
    </ReportPaper>
  );

  const mapIOPorts = portNumber => (
    <ReportPaper>
      <Typography
        style={{
          display: 'flex',
          position: 'relative',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        I/O Port {portNumber}
      </Typography>
      <SwitchesOuter>
        <Switches>
          <Typography style={{ width: '100%' }} color="secondary">IO Status</Typography>
          <SwitchBlock style={{ border: 'none' }}>
            <FormControlLabel
              control={(
                <Radio
                  style={{ marginRight: 24 }}
                  checked={selectedSetting[`port${portNumber}_io_status`] === '00'}
                  onChange={() => handleChangeSetting(`port${portNumber}_io_status`, '00')}
                />
              )}
              label="None"
            />
          </SwitchBlock>
          <SwitchBlock style={{ border: 'none' }}>
            <FormControlLabel
              control={(
                <Radio
                  style={{ marginRight: 24 }}
                  checked={selectedSetting[`port${portNumber}_io_status`] === '01'}
                  onChange={() => handleChangeSetting(`port${portNumber}_io_status`, '01')}
                />
              )}
              label="Digital Output"
            />
          </SwitchBlock>
          <SwitchBlock style={{ border: 'none' }}>
            <FormControlLabel
              control={(
                <Radio
                  style={{ marginRight: 24 }}
                  checked={selectedSetting[`port${portNumber}_io_status`] === '10'}
                  onChange={() => handleChangeSetting(`port${portNumber}_io_status`, '10')}
                />
              )}
              label="Digital Input"
            />
          </SwitchBlock>
          <SwitchBlock style={{ border: 'none' }}>
            <FormControlLabel
              control={(
                <Radio
                  style={{ marginRight: 24 }}
                  checked={selectedSetting[`port${portNumber}_io_status`] === '11'}
                  onChange={() => handleChangeSetting(`port${portNumber}_io_status`, '11')}
                />
              )}
              label="Analog Input"
            />
          </SwitchBlock>
          <Typography style={{ width: '100%', marginTop: 16 }} color="secondary">Out Status</Typography>
          <SwitchBlock style={{ border: 'none' }}>
            <FormControlLabel
              control={(
                <Radio
                  style={{ marginRight: 24 }}
                  checked={selectedSetting[`port${portNumber}_out_status`] === '1'}
                  onChange={() => handleChangeSetting(`port${portNumber}_out_status`, '1')}
                />
              )}
              label="High"
            />
          </SwitchBlock>
          <SwitchBlock style={{ border: 'none' }}>
            <FormControlLabel
              control={(
                <Radio
                  style={{ marginRight: 24 }}
                  checked={selectedSetting[`port${portNumber}_out_status`] === '0'}
                  onChange={() => handleChangeSetting(`port${portNumber}_out_status`, '0')}
                />
              )}
              label="Low"
            />
          </SwitchBlock>
        </Switches>
      </SwitchesOuter>
    </ReportPaper>
  );

  const originGetIsSettingChanged = () => {
    if (!selectedSetting || !recentSettingsData || !recentSettingsData.GetRecentestSetting.ok) return false;

    const { GetRecentestSetting: { recentestSetting: recentSetting } } = recentSettingsData;

    const changedSettings = [];

    const getSettingKeyword = (settingKey) => {
      if (/report1/.test(settingKey)) return 'report1';
      if (/report2/.test(settingKey)) return 'report2';
      if (/report3/.test(settingKey)) return 'report3';
      if (/report4/.test(settingKey)) return 'report4';
      return 'io';
    };

    Object.keys(selectedSetting).forEach((settingKey) => {
      if (selectedSetting[settingKey] !== recentSetting[settingKey]) {
        if (!selectedSetting[settingKey] && !recentSetting[settingKey]) return;

        if (!changedSettings.includes(getSettingKeyword(settingKey))) {
          changedSettings.push(getSettingKeyword(settingKey));
        }
      }
    });

    // console.log(changedSettings);

    if (!changedSettings.length) return false;

    return changedSettings;
  };

  const getIsSettingChanged = () => {
    if (isSettingChangedMemoizer) return isSettingChangedMemoizer;

    isSettingChangedMemoizer = originGetIsSettingChanged();

    return isSettingChangedMemoizer;
  };

  const confirmSaveSettings = () => {
    const changedSettings = getIsSettingChanged();
    if (!changedSettings) return false;

    setOnComfirmSaveSettings(true);
  };

  const setDefaultSetting = () => {
    setSelectedSetting(DEFAULT_SETTING);
  };

  const saveSettings = async () => {
    try {
      const reportBitMap = {
        report1: '00',
        report2: '01',
        report3: '10',
        report4: '11',
      };

      const getIOPortSettings = () => ({
        IOPort1_IO_status: selectedSetting.port1_io_status,
        IOPort1_out_status: selectedSetting.port1_out_status,
        IOPort2_IO_status: selectedSetting.port2_io_status,
        IOPort2_out_status: selectedSetting.port2_out_status,
        IOPort3_IO_status: selectedSetting.port3_io_status,
        IOPort3_out_status: selectedSetting.port3_out_status,
        IOPort4_IO_status: selectedSetting.port4_io_status,
        IOPort4_out_status: selectedSetting.port4_out_status,
      });

      const getReportMaskingBit = (settingKey) => {
        const reportNumber = reportBitMap[settingKey];
        const enable = selectedSetting[`${settingKey}_enable`] ? 1 : 0;
        const utc = selectedSetting[`${settingKey}_utc_on`] ? 1 : 0;
        const gps = selectedSetting[`${settingKey}_gps_on`] ? 1 : 0;
        const alt = selectedSetting[`${settingKey}_alt_on`] ? 1 : 0;
        const speed = selectedSetting[`${settingKey}_speed_on`] ? 1 : 0;
        const course = selectedSetting[`${settingKey}_course_on`] ? 1 : 0;

        return `${reportNumber}${enable}${utc}${gps}${alt}${speed}${course}`;
      };

      const getIOMaskingBit = (settingKey) => {
        const io1 = selectedSetting[`${settingKey}_io1_on`] ? 1 : 0;
        const io2 = selectedSetting[`${settingKey}_io2_on`] ? 1 : 0;
        const io3 = selectedSetting[`${settingKey}_io3_on`] ? 1 : 0;
        const io4 = selectedSetting[`${settingKey}_io4_on`] ? 1 : 0;

        return `0000${io1}${io2}${io3}${io4}`;
      };

      const savedSettings = getIsSettingChanged() ? getIsSettingChanged().filter(key => key !== 'io') : false;
      if (!savedSettings) return;

      setOnSaveSettingsLoading(true);

      const Settings = {
        report_masking_bit_1: getReportMaskingBit('report1'),
        period_time_1: selectedSetting.report1_period_time || 60,
        io_masking_bit_1: getIOMaskingBit('report1'),
        report_masking_bit_2: getReportMaskingBit('report2'),
        period_time_2: selectedSetting.report2_period_time || 60,
        io_masking_bit_2: getIOMaskingBit('report2'),
        report_masking_bit_3: getReportMaskingBit('report3'),
        period_time_3: selectedSetting.report3_period_time || 60,
        io_masking_bit_3: getIOMaskingBit('report3'),
        report_masking_bit_4: getReportMaskingBit('report4'),
        period_time_4: selectedSetting.report4_period_time || 60,
        io_masking_bit_4: getIOMaskingBit('report4'),
        ...getIOPortSettings(),
      };

      await emitSaveAssetSettings({
        variables: {
          imei: selectedAsset.imei,
          ...Settings,
        },
      });

      setOnSaveSettingsLoading(false);
      setOnComfirmSaveSettings(false);
      setSnackbarOpen('Successfully saved asset settings.');
    } catch (err) {
      // console.log(err);
      alert('You have errors in your input.');
    }
  };

  // Use another user list query for administrator users.

  const [isAdminInitialized, setIsAdminInitialized] = useState(false);
  const [allUserList, setAllUserList] = useState(null);

  const [getAllUsers] = useLazyQuery(GET_WHOLETREE_WHICHIS, {
    onCompleted: (data) => {
      // console.log('GETALLUSER', data);
      const uwPipes = [];
      const findUWPipe = (user) => {
        if (user.uwpipe && user.uwpipe.length) {
          uwPipes.push(...user.uwpipe);
        }

        if (user.usersAsUser && user.usersAsUser.length) {
          user.usersAsUser.forEach(innerUser => findUWPipe(innerUser));
        }
      };
      if (data.GetUsers.ok && data.GetUsers.users) {
        setAllUserList(data.GetUsers.users);
        if (data.GetUsers.users.length) {
          data.GetUsers.users.map(user => findUWPipe(user));

          if (uwPipes.length) {
            if (recentAssets && recentAssets.length) {
              // console.log('INRECENT', recentAssets, uwPipes);
              const foundLastAsset = uwPipes.find(asset => asset.id === recentAssets[recentAssets.length - 1]);

              if (foundLastAsset) {
                loadAsset(foundLastAsset);
              }
            }
          }
        }
      } else {
        alert('Failed to fetch user list. Please check server status or connection status.');
      }
    },
    fetchPolicy: 'no-cache',
  });

  const initializeAllUserData = async () => {
    // console.log('EFFECT', isAdminInitialized);
    if (isAdminInitialized) return;

    setIsAdminInitialized(true);
    // console.log('INVOKE: getAllUsers');
    await getAllUsers();
  };

  useEffect(() => {
    if (profileData && ['administrator', 'agent'].includes(profileData.role)) {
      initializeAllUserData();
    }
  });

  const loadAdminAsset = (targetAsset) => {
    const foundOriginAsset = assets.find(asset => asset.id === targetAsset.id);

    if (foundOriginAsset) return loadAsset(foundOriginAsset);

    return loadAsset(targetAsset);
  };

  const mapUsersAsUser = (usersAsUser, level) => {
    // console.log(usersAsUser);
    if (level > 1) {
      return usersAsUser.map((user) => {
        // console.log(user);
        if (!user.usersAsUser || !user.usersAsUser.length) return null;
        return (
          <AdminInnerExpansionPanel>
            <ExpansionPanelSummary
              style={{ position: 'relative' }}
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography color="textSecondary" variant="body1">{user.nickname || getFullName(user)}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              {
                mapUsersAsUser(user.usersAsUser, level - 1)
              }
            </ExpansionPanelDetails>
          </AdminInnerExpansionPanel>
        );
      });
    }

    return usersAsUser.filter(user => user.uwpipe && user.uwpipe.length).map((assetUser) => {
      const currentUserWhichi = assetUser.uwpipe[0];

      return (
        <AssetListItem selected={selectedAsset && selectedAsset.id === currentUserWhichi.id} onClick={() => loadAdminAsset(currentUserWhichi)} variant="text">{currentUserWhichi.name}</AssetListItem>
      );
    });
  };

  // END OF CODES FOR ADMINISTRATOR USER LIST QUERY //

  useEffect(() => {
    if (!isInitialized && profileData) {
      setInitialized(true);
      const currentRecentAssets = localStorage.getItem(`${profileData ? profileData.id : 'null'}whichiten-asset-recent`) ? JSON.parse(localStorage.getItem(`${profileData ? profileData.id : 'null'}whichiten-asset-recent`)) : [];

      setRecentAssets(currentRecentAssets);

      if (assets && assets.length) {
        if (currentRecentAssets && currentRecentAssets.length) {
          const foundLastAsset = assets.find(asset => asset.id === currentRecentAssets[currentRecentAssets.length - 1]);

          if (foundLastAsset) {
            loadAsset(foundLastAsset);
          }
        }
      }
    }
  });

  const [uploadFile] = useMutation(UPLOAD_FILE);

  const uploadProfile = async (event) => {
    // console.log(event.target.files);

    const uploadable = event.target.files[0];

    // console.log('UPLOAD', uploadable);

    event.target.files = null; // eslint-disable-line

    try {
      const result = await uploadFile({
        variables: {
          file: uploadable,
          userId: profileData.id,
        },
        refetchQueries: [{ query: GET_MY_PROFILE }],
      });

      // console.log(result);
    } catch (err) {
      // console.log(err);
    }
  };

  return (
    <main
      className={clsx(classes.content, {
        [classes.contentShift]: open,
      })}
      style={isRightAssetEnabled ? {} : { paddingRight: 0 }}
    >
      <Contents>
        {
          (!profileData || !['asset', 'agent'].includes(profileData.role))
          && (
            <ContentLeft>
              {
                allUserList
                && allUserList.map(admin => (
                  <AdminOuterExpansionPanel defaultExpanded>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography color="textSecondary" variant="h6">User List</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      {
                        admin.usersAsUser && admin.usersAsUser.length
                        && mapUsersAsUser(admin.usersAsUser, profileData.role === 'administrator' ? 3 : 2) || false
                      }
                    </ExpansionPanelDetails>
                  </AdminOuterExpansionPanel>
                ))
                || profileData && !['administrator', 'agent'].includes(profileData.role)
                && (
                  <OuterExpansionPanel defaultExpanded>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography color="textSecondary" variant="h6">All</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      {
                        loading
                        && (
                        <div style={{
                          width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 144,
                        }}
                        >
                          <CircularProgress style={{ color: 'white' }} />
                        </div>
                        )
                        || mapGroupedAssets()
                      }
                    </ExpansionPanelDetails>
                  </OuterExpansionPanel>
                )
                || (
                  <div style={{
                    width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 144,
                  }}
                  >
                    <CircularProgress style={{ color: 'white' }} />
                  </div>
                )
              }
              <OuterExpansionPanel defaultExpanded>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography color="textSecondary" variant="h6">Recent</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  {
                    !loading
                    && mapAssets(getRecentAssets())
                  }
                </ExpansionPanelDetails>
              </OuterExpansionPanel>
            </ContentLeft>
          )
        }
        <ContentRight>
          <RightInner>
            {
              (!profileData || !['asset', 'agent'].includes(profileData.role)) && (
                selectedAsset && !selectedSetting
                && (
                  <Paper>
                    <Typography style={{ display: 'flex', alignItems: 'center' }}>
                      <CircularProgress size={24} style={{ marginRight: 16 }} />
                      Synchronizing settings from server...
                    </Typography>
                  </Paper>
                )
                || selectedAsset && selectedSetting
                && (
                  <>
                    <Typography variant="h6">
                      Reports


                      <Button style={{ marginLeft: 16 }} disabled={!getIsSettingChanged()} onClick={confirmSaveSettings} variant="contained" color="secondary">
                        {
                            updateProfileLoading
                            && <CircularProgress size={24} />
                            || 'Send change setting request'}
                      </Button>
                      <Button style={{ marginLeft: 16 }} onClick={setDefaultSetting} variant="contained" color="secondary">
                        Default
                      </Button>
                    </Typography>
                    <ReportHolder style={{ marginBottom: 24 }}>
                      {
                        [1, 2, 3, 4].map(mapReports)
                      }
                    </ReportHolder>
                    <Typography variant="h6">
                      Terminal
                      <Button style={{ marginLeft: 16 }} disabled={!getIsSettingChanged()} onClick={confirmSaveSettings} variant="contained" color="secondary">
                        {
                            updateProfileLoading
                            && <CircularProgress size={24} />
                            || 'Send change setting request'}
                      </Button>
                    </Typography>
                    <ReportHolder style={{ marginBottom: 24 }}>
                      {
                        [1, 2, 3, 4].map(mapIOPorts)
                      }
                    </ReportHolder>
                  </>
                )
                || (
                  <div style={{
                    width: '100%', height: 360, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: 1,
                  }}
                  >
                    <Pageview style={{ fontSize: 48 }} />
                    <Typography>Please select the asset from left navigation.</Typography>
                  </div>
                )
              )
            }
          </RightInner>
        </ContentRight>
      </Contents>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={closeSnackbar}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{snackbarOpen}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            className={classes.close}
            onClick={closeSnackbar}
          >
            <Close />
          </IconButton>,
        ]}
      />
      <Dialog open={onConfirmSaveSettings} onClose={onSaveSettingsLoading ? () => {} : () => setOnComfirmSaveSettings(null)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Typography>
            The queries will reach the asset approximately 5 to 10 minutes after you send the queries. (May vary depending on the circumstances)
            <br />
            <br /> It takes some more time to synchronize the settings with our server. Before the settings completely synchronized, this page will show older version of asset settings.
            <br />
            <br />
            Every enabled report&apos;s <span style={{ fontWeight: 'bold' }}>Period Time </span> must be set. they will set to 60 minutes if you left them blank.
            <br />
            <br />
            You can check the query status on the <Link to={`${url.replace('setting', 'report')}?selected=${selectedAsset && selectedAsset.id}`}>Report</Link> page.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onSaveSettingsLoading ? () => {} : () => setOnComfirmSaveSettings(null)} color="primary">
            Cancel
          </Button>
          <Button onClick={onSaveSettingsLoading ? () => {} : saveSettings} color="secondary">
            {
              onSaveSettingsLoading
              && <CircularProgress size={24} />
              || `Send ${onConfirmSaveSettings} request query`
            }
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth open={passwordChangeModal} onClose={changePasswordLoading ? () => {} : closeChangePassword} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
        <DialogContent>
          <Typography style={{ marginTop: 20 }}>
            Current password
          </Typography>
          <TextField
            margin="dense"
            value={changePassword.previous}
            onChange={event => changePasswordInput(event.target.value, 'previous')}
            label="Current Password"
            fullWidth
            type="password"
          />
          <Typography style={{ marginTop: 20 }}>
            New password
          </Typography>
          <TextField
            margin="dense"
            value={changePassword.password}
            onChange={event => changePasswordInput(event.target.value, 'password')}
            label="Password"
            fullWidth
            type="password"
          />
          <Typography style={{ marginTop: 20 }}>
            Confirm password
          </Typography>
          <TextField
            margin="dense"
            value={changePassword.confirm}
            error={changePassword.confirm !== changePassword.password}
            onChange={event => changePasswordInput(event.target.value, 'confirm')}
            label="Confirm"
            fullWidth
            type="password"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={changePasswordLoading ? () => {} : closeChangePassword} color="primary">
            Cancel
          </Button>
          <div onClick={changePassword.confirm !== changePassword.password ? () => alert('Please fill out all required fields.') : () => {}}>
            <Button disabled={changePassword.confirm !== changePassword.password} onClick={changePasswordLoading ? () => {} : applyChangePassword} color="primary">
              {
                changePasswordLoading
                && <CircularProgress size={24} />
                || 'Apply'
              }
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </main>
  );
}
