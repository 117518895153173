import React from 'react';
import styled from 'styled-components';

const ErrorMessage = styled.div`
  width: 100%;
  height: 100%;

  span, p, svg {
    color: white;
    font-weight: bold;
    font-size: 32;
  }
`;

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    // console.log(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorMessage>
          <div style={{
            width: '100%', margin: 48, height: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: 1,
          }}
          >
            <p color="textSecondary">App crashed by unknown reason. Please refresh the page. If this problem continues, please contact to us at developer@modootel.co.kr</p>
          </div>
        </ErrorMessage>
      );
    }
    return this.props.children;
  }
}
