import gql from 'graphql-tag';

export const GET_MY_PROFILE = gql`
{
  GetMyProfile {
    ok
    user {
      id
      firstName
      lastName
      nickname
      role
      email
      age
      userid
      postalCode
      registrant
      companyCode
      phoneNumber
      profilePhoto
      fax
      address
    }
  }
}
`;

export const UPDATE_PROFILE = gql`
  mutation UpdateProfile(
    $email: String
    $firstName: String
    $lastName: String
    $companyCode: String
    $phoneNumber: String
    $fax: String
    $address: String
    $registrant: String
    $postalCode: String
  ) {
    UpdateMyProfile(
      email: $email
      firstName: $firstName
      lastName: $lastName
      companyCode: $companyCode
      phoneNumber: $phoneNumber
      fax: $fax
      address: $address
      registrant: $registrant
      postalCode: $postalCode
    ) {
      ok
      error
    }
  }
`;
