import styled from 'styled-components';

export const ReportInner = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;

  > div {
    width: 100%;
    flex-shrink: 0;
  }

  > div:last-child {
    flex-grow: 1;
    flex-shrink: 1;
  }
`;

export const ReportAssetHeader = styled.div`
  height: 48px;
  background: white;
  display: flex;
  padding: 8px;

  > p {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    > span {
      display: inline-flex;
      align-items: center;
    }

    > span + span {
      flex-grow: 1;
      margin-left: 8px;
    }
  }
`;

export const ReportAssetFilters = styled.div`
  height: 64px;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const GrowPadder = styled.div`
  flex-grow: 1;
`;

export const ReportList = styled.div`
display: flex;
flex-direction: column;
overflow: auto;
${props => !props.noBackground && 'background: white;'}

> div {
  height: fit-content;
  min-height: 48px;
  flex-shrink: 0;
}


>*:nth-child(even) {
  background: #DBEAFE;
}
  
::-webkit-scrollbar {
  width: 16px;
}
::-webkit-scrollbar-track {
${props => !props.noBackground && 'background: white;'}
}
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.4);
  width: 16px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 8px;
}
`;

export const EventReportLayout = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  > p {
    text-align: left;
    width: ${props => props.simple && 100 || 240}px;
    ${props => props.isMobile && `
      width: 25%;
    `}
  }

  > p:last-child {
    flex-grow: 1;
  }
`;

export const AverageReportLayout = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  > p:first-child {
    {
      ${props => !props.isMobile && `
        flex-grow: 1;
      `}
    }
    text-align: left;
  }

  > p {
    text-align: left;
    width: 25%;
    {
      ${props => props.isMobile && `
        flex-grow: 1;
      `}
    }

    ${props => props.mini && 'width: 50%;'}
  }
`;

export const PositionReportLayout = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 12px;
${
  props => !props.mini && !props.isMobile
  && `
    padding-left: 24px;
    padding-right: 24px;
  `
}
  > p:first-child {
    {
      ${props => !props.isMobile && `
        flex-grow: 1;
      `}
    }
    width: 20%;
    text-align: left;
  }

  > p {
    text-align: left;
    width: 15%;
    font-size: 0.75rem;
    {
      ${props => props.isMobile && `
        flex-grow: 1;
      `}
    }
  }
`;

export const ReportListHeader = styled.div`
  height: 48px;
  width: 100%;
  border-top: 1px solid #111;
  flex-shrink: 0;
`;

export const ReportAssetTimeline = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  margin: 0px 8px;

  color: #fff;

  > div > label {
    color: #fff !important;
  }

  > div > div {
    color: #fff;
    margin-top: 10px;

    &:hover {
      &::before {
        border-bottom: 2px solid rgba(255, 255, 255, 0.7) !important;
      }
    }
    &::before {
      border-bottom-color: rgba(255, 255, 255, 0.7);
    }
    &::after {
      border-bottom-color: rgba(255, 255, 255, 1) !important;
    }

    > div > button {
      color: rgba(255, 255, 255, 0.7);
    }
  }
`;
