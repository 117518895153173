const messageKindMap = {
  report_rx_ask: {
    status: 'Setting',
    event: 'Response - Setting Read',
    color: '#E3CB88',
  },
  report_rx_success: {
    status: 'Setting',
    event: 'Report Setting change success',
    color: '#E3CB88',
  },
  ioport_rx_success: {
    status: 'Setting',
    event: 'IO Port Setting change success',
    color: '#E3CB88',
  },
  distress_tx_set: {
    status: 'Distress',
    event: 'Request – Distress Signal Turn Off',
    color: '#BBB',
  },
  ioport_tx_ask: {
    status: 'Setting',
    event: 'Request - IO Setting Read',
    color: '#E3CB88',
  },
  place_rx_poll: {
    status: 'Poll',
    event: 'Response',
    color: '#ABE896',
  },
  poll_tx_ask: {
    status: 'Poll',
    event: 'Request - Terminal current location',
    color: '#ABE896',
  },
  report_tx_ask: {
    status: 'Setting',
    event: 'Request - Report Setting Read',
    color: '#E3CB88',
  },
  report_tx_set: {
    status: 'Setting',
    event: 'Request - Setting Write',
    color: '#E3CB88',
  },
  poweron_rx: {
    status: 'Active',
    event: 'Asset Power ON',
    color: '#E8E596',
  },
  message_tx: {
    status: 'Message',
    event: 'Send Message',
    color: '#96E2E8',
  },
  message_rx: {
    status: 'Message',
    event: 'Receive Message',
    color: '#96E2E8',
  },
  distress_rx: (message) => {
    const isOn = message.message_body;
    if (isOn === '1') {
      return {
        status: 'Distress',
        event: 'Receive - Distress Signal ON',
        color: '#F13B3A',
      };
    }
    return {
      status: 'Distress',
      event: 'Receive – Distress Signal OFF',
      color: '#BBB',
    };
  },
  distress_rx_on: {
    status: 'Distress',
    event: 'Receive - Distress Signal ON',
    color: '#F13B3A',
  },
  distress_rx_off: {
    status: 'Distress',
    event: 'Request – Distress Signal Turn Off',
    color: '#BBB',
  },
  whichi_inactive: {
    status: 'Inactive',
    event: 'The reporting period has been exceeded.',
    color: '#999',
  },
  user_active: {
    status: 'USE',
    event: 'Terminal approval',
    color: '#4070F6',
  },
  user_inactive: {
    status: 'STOP',
    event: 'Terminal not approval',
    color: '#999',
  },
  reportsioport_rx_set: {
    status: 'Setting',
    event: 'Response – Setting change completed.',
    color: '#E3CB88',
  },
  reportsioport_tx_set: {
    status: 'Setting',
    event: 'Request – Change Setting',
    color: '#E3CB88',
  },
  place_rx_ask: {
    status: 'Location',
    event: 'Terminal location information',
    color: 'green',
  },
};

export default messageKindMap;
