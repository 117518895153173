import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import {
  useHistory,
  Route,
  useRouteMatch,
  Link,
  useLocation,
  Redirect,
} from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import {
  Language, Close, Search, CancelPresentation, Send, Edit, AccessTime, Layers, FilterList, Settings, KeyboardArrowRight, SupervisedUserCircle, ExitToApp, ExpandMore, MapOutlined, DirectionsCar, SquareFoot,
} from '@material-ui/icons';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import {
  CircularProgress, InputBase, Button, MenuItem, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar, Select, ExpansionPanel, ExpansionPanelSummary, Slider, Switch, Tooltip,
} from '@material-ui/core';
import { KeyboardDatePicker, DatePicker } from '@material-ui/pickers';
import clsx from 'clsx';
import IconAssets from '../images/icon_assets.png';
import IconMail from '../images/icon_mail.png';
import IconStat from '../images/icon_stat.png';
import DistressSafe from '../images/distress_safe.png';
import DistressWarning from '../images/distress_warning.png';
import CommandCenter from '../images/commend_center.png';
import NoProfile from '../images/truck.png';
import { AssetSVG } from '../images/svgs';
import authHandler from '../libs/authHandler';
import {
  onEnter, humanizeDate, firstUpperLetter, getFullName, debounce, capitalize, photoURL, getByteLength,
} from '../libs/helpers';
import Map from './Map';
import AssetPage from './Asset';
import MessagePage from './Messages';
import {
  GET_WHICHI_MESSAGES, GET_WHICHIS, GET_WHICHI_LOCATIONS, SEND_WHICHI_MESSAGE, GET_WHICHI_REPORT_INFO, GET_RECENT_SETTINGS,
} from '../graphql/whichi';
import { GET_MY_PROFILE } from '../graphql/profile';
import { SquareSelect } from '../components/select';
import {
  assetInfoTypes, assetInfoStatus, allAssetInfoUsage, assetInfoUsage,
} from '../libs/assetInfoFilters';
import {
  GroupIcon, MessageLog, MessageView, ErrorWrapper, SpanHighlighted, AssetExpansionPanel, AssetExpansionPanelDetails, StyledColorPicker,
} from '../components/views';
import { drawerWidth, API_HOST, WICHI_MAPOPTION_KEY } from '../constants';
import ReportPage from './Reports';
import { WichiTabs, WichiTab } from '../components/tabs';
import { WichiPrimaryButton } from '../components/buttons';
import SettingPage from './Settings';
import {
  ReportAssetTimeline, ReportAssetFilters, GrowPadder, ReportListHeader, EventReportLayout, AverageReportLayout, PositionReportLayout, ReportList, ReportAssetHeader, ReportInner,
} from '../components/reports';
import { FilterWrapper } from '../components/input';
import AdminPage from './Administrator';
import apolloClient from '../libs/apolloClient';
import { SEND_POLL_REQUEST, TURN_DISTRESS_OFF } from '../graphql/reports';
import messageKindMap from '../libs/messageKindMaps';
import ProfilePage from './Profile';

let whichiLocationsTimeout;

const RightAssetFilter = styled.div`
  width: calc(100% + 48px);
  margin-left: -24px;
  display: flex;
  flex-direction: column;
  background: #808080;
  color: white;

  > div {
    width: 100%;
    height: 40px;
    display: flex;
    border-bottom: 1px solid #bbb
    align-items: center;

    > div {
      width: 50%;
      display: flex;
      padding: 8px;

      > span {
        width: 64px;
        text-align: center;
        display: inline-block;
      }

      > div {
        width: auto;
        flex-grow: 1;
      }
    }
  }


  > div:nth-child(3) {
    > div:first-child {
      border-right: 1px solid #bbb;
    }
    > div {
      align-items: center;
      justify-content: center;
      height: 100%;
      color: white;
      padding-right: 32px;
      > div {
        width: 0;
        flex-grow: 0;
      }
      > div > div > div > button {
        color: #bbb;
      }
    }
  }

  ${props => props.isMobile && `
  width: 100%;
  margin-left: 0;
  background: #80808040;
  `}
`;

const RightAssetDetail = styled.div`
  color: white;
  height: 550px;
  width: calc(100% + 48px);
  margin-left: -24px;
  display: flex;
  flex-direction: column;

  > div:first-child {
    display: flex;
    align-items: center;
    height: 52px;
    padding: 8px;
    border-bottom: 1px solid #bbb;

    > svg:first-child {
      width: 40px;
      height: 40px;
      margin-right: 24px;
    }

    > p {
      margin: 0;
      display: flex;
      align-items: center;
      flex-grow: 1;
      font-size: 1.25em;

      > a {
        font-size: 0.825em;
        display: inline-flex;
        align-items: center;
        margin-left: 16px;
        cursor: pointer;
        text-decoration: none;
        color: white;
        > svg {
          width: 0.825em;
          height: 0.825em;
          margin-right: 8px;
        }
      }
    }

    > p + svg {
      cursor: pointer;
      width: 40px;
      height: 40px;
    }
  }

  ${props => props.isMobile && `
    max-height: calc(402px);
    width: 100%;
    flex-grow: 1;
    margin-left: 0;
    > div:first-child {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 8px;
      border-bottom: 1px solid #bbb;

      > svg:first-child {
        width: 32px;
        height: 32px;
        margin-right: 24px;
      }

      > p {
        margin: 0;
        display: flex;
        align-items: center;
        flex-grow: 1;
        font-size: 1.25em;

        > a {
          font-size: 0.825em;
          display: inline-flex;
          align-items: center;
          margin-left: 16px;
          cursor: pointer;
          text-decoration: none;
          color: white;
          > svg {
            width: 0.825em;
            height: 0.825em;
            margin-right: 8px;
          }
        }
      }

      > p + svg {
        cursor: pointer;
        width: 32px;
        height: 32px;
      }
    }
  `}
`;

const AssetDetailInfo = styled.div`
  display: flex;
  height: 160px;
  border-bottom: 1px solid #bbb;
  > img {
    width: 160px;
    height: 160px;
  }
  > img + div {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px 16px;

    > p {
      display: flex;
      align-items: center;
      font-size: 1em;
      color: white;
      > span {
        display: inline-block;
        width: 80px;
        font-weight: bold;
      }
    }
  }

  ${props => props.isMobile && `
    height: 120px;
    > img {
      width: 120px;
      height: 120px;
    }
  `}
`;

const DistressOffButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  flex-grow: 1;
  background: #f44336;
  color: white;
  font-size: 1em;
  padding: 0;
  text-decoration: none;
  text-transform: none;
  font-weight: normal;
  height: 24px;
  max-width: 120px;
  margin-left: 40px;
`;

const AssetDetailTrack = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-bottom: 1px solid #bbb;

  > div:first-child {
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      flex-grow: 1;
      background: #808080;
      color: white;
      font-size: 1em;
      padding: 0;
      text-decoration: none;
      text-transform: none;
      font-weight: normal;
      height: 24px;
      min-width: 20px;
      width: calc(16.666% - 4px);

      &.last-selected {
        background: #06A1AF;
      }
    }

    > button + button {
      margin-left: 8px;
    }
  }

  > div:nth-child(2) {
    margin-top: 8px;
    height: 32px;
    background: #808080;
    display: flex;
    > div:first-child {
      border-right: 1px solid #bbb;
    }
    > div {
      padding: 0;
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      > div {
        width: 0;
      }
    }
  }

  > button:nth-child(3) {
    margin-top: 8px;
    height: 24px;
    color: white;
    background: #06A1AF;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    padding: 0;
    text-decoration: none;
    text-transform: none;
  }
`;

const AssetDetailOthers = styled.div`
  display: flex;
  flex-direction: column;

  > p {
    display: flex;
    align-items: center;
    padding: 12px;
    border-bottom: 1px solid #bbb;
    width: 100%;
    color: white;
    font-size: 1em;

    > span {
      width: 30%;
      display: inline-block;
    }
  }

  ${props => props.isMobile && `
  
    
    ::-webkit-scrollbar {
      width: 4px;
    }
    ::-webkit-scrollbar-track {
    background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.4);
      width: 4px;
      border: 4px solid rgba(0, 0, 0, 0.4);
      background-clip: padding-box;
      border-radius: 8px;
    }
    height: auto;
    flex-grow: 1;
    overflow: auto;
    > p {
      padding: 4px 12px;
    }
  `}
`;

const MobileDrawerButton = styled.div`
  padding: 12px;
  padding-left: 24px;
  background-color: rgba(255, 255, 255, 0.05);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;

  > img, svg {
    margin-right: 16px;
    height: 20px;
    width: auto;
    color: white;
  }
`;

const TopbarGroups = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const TopbarProfile = styled.div`
  display: flex;
  align-items: center;
  width: 265px;

  > img {
    width: 26px;
    margin-right: 12px;
  }
  >A {
    color: inherit !important;
    text-decoration: none;
    > svg {
      width: 16px;
      margin-left: 8px;
      cursor: pointer;
    }
  }
`;

const Distress = styled.div`
  background: #1A1B26;
  border-radius: 8px;
  height: 36px;
  width: 120px;
  > img {
    width: 100%;
    height: 100%;
  }
`;

const AssetGroupIndicator = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 24px;
  align-items: center;
  background: #777 !important;
  ${props => props.isMobile && 'background: #70707040 !important;'}
  color: white;
  height: 40px;
  flex-shrink: 0;
  > div {
    width: 120px;
    margin-left: 12px;
  }
`;


const AssetTimeline = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;

  color: #fff;

  >div >div {
    color: #fff;
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: 1000,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    zIndex: 900,
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    zIndex: 900,
    width: drawerWidth,
    background: '#0E0F1A',
    paddingTop: 64,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: '48px',
  },
  content: {
    flexGrow: 1,
    padding: 0,
    paddingRight: 48,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));


const RightDrawer = styled(Drawer)`
  >div {
    background: #0E0F1A;
    border-left: 1px solid #666;
    padding-top: 64px;
  }
`;

const RightListItem = styled(ListItem)`
  &.MuiListItem-root {
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    >.MuiListItemIcon-root {
      justify-content: center;
      align-items: center;
    }

  ${props => props.selected && `
    &::after {
      content: '';
      width: 24px;
      margin: auto;
      position: absolute;
      bottom: 0;
      height: 2px;
      background: #0D96FD;
    }
  `}
  }
`;

const RightAssets = styled.div`
  width: 480px;
  position: fixed;
  z-index: 100;
  right: 48px;
  bottom: 0;
  top: 64px;
  background: #0E0F1A;
  transition: transform 0.2s ease-in-out;
  transform: translateX(480px);
  padding: 24px;
  padding-top: 0px;

  ${props => props.open && `
    transform: translateX(0);
  `}
`;

const MobileRightAssets = styled.div`
    position: fixed;
    z-index: 100;
    background: #0e0f1a90;
    transition: transform 0.2s ease-in-out;
    bottom: 64px;
    left: 0;
    right: unset;
    top: unset;
    width: 100%;
    transform: translateY(100%);
    padding: 0;
    min-height: 450px;
    height: 50%;
    display: flex;
    flex-direction: column;

  ${props => props.open && `
    transform: translateY(0);
  `}
`;

const DrawerContents = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  > p {
    margin-bottom: 24px;
    width: 200px;
  }

  > div {
    display: flex;
    > a:first-child {
      margin-right: 24px;
    }
    margin-bottom: 24px;
  }
`;

const Circle = styled.div`
  background: white;
  border-radius: 100px;
  width: 120px;
  height: 120px;
  margin-bottom: 24px;
  margin-top: 48px;
`;

const DrawerButton = styled(Button)`
  &.MuiButton-root {
    cursor: pointer;
    background: #243449;
    border: 1px solid #2E3D53;
    border-radius: 8px;
    align-items: center;
    width: 120px;
    height: 120px;

    > span {
      display: flex;
      flex-direction: column;
      justify-content: center;
      > img {
        margin-bottom: 8px;
      }
    }
  }
`;

const AssetList = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -24px;
  margin-right: -24px;
  overflow: auto;
  height: calc(100% - 24px + ${props => props.adjustment || 0}px);
  ${props => !props.noBackground && 'background: white;'}
  


  >*:nth-child(odd) {
    background: #DBEAFE;

    &:hover {
      background: #6999D9;
    }
  }
    
  ::-webkit-scrollbar {
    width: 16px;
  }
  ::-webkit-scrollbar-track {
  ${props => !props.noBackground && 'background: white;'}
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.4);
    width: 16px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 8px;
  }

  ${props => props.isMobile && `
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  flex-grow: 1;
  height: auto;
${
  !props.noBackground && 'background: #ffffff85;'
}
  >*:nth-child(even) {
    background: #ffffff85;

    &:hover {
      background: #bbbbbb85;
    }
  }
  >*:nth-child(odd) {
    background: #DBEAFE85;

    &:hover {
      background: #6999D985;
    }
  }
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
   background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.4);
    width: 4px;
    border: 4px solid rgba(0, 0, 0, 0.4);
    background-clip: padding-box;
    border-radius: 8px;
  }
  `}
`;

const Asset = styled.div`
  width: 100%;
  height: 64px;
  background: white;
  flex-shrink: 0;
  cursor: pointer;

  display: flex;
  padding: 12px;

  &:hover {
    background: #ddd;
  }

  p {
    color: black;
  }

  > svg {
    width: 32px;
    margin-right: 12px;
  }

  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 180px;

    > p:first-child {
      font-weight: bold;
    }

    > p:nth-child(2) {
      font-size: 0.8rem;
    }
  }

  > div:nth-child(3) {
    flex-grow: 1;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    > div {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      > p {
        margin: 0;
        font-size: 0.8rem;
        text-align: start;
        width: 100%;
      }
    }
  }

  ${props => props.status === 'Inactive'
    && `
      border: 3px solid #FFFF00;
      background-color: #E8E879 !important;
    `
    || props.status === 'Hold'
    && `
      border: 3px solid #FF7F00;
      background-color: #E89C56 !important;
    `
    || props.status === 'Land'
    && `
      border: 3px solid #00FE00;
      background-color: #6BDB6A !important;
    `
    || props.status === 'distress'
    && `
      border: 3px solid #FF0000;
      background-color: #E85656 !important;
    `
}

${props => props.noBackground && `
  background: transparent !important;
  border-top: 1px solid #777;

  p {
    color: white;
  }
`}
`;

const BottomMobileDrawer = styled.div`
  display: flex;
  justify-content: space-around;
  height: 64px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #0E0F1A;
  z-index: 120;
`;

const MobileRightAssetHandle = styled.div`
  width: 100%;
  position: absolute;
  height: 24px;
  background: #0e0f1a61;
  top: -24px;
  left: 0;
  border-radius: 12px 12px 0px 0px;
  ::after {
    width: 40px;
    height: 2px;
    content: "";
    background: #ffffff80;
    display: block;
    position: absolute;
    top: 11px;
    left: 0;
    right: 0;
    margin: auto;
  }
`;

const DEFAULT_MAP_OPTION = {
  mapType: 'roadmap',
  opacity: 100,
  hash: 'default',
  lastChanged: null,
  assetSize: 50,
  trackSize: 50,
  showLabel: true,
  defaultColor: '#7F7F7F',
  maxTrack: 500,
  altUnit: 'meter',
  speedUnit: 'knot',
};

export default function Dashboard() {
  let getWhichiLocations;
  const classes = useStyles();
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const [open, setOpen] = React.useState(false);
  const [assetPings, setAssetPings] = useState([]);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isOptimisticMessage, setIsOptimisticMessage] = useState(false);
  const [selectedRightMenu, setSelectedRightMenu] = useState(null);
  const [selectedAssetTab, _setSelectedAssetTab] = useState('recent');
  const [selectedAssetGroup, setSelectedAssetGroup] = useState(null);
  const [selectedReportTab, _setSelectedReportTab] = useState('recent');
  const [selectedReportInnerTab, setSelectedReportInnerTab] = useState('event');
  const [selectedReportGroup, setSelectedReportGroup] = useState(null);
  const [selectedMessageTab, setSelectedMessageTab] = useState('recent');
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedReportAsset, setSelectedReportAsset] = useState(null);
  const [messageSearchKeyword, _setMessageSearchKeyword] = useState('');
  const [appliedMessageSearchKeyword, setAppliedMessageSearchKeyword] = useState('');
  const [selectedMessageView, _setSelectedMessageView] = useState(null);
  const [trackStartDate, _setTrackStartDate] = useState(null);
  const [trackEndDate, _setTrackEndDate] = useState(null);
  const [lastSelectedTrack, setLastSelectedTrack] = useState(null);
  const [assetFilters, setAssetFilters] = useState({});
  const [reportFilters, setReportFilters] = useState({});
  const [appliedAssetFilter, setAppliedAssetFilter] = useState({});
  const [appliedReportFilter, setAppliedReportFilter] = useState({});
  const [onColorPick, setOnColorPick] = useState(false);
  const [messageInput, setMessageInput] = useState('');
  const [selectedReportStartDate, setReportStartDate] = useState(moment().subtract(7, 'day'));
  const [selectedReportEndDate, setReportEndDate] = useState(moment());
  const [mobileShowRightAsset, setMobileShowRightAsset] = useState(true);
  const [pingColor, setPingColor] = useState(null);
  const [onSendPollRequest, setOnSendPollRequest] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const [needCheckMessage, setNeedCheckMessage] = useState(false);
  const [selectedPollRequestReportNum, setPollRequestReportNum] = useState('31');
  const [pollReportStatus, setPollReportStatus] = useState({});
  let savedMapOption;
  try {
    savedMapOption = JSON.parse(localStorage.getItem(WICHI_MAPOPTION_KEY));

    if (Object.keys(savedMapOption).length !== Object.keys(DEFAULT_MAP_OPTION).length) {
      savedMapOption = null;
      localStorage.removeItem(WICHI_MAPOPTION_KEY);
    }
  } catch (err) {
    // console.log(err);
  }
  const [mapOption, _setMapOption] = useState(savedMapOption || DEFAULT_MAP_OPTION);
  const [{ center, zoom }, setMapPosition] = useState({
    center: {
      lat: 37.532600,
      lng: 127.024612,
    },
    zoom: 11,
  });


  const [getRecentSettings, {
    data: recentSettingsData,
    loading: recentSettingsLoading,
  }] = useLazyQuery(GET_RECENT_SETTINGS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const { GetRecentestSetting: { recentestSetting: recentSetting, ok, error } } = data;

      setPollReportStatus({
        report1: recentSetting.report1_enable,
        report2: recentSetting.report2_enable,
        report3: recentSetting.report3_enable,
        report4: recentSetting.report4_enable,
      });

      if (recentSetting.report1_enable) {
        setPollRequestReportNum('31');
      } else if (recentSetting.report2_enable) {
        setPollRequestReportNum('32');
      } else if (recentSetting.report3_enable) {
        setPollRequestReportNum('33');
      } else if (recentSetting.report4_enable) {
        setPollRequestReportNum('34');
      }
    },
  });

  const messageInputRef = useRef();

  const setMapOption = (key, value) => {
    const newOption = {
      ...mapOption,
      [key]: value,
      hash: new Date().getTime(),
      lastChanged: key,
    };
    // console.log(newOption);
    _setMapOption(newOption);

    localStorage.setItem(WICHI_MAPOPTION_KEY, JSON.stringify(newOption));
  };

  const location = useLocation();

  const setSelectedReportTab = (...args) => {
    _setSelectedReportTab(...args);
    setSelectedReportAsset(null);
  };

  const setTrackStartDate = (...args) => {
    _setTrackStartDate(...args);
    setLastSelectedTrack(null);
  };

  const setTrackEndDate = (...args) => {
    _setTrackEndDate(...args);
    setLastSelectedTrack(null);
  };

  const messageLogsRef = useRef();

  const setMessageSearchKeyword = (keyword) => {
    _setMessageSearchKeyword(keyword);
    debounce({ key: 'APPLY_MESSAGE_SEARCH', callback: () => setAppliedMessageSearchKeyword(keyword), timeout: 500 });
  };

  // console.log(center, zoom);
  const { loading: getWhichisLoading, data: getWhichisData } = useQuery(GET_WHICHIS, {
    pollInterval: 1000 * 60,
    onCompleted: (data) => {
      // console.log(data);
      if (data.GetUsersWhichis.whichis) {
        const filteredWhichis = data.GetUsersWhichis.whichis.filter(whichi => whichi.last_position && whichi.last_position.lat && whichi.last_position.lng).sort((prev, next) => moment(next.placeUpdatedAt || 0) - moment(prev.placeUpdatedAt || 0));
        // console.log(filteredWhichis);
        if (filteredWhichis.length) {
          const lastPosition = filteredWhichis[0].last_position;

          // console.log(lastPosition);

          setMapPosition({
            center: {
              lat: lastPosition.lat,
              lng: lastPosition.lng,
            },
            zoom,
          });
        }
        setIsInitialized(true);
      }
    },
  });
  const { loading: profileLoading, data: profileData } = useQuery(GET_MY_PROFILE);
  const [getMessages, {
    loading: messagesLoading, data: messagesData, startPolling: startMessagePolling, stopPolling: stopMessagePolling,
  }] = useLazyQuery(GET_WHICHI_MESSAGES, {
    onCompleted: () => {
      startMessagePolling(5000);
      if (messageLogsRef.current) {
        messageLogsRef.current.scrollTop = messageLogsRef.current.scrollHeight;
      }
    },
  });
  const [getLocations, {
    data: locationsData, startPolling: startLocationsPolling, stopPolling: stopLocationsPolling,
  }] = useLazyQuery(GET_WHICHI_LOCATIONS, {
    onCompleted: (data) => {
      // console.log(data);
      startLocationsPolling(1000 * 60);
      getWhichiLocations();
    },
    onError: () => window.alert('API Fetching error'),
    fetchPolicy: 'no-cache',
  });

  const [
    commitSendPollRequest, {
      loading: pollRequestLoading,
    },
  ] = useMutation(SEND_POLL_REQUEST);

  const [
    commitSendDistressOff,
  ] = useMutation(TURN_DISTRESS_OFF);

  const turnDistressOff = async (imei) => {
    commitSendDistressOff({
      variables: {
        imei,
      },
      refetchQueries: [{ query: GET_WHICHIS }],
    });

    alert('Distress Off Signal Transmitted. Please check later.');
  };


  const isMobile = useMediaQuery('(max-width: 500px)');

  useEffect(() => {
    if (selectedAsset && getWhichisData && getWhichisData.GetUsersWhichis.whichis) {
      setSelectedAsset(
        getWhichisData.GetUsersWhichis.whichis.find(whichi => whichi.id === selectedAsset.id) || selectedAsset,
      );
    }
  }, [getWhichisData]);


  const [getReportInfo, {
    data: reportInfoData, loading: reportInfoLoading,
  }] = useLazyQuery(GET_WHICHI_REPORT_INFO, {
    fetchPolicy: 'no-cache',
  });


  const [
    emitSendMessage,
    {
      loading: sendMessageLoading,
    },
  ] = useMutation(SEND_WHICHI_MESSAGE);

  let isDistress = false;

  const toggleDrawer = (force) => {
    setOpen(force === false ? force : !open);
  };

  const setSelectedAssetTab = (value) => {
    _setSelectedAssetTab(value);
    if (value !== 'option') {
      setSelectedAsset(null);
      setAssetPings([]);
    }
    if (stopLocationsPolling) {
      stopLocationsPolling();
    }
  };


  useEffect(() => {
    if (!authHandler.accessToken) {
      history.push('/');
    }
  });

  const toggleRightMenu = (rightMenu) => {
    if (selectedRightMenu === rightMenu) {
      return setSelectedRightMenu(null);
    }
    setSelectedRightMenu(rightMenu);
    setMobileShowRightAsset(true);
  };

  getWhichiLocations = async (timeoutId) => {
    // console.log('GETWHICHIL', timeoutId, whichiLocationsTimeout);
    const { GetMyPlaces: { places } } = locationsData;
    const currentTimeoutId = whichiLocationsTimeout;

    if (whichiLocationsTimeout) {
      clearTimeout(whichiLocationsTimeout);
    }

    whichiLocationsTimeout = setTimeout(() => getWhichiLocations(currentTimeoutId), 1000 * 60 * 5);

    // console.log('NEXTTIMEOUT', whichiLocationsTimeout);

    if (places && places.length) {
      const filteredPlaces = places.filter(place => place.lat && place.lng);
      setAssetPings(filteredPlaces.slice(filteredPlaces.length - (mapOption && mapOption.maxTrack || 500), filteredPlaces.length - 1));
      setPingColor(selectedAsset && selectedAsset.whichigroup && selectedAsset.whichigroup.color);
      // createAssetMarker({ ...places[places.length - 1] });
    } else {
      window.alert('해당 에셋의 위치 정보가 없습니다.');
    }
  };

  // Functions for assets.

  const loadAsset = (asset) => {
    clearTimeout(whichiLocationsTimeout);
    if (stopLocationsPolling) {
      // console.log(stopLocationsPolling);
      stopLocationsPolling();
    }
    setSelectedAsset(asset);
    setAssetPings([]);

    toggleDrawer(false);
    history.push(url);

    if (asset.last_position && asset.last_position.lat && asset.last_position.lng) {
      setMapPosition({
        center: {
          lat: asset.last_position.lat,
          lng: asset.last_position.lng,
        },
        zoom,
      });
    }
  };

  const mapAssets = (assetPreset, customCallback) => {
    const currentAssets = assetPreset || getWhichisData && getWhichisData.GetUsersWhichis && getWhichisData.GetUsersWhichis.whichis;

    if (!currentAssets || !currentAssets.length) {
      return (
        <ErrorWrapper>
          <Search />
          <Typography variant="h6">No results found.</Typography>
        </ErrorWrapper>
      );
    }
    return currentAssets.sort((prev, next) => moment(next.placeUpdatedAt || 0) - moment(prev.placeUpdatedAt || 0))
      .map(whichi => (
        <Asset status={whichi.status} onClick={customCallback ? () => customCallback(whichi) : (() => loadAsset(whichi))}>
          <AssetSVG style={{ marginRight: 8 }} icon={whichi.icon || whichi.whichigroup && whichi.whichigroup.icon} color={whichi.whichigroup && whichi.whichigroup.color} />
          <div>
            <Typography>{whichi.whichigroup && <GroupIcon style={{ color: 'white' }} color={whichi.whichigroup.color}>{firstUpperLetter(whichi.whichigroup.name)}</GroupIcon>}{whichi.name}</Typography>
            <Typography>{whichi.placeUpdatedAt ? humanizeDate(whichi.placeUpdatedAt)
              : '-'}
              {
                assetInfoStatus.filter(status => status !== 'Active').includes(whichi.status)
                && (
                <span style={{ marginLeft: 8, fontWeight: 'bold', color: whichi.status === 'distress' ? 'f44336' : '' }}>
                  {capitalize(whichi.status)}
                </span>
                )
              }
            </Typography>
          </div>
          <div>
            {
              whichi.last_position
              && (
                <>
                  <div>
                    <Typography>GPS: {whichi.last_position.lat}° | {whichi.last_position.lng}°</Typography>
                    <Typography>S: {whichi.last_position.speed ? `${parseInt(mapOption.speedUnit !== 'km/h' ? whichi.last_position.speed : whichi.last_position.speed * 1.852, 10)}${mapOption.speedUnit}` : '-'} | C: {whichi.last_position.course ? `${parseInt(whichi.last_position.course, 10)}°` : '-'} | A: {whichi.last_position.alt ? `${parseInt(mapOption.altUnit !== 'feet' ? whichi.last_position.alt : whichi.last_position.alt * 3.281, 10)}${mapOption.altUnit === 'meter' ? 'm' : 'feet'}` : '-'}</Typography>
                  </div>
                </>
              )
              || '-'
            }
          </div>
        </Asset>
      ));
  };

  const loadReportAsset = async (asset) => {
    setSelectedReportAsset(asset);
    await getReportInfo({
      variables: {
        whichiId: asset.id,
        whichiIds: [asset.id],
        from: selectedReportStartDate ? moment(selectedReportStartDate.format('YYYY/MM/DD 00:00:00')) : null,
        to: selectedReportEndDate ? moment(selectedReportEndDate.format('YYYY/MM/DD 23:59:59')) : null,
      },
    });
  };

  const mapReportAssets = presetAssets => mapAssets(presetAssets, whichi => loadReportAsset(whichi));

  // Functions for messages.

  window.moment = moment;

  const setSelectedMessageView = (input) => {
    _setSelectedMessageView(input);

    if (stopMessagePolling) {
      stopMessagePolling();
    }
  };

  const loadWhichiMessages = (whichiId, whichiName, imei, whichi) => {
    // console.log(whichi);
    getMessages({
      variables: {
        whichiIds: [whichiId],
      },
    });
    setSelectedMessageView({
      id: whichiId,
      name: whichiName,
      imei,
      ...whichi,
    });
  };


  const mapMessages = () => {
    if (!getWhichisData || !getWhichisData.GetUsersWhichis || !getWhichisData.GetUsersWhichis.whichis.length) {
      return (
        <ErrorWrapper>
          <Search />
          <Typography variant="h6">No results found.</Typography>
        </ErrorWrapper>
      );
    }
    const whichiHasMessages = getWhichisData.GetUsersWhichis.whichis.filter((whichi) => {
      if (appliedMessageSearchKeyword) {
        if (whichi.last_message && whichi.last_message.message_body) {
          if (whichi.last_message.message_body.includes(appliedMessageSearchKeyword) || whichi.name.includes(appliedMessageSearchKeyword) || whichi.imei.includes(appliedMessageSearchKeyword)) {
            return true;
          }
        }
        return false;
      }

      return whichi.last_message;
    });

    if (!whichiHasMessages.length) {
      return (
        <ErrorWrapper>
          <Search />
          <Typography variant="h6">No results found.</Typography>
        </ErrorWrapper>
      );
    }

    const sortedWichiMessages = whichiHasMessages.sort((prev, next) => moment(next.last_message.createdAt) - moment(prev.last_message.createdAt));

    const lastMessageDate = sortedWichiMessages[0].last_message.createdAt;

    if (lastMessageDate !== localStorage.getItem('lastMessageDate')) {
      localStorage.setItem('lastMessageDate', lastMessageDate);
      setNeedCheckMessage(true);
    }

    return sortedWichiMessages.map(whichi => (
      <Asset noBackground onClick={() => loadWhichiMessages(whichi.id, whichi.name, whichi.imei, whichi)}>
        <AssetSVG style={{ marginRight: 8 }} icon={whichi.icon || whichi.whichigroup && whichi.whichigroup.icon} color={whichi.whichigroup && whichi.whichigroup.color} />
        <div>
          <Typography color="textSecondary">{whichi.name}</Typography>
          <Typography color="textSecondary">IMEI: {whichi.imei}</Typography>
        </div>
        <div>
          <Typography
            style={{
              whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 200,
            }}
            color="textSecondary"
          >{whichi.last_message.message_body}
          </Typography>
        </div>
      </Asset>
    ));
  };

  const mapMessageLogs = messages => messages.map((message, idx) => (
    <MessageLog key={message.id} received={message.txrx === 'rx'} isOptimistic={isOptimisticMessage && idx === messages.length - 1}>
      {
          message.txrx === 'rx'
          && <AssetSVG style={{ marginRight: 8 }} imgOnly icon={selectedMessageView.icon || selectedMessageView.whichigroup && selectedMessageView.whichigroup.icon} color={selectedMessageView.whichigroup && selectedMessageView.whichigroup.color} />
          || <svg style={{ marginRight: 32 }} />
        }
      <div style={message.txrx === 'rx' ? { marginRight: 32, width: 'auto', minWidth: 120 } : { width: 'auto', marginLeft: 'auto', minWidth: 120 }}>
        <Typography style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }} variant="body2">{message.message_body}</Typography>
        <Typography style={{ whiteSpace: 'nowrap' }}>{humanizeDate(message.createdAt)} | {message.payload_length || 'Unknown'} bytes</Typography>
      </div>
    </MessageLog>
  ));

  // console.log(selectedMessageView);
  const sendMessage = () => {
    if (sendMessageLoading) return;
    if (messageLogsRef.current) {
      messageLogsRef.current.scrollTop = messageLogsRef.current.scrollHeight;
    }
    const { imei } = selectedMessageView;
    const tempMessageId = new Date().getTime();
    const tempMessage = {
      txrx: 'tx',
      message_body: messageInput,
      id: tempMessageId,
      createdAt: new Date(),
      __typename: 'Message',
    };

    // console.log(messageInput);

    emitSendMessage({
      variables: {
        imei,
        message_body: messageInput,
      },
      refetchQueries: [{ query: GET_WHICHIS }],
      optimisticResponse: {
        __typename: 'Mutation',
        AddTxMessage: {
          isOptimistic: true,
          ok: true,
          error: null,
          __typename: 'AddTxMessageResponse',
        },
      },
      update(proxy, { data: { AddTxMessage: { isOptimistic, ok } } }) {
        const { GetMessages } = proxy.readQuery({ query: GET_WHICHI_MESSAGES, variables: { whichiIds: [selectedMessageView.id] } });
        if (isOptimistic) {
          messageLogsRef.current.scrollTop = messageLogsRef.current.scrollHeight;
          setIsOptimisticMessage(true);
          proxy.writeQuery({
            query: GET_WHICHI_MESSAGES,
            variables: { whichiIds: [selectedMessageView.id] },
            data: {
              GetMessages: {
                ...GetMessages,
                messages: [
                  ...GetMessages.messages,
                  {
                    ...tempMessage,
                    isOptimistic,
                  },
                ],
              },
            },
          });
          setMessageInput('');
        } else if (ok) {
          setIsOptimisticMessage(false);
          messageLogsRef.current.scrollTop = messageLogsRef.current.scrollHeight;
          proxy.writeQuery({
            query: GET_WHICHI_MESSAGES,
            variables: { whichiIds: [selectedMessageView.id] },
            data: {
              GetMessages: {
                ...GetMessages,
                messages: [
                  ...GetMessages.messages,
                  {
                    ...tempMessage,
                  },
                ],
              },
            },
          });
        }
      },
    });
  };

  const unSelectAsset = () => {
    setSelectedAsset(null);
  };

  const showTrackFromSelecedAsset = () => {
    getLocations({
      variables: {
        whichiId: selectedAsset.id,
        from: trackStartDate ? moment(trackStartDate.format('YYYY/MM/DD 00:00:00')) : null,
        to: trackEndDate ? moment(trackEndDate.format('YYYY/MM/DD 23:59:59')) : null,
      },
    });
  };

  const autoSelectTrackDate = (amount, unit) => {
    const endDate = moment();
    const startDate = moment(endDate).subtract(amount, unit);

    setTrackStartDate(startDate);
    setTrackEndDate(endDate);

    setLastSelectedTrack(`${amount}${unit}`);
  };


  const groupedAssets = {};
  let assetMarkers = [];

  const addOrCreateToGroup = (asset, groupName) => {
    if (!groupedAssets[groupName]) {
      groupedAssets[groupName] = [];
    }
    groupedAssets[groupName].push(asset);
  };

  if (getWhichisData && getWhichisData.GetUsersWhichis.whichis) {
    getWhichisData.GetUsersWhichis.whichis.forEach((asset) => {
      if (asset.status === 'distress') {
        isDistress = true;
      }

      if (!asset.whichigroup) {
        addOrCreateToGroup(asset, 'Ungroup');
      } else {
        addOrCreateToGroup(asset, asset.whichigroup.name);
      }
      if (asset.last_position) {
        assetMarkers.push({ ...asset.last_position, ...asset });
      }
    });
  }

  const filterAssets = (isReport) => {
    let filteredAssets = getWhichisData.GetUsersWhichis.whichis;
    const {
      type, usage, group, status, startDate, endDate,
    } = (isReport ? appliedReportFilter : appliedAssetFilter);
    if (type) {
      filteredAssets = filteredAssets.filter(asset => asset.type === type);
    }
    if (usage) {
      filteredAssets = filteredAssets.filter(asset => asset.usage === usage);
    }
    if (group) {
      const groupFilter = groupedAssets[group].map(asset => asset.id);
      filteredAssets = filteredAssets.filter(asset => groupFilter.includes(asset.id));
    }
    if (status) {
      filteredAssets = filteredAssets.filter(asset => asset.status === status);
    }
    if (startDate) {
      filteredAssets = filteredAssets.filter(asset => moment(asset.placeUpdatedAt) > startDate);
    }
    if (endDate) {
      filteredAssets = filteredAssets.filter(asset => moment(asset.placeUpdatedAt) < endDate);
    }

    console.log(appliedAssetFilter, appliedReportFilter, getWhichisData.GetUsersWhichis.whichis, isReport, filteredAssets);

    return filteredAssets;
  };

  if (selectedAssetTab === 'group') {
    assetMarkers = [];

    if (selectedAssetGroup && groupedAssets[selectedAssetGroup]) {
      const selectedGroupedAssets = groupedAssets[selectedAssetGroup];

      selectedGroupedAssets.forEach((asset) => {
        if (asset.last_position) {
          assetMarkers.push({ ...asset.last_position, ...asset });
        }
      });
    }
  } else if (selectedAssetTab === 'filter') {
    assetMarkers = [];

    if (getWhichisData && getWhichisData.GetUsersWhichis.whichis && Object.keys(appliedAssetFilter).length) {
      const filteredAssets = filterAssets();

      filteredAssets.forEach((asset) => {
        if (asset.last_position) {
          assetMarkers.push({ ...asset.last_position, ...asset });
        }
      });
    }
  }

  const mapGroupAssets = () => {
    if (!selectedAssetGroup) {
      return false;
    }
    const selectedGroupedAssets = groupedAssets[selectedAssetGroup];
    return mapAssets(selectedGroupedAssets);
  };

  const mapGroupReportAssets = () => {
    if (!selectedReportGroup) {
      return false;
    }
    const selectedGroupedAssets = groupedAssets[selectedReportGroup];
    return mapReportAssets(selectedGroupedAssets);
  };

  const handleAssetFilterChange = (value, key) => {
    setAssetFilters({
      ...assetFilters,
      [key]: value,
    });
  };

  const handleReportFilterChange = (value, key) => {
    setReportFilters({
      ...reportFilters,
      [key]: value,
    });
  };

  const applyReportFilter = () => {
    setAppliedReportFilter({
      ...reportFilters,
    });
  };

  const resetReportFilter = () => {
    setAppliedReportFilter({});
    setReportFilters({});
  };

  const applyAssetFilter = () => {
    setAppliedAssetFilter({
      ...assetFilters,
    });
  };

  const resetAssetFilter = () => {
    setAppliedAssetFilter({});
    setAssetFilters({});
  };

  const mapFilteredAssets = () => {
    if (getWhichisData && getWhichisData.GetUsersWhichis.whichis && Object.keys(appliedAssetFilter).length) {
      return mapAssets(filterAssets());
    }
    return [];
  };

  const mapFilteredReportAssets = () => {
    console.log(appliedReportFilter, reportFilters);
    if (getWhichisData && getWhichisData.GetUsersWhichis.whichis && Object.keys(appliedReportFilter).length) {
      return mapReportAssets(filterAssets(true));
    }
    return [];
  };

  const toMap = () => {
    toggleDrawer(false);
    history.push(`${url}`);
  };

  const refetchReportInfo = async () => {
    const asset = selectedReportAsset;
    await getReportInfo({
      variables: {
        whichiId: asset.id,
        whichiIds: [asset.id],
        from: selectedReportStartDate ? moment(selectedReportStartDate.format('YYYY/MM/DD 00:00:00')) : null,
        to: selectedReportEndDate ? moment(selectedReportEndDate.format('YYYY/MM/DD 23:59:59')) : null,
      },
    });
    setSelectedReportAsset(asset);
  };


  const mapReports = () => {
    if (reportInfoLoading) {
      return (
        <div style={{
          width: '100%', height: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: 1,
        }}
        >
          <CircularProgress />
        </div>
      );
    }
    let selectedReports = [];

    try {
      if (selectedReportInnerTab === 'average') {
        selectedReports = reportInfoData.GetDailyDistance.resultArr;
      } else if (selectedReportInnerTab === 'position') {
        selectedReports = reportInfoData.GetMyPlaces.messages.slice().reverse();
      } else if (selectedReportInnerTab === 'event') {
        selectedReports = reportInfoData.GetMessages.messages.slice().reverse();
      }

      // console.log(reportInfoData);

      // console.log(selectedReports.length);
    } catch (error) {
      // console.log(error);
      selectedReports = [];
    }

    if (!selectedReports.length) {
      return (
        <div style={{
          width: '100%', height: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: 1,
        }}
        >
          <CancelPresentation />
          <Typography variant="h6">No reports found.</Typography>
          <Typography variant="body1">If there&apos;s any problem, please contact us.</Typography>
        </div>
      );
    } if (selectedReportInnerTab === 'average') {
      return (
        selectedReports.sort((next, prev) => moment(prev.date) - moment(next.date)).map(report => (
          <AverageReportLayout mini>
            <Typography>
              {
                moment(report.date).format('YYYY/MM/DD')
              }
            </Typography>
            <Typography>
              {parseFloat(report.km).toFixed(2)}km
            </Typography>
            <Typography>
              {parseFloat(mapOption.speedUnit !== 'km/h' ? report.speed : report.speed * 1.852).toFixed(2)} {mapOption.speedUnit}
            </Typography>
          </AverageReportLayout>
        ))
      );
    } if (selectedReportInnerTab === 'position') {
      return (
        selectedReports.map(report => (
          <PositionReportLayout mini>
            <Typography variant="body2">
              {
                moment(report.createdAt).format('YYYY/MM/DD')
              }
              <br />
              {
                moment(report.createdAt).format('HH:mm:ss')
              }
            </Typography>
            <Typography variant="body2">
              { report.place && report.place.lat && `${parseFloat(report.place.lat).toFixed(3)}°` || '-'}
            </Typography>
            <Typography variant="body2">
              { report.place && report.place.lng && `${parseFloat(report.place.lng).toFixed(3)}°` || '-'}
            </Typography>
            <Typography variant="body2">
              { report.place && report.place.alt && `${parseInt(mapOption.altUnit !== 'feet' ? report.place.alt : report.place.alt * 3.281, 10)}${mapOption.altUnit !== 'feet' ? 'm' : 'feet'}` || '-'}
            </Typography>
            <Typography variant="body2">
              { report.place && report.place.speed && `${parseFloat(mapOption.speedUnit !== 'km/h' ? report.place.speed : report.place.speed * 1.852).toFixed(1)}${mapOption.speedUnit}` || '-'}
            </Typography>
            <Typography variant="body2">
              { report.place && report.place.course && `${report.place.course}°` || '-'}
            </Typography>
          </PositionReportLayout>
        ))
      );
    } if (selectedReportInnerTab === 'event') {
      return (
        selectedReports.map((report, index) => {
          let currentReportKind = messageKindMap[report.kind] || {};

          if (typeof (currentReportKind) === 'function') {
            currentReportKind = currentReportKind(report);
          }
          return (
            <EventReportLayout simple style={{ backgroundColor: currentReportKind.color, opacity: index % 2 === 0 ? 0.8 : 1 }}>
              <Typography>
                {
                  moment(report.createdAt).format('YYYY/MM/DD HH:mm:ss')
                }
              </Typography>
              <Typography>
                {
                  currentReportKind.status
                }
              </Typography>
              <Typography>
                {
                  currentReportKind.event
                }
              </Typography>
            </EventReportLayout>
          );
        })
      );
    }
  };

  const userAccesses = {
    map: 'all',
    asset: 'all',
    asset_config: 'semi',
    message: 'all',
    report: 'all',
    setting: 'all',
    administrator: 'all',
  };

  if (profileData && profileData.GetMyProfile.user) {
    const userProfile = profileData.GetMyProfile.user;

    switch (userProfile.role) {
      case ('administrator'): {
        break;
      }
      case ('agent'): {
        userAccesses.map = 'no-access';
        userAccesses.asset = 'info-only';
        userAccesses.message = 'no-access';
        userAccesses.report = 'no-access';
        userAccesses.setting = 'no-access';
        break;
      }
      case ('customer'): {
        userAccesses.administrator = 'no-access';
        userAccesses.asset_config = 'all';
        break;
      }
      case ('asset'): {
        userAccesses.asset = 'no-access';
        userAccesses.message = 'no-access';
        userAccesses.report = 'no-access';
        userAccesses.setting = 'no-access';
        userAccesses.administrator = 'no-access';
        break;
      }
      default: {
        userAccesses.map = 'no-access';
        userAccesses.asset = 'no-access';
        userAccesses.message = 'no-access';
        userAccesses.report = 'no-access';
        userAccesses.setting = 'no-access';
        userAccesses.administrator = 'no-access';
      }
    }
  }

  const isRightAssetEnabled = (userAccesses.asset === 'all')
  || !(userAccesses.message === 'no-access')
  || !(userAccesses.report === 'no-access')
  || userAccesses.map === 'all';

  if (profileData && profileData.GetMyProfile.user && profileData.GetMyProfile.user.role === 'agent' && location.pathname.split('/').slice(-1)[0] === 'dashboard') {
    return (
      <Redirect to={{ pathname: '/dashboard/assets' }} />
    );
  }

  const logout = () => {
    authHandler.signOut();
    history.push('/');
    apolloClient.clearStore();
  };

  const sendPollRequest = async () => {
    if (!selectedAsset) {
      alert('asset not selected.');
      return setOnSendPollRequest(false);
    }
    const result = await commitSendPollRequest({
      variables: {
        imei: selectedAsset.imei,
        reportNum: selectedPollRequestReportNum,
      },
    });

    if (result.data.AddTxPoll.ok) {
      localStorage.setItem(`${selectedAsset.imei}-last-sent-poll`, new Date().getTime());

      setSnackbarMessage(`Poll request successfully sent to asset ${selectedAsset.name}.`);
      setOnSendPollRequest(false);
    } else {
      setOnSendPollRequest(false);
      alert('Failed to send poll request. Please kindly report this issue to us.');
    }
  };

  const lastSentPollRequest = selectedAsset && localStorage.getItem(`${selectedAsset.imei}-last-sent-poll`) && moment(parseFloat(localStorage.getItem(`${selectedAsset.imei}-last-sent-poll`)));

  // console.log(path, url);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={classes.appBar}
      >
        {
          isMobile
          && (
          <Toolbar style={{ minHeight: 64 }}>
            <Tooltip
              placement="bottom"
              title="MENU"
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                edge="start"
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>

            </Tooltip>
            <div
              style={{
                display: 'flex', cursor: 'pointer', justifyContent: 'center', flexGrow: 1,
              }}
              onClick={toMap}
            >
              <Typography variant="h6" noWrap>
                MODOOTEL
              </Typography>
            </div>
            <Distress style={{ flexShrink: 0 }}>
              <img src={isDistress ? DistressWarning : DistressSafe} alt="distress" />
            </Distress>
          </Toolbar>
          )
          || (
          <Toolbar>
            <Tooltip
              placement="bottom"
              title="MENU"
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                edge="start"
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>
            <div
              style={{ display: 'flex', cursor: 'pointer' }}
              onClick={() => {
                toggleRightMenu(null);
                toggleDrawer(false);
                toMap();
              }}
            >
              <Typography variant="h6" noWrap>
                MODOOTEL
              </Typography>
              <Typography
                style={{
                  marginLeft: 24, display: 'flex', justifyContent: 'center', alignItems: 'center',
                }}
                variant="body1"
                noWrap
              >
                Command Center
                <Tooltip
                  placement="bottom"
                  title="HOME"
                >
                  <Language style={{ marginLeft: 24, fontSize: '2rem' }} />
                </Tooltip>
              </Typography>
            </div>
            <TopbarGroups />
            <TopbarProfile>
              <img src={CommandCenter} alt="ccenter" />
              {
                (profileLoading || !(profileData && profileData.GetMyProfile))
                && <CircularProgress size={24} style={{ color: 'white' }} />
                || <Typography>{profileData.GetMyProfile.user.userid}</Typography>
              }
              <Tooltip
                placement="bottom"
                title="Edit Profile"
              >
                <Link to={`${url}/profile`}>
                  <Edit />
                </Link>
              </Tooltip>
            </TopbarProfile>
            <Tooltip
              placement="bottom"
              title="Distress"
            >
              <Distress>
                <img src={isDistress ? DistressWarning : DistressSafe} alt="distress" />
              </Distress>
            </Tooltip>
          </Toolbar>
          )
        }
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        style={{ zIndex: 100, position: 'absolute' }}
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {
          (profileLoading || !profileData)
          && (
          <div style={{
            width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 144,
          }}
          >
            <CircularProgress color="secondary" />
          </div>
          )
          || (() => {
            const { GetMyProfile: { user: { role: userRole, profilePhoto } } } = profileData;
            const userFullName = profileData.GetMyProfile.user.userid;
            return (
              <DrawerContents>
                <IconButton
                  onClick={toggleDrawer}
                  style={{
                    position: 'absolute', padding: 0, right: 24, top: 24,
                  }}
                >
                  <Close color="secondary" />
                </IconButton>
                <Circle style={{ cursor: 'pointer', backgroundImage: `url(${photoURL(profilePhoto)})`, backgroundSize: '100% 100%' }} onClick={() => history.push(`${url}/profile`)} />
                <Typography color="textSecondary">Authority: {capitalize(userRole)}</Typography>
                <Typography color="textSecondary">ID: {userFullName}</Typography>
                {
                  isMobile && (
                    <>
                      <Link
                        style={{ width: '100%' }}
                        onClick={() => {
                          toggleRightMenu(null);
                          toggleDrawer(false);
                        }}
                        to={`${url}`}
                      >
                        <MobileDrawerButton>
                          <MapOutlined />
                          <Typography color="textSecondary">Map</Typography>
                        </MobileDrawerButton>
                      </Link>
                      {
                        !(userAccesses.asset === 'no-access')
                        && (
                        <Link
                          style={{ width: '100%' }}
                          onClick={() => {
                            toggleRightMenu(null);
                            toggleDrawer(false);
                          }}
                          to={`${url}/assets`}
                        >
                          <MobileDrawerButton>
                            <img src={IconAssets} alt="icon" />
                            <Typography color="textSecondary">Asset</Typography>
                          </MobileDrawerButton>
                        </Link>
                        )
                      }
                      {
                        !(userAccesses.message === 'no-access')
                        && (
                        <Link
                          style={{ width: '100%' }}
                          onClick={() => {
                            toggleRightMenu(null);
                            toggleDrawer(false);
                          }}
                          to={`${url}/messages`}
                        >
                          <MobileDrawerButton>
                            <img style={{ height: 15 }} src={IconMail} alt="icon" />
                            <Typography color="textSecondary">Message</Typography>
                          </MobileDrawerButton>
                        </Link>
                        )
                      }
                      {
                        !(userAccesses.report === 'no-access')
                        && (
                        <Link
                          style={{ width: '100%', marginBottom: 16 }}
                          onClick={() => {
                            toggleRightMenu(null);
                            toggleDrawer(false);
                          }}
                          to={`${url}/report`}
                        >
                          <MobileDrawerButton>
                            <img src={IconStat} alt="icon" />
                            <Typography color="textSecondary">Report</Typography>
                          </MobileDrawerButton>
                        </Link>
                        )
                      }
                    </>
                  )
                  || (
                    <>
                      <div>
                        {
                          !(userAccesses.asset === 'no-access')
                          && (
                          <Link
                            style={userAccesses.message === 'no-access' && { width: 264, marginRight: 0 } || {}}
                            onClick={() => {
                              toggleRightMenu(null);
                              toggleDrawer(false);
                            }}
                            to={`${url}/assets`}
                          >
                            <DrawerButton style={userAccesses.message === 'no-access' && { width: '100%' } || {}}>
                              <img style={{ width: 24 }} src={IconAssets} alt="icon" />
                              <Typography color="textSecondary">Asset</Typography>
                            </DrawerButton>
                          </Link>
                          )
                        }
                        {
                          !(userAccesses.message === 'no-access')
                          && (
                          <Link
                            style={userAccesses.asset === 'no-access' && { width: 264, marginRight: 0 } || {}}
                            onClick={() => {
                              toggleRightMenu(null);
                              toggleDrawer(false);
                            }}
                            to={`${url}/messages`}
                          >
                            <DrawerButton style={userAccesses.asset === 'no-access' && { width: '100%' } || {}}>
                              <img style={{ width: 32 }} src={IconMail} alt="icon" />
                              <Typography color="textSecondary">Message</Typography>
                            </DrawerButton>
                          </Link>
                          )
                        }
                      </div>
                      <div>
                        {
                          !(userAccesses.report === 'no-access')
                          && (
                          <Link
                            style={userAccesses.setting === 'no-access' && { width: 264, marginRight: 0 } || {}}
                            onClick={() => {
                              toggleRightMenu(null);
                              toggleDrawer(false);
                            }}
                            to={`${url}/report`}
                          >
                            <DrawerButton style={userAccesses.setting === 'no-access' && { width: '100%' } || {}}>
                              <img style={{ width: 24 }} src={IconStat} alt="icon" />
                              <Typography color="textSecondary">Report</Typography>
                            </DrawerButton>
                          </Link>
                          )
                        }
                        {
                          !(userAccesses.setting === 'no-access')
                          && (
                          <Link
                            style={userAccesses.report === 'no-access' && { width: 264, marginRight: 0 } || {}}
                            onClick={() => {
                              toggleRightMenu(null);
                              toggleDrawer(false);
                            }}
                            to={`${url}/setting`}
                          >
                            <DrawerButton style={userAccesses.report === 'no-access' && { width: '100%' } || {}}>
                              <Settings style={{ width: 48, color: 'white', height: '1.5em' }} />
                              <Typography color="textSecondary">Settings</Typography>
                            </DrawerButton>
                          </Link>
                          )
                        }
                      </div>
                      {
                        profileData && profileData.GetMyProfile && profileData.GetMyProfile.user && ['administrator', 'agent'].includes(profileData.GetMyProfile.user.role)
                        && (
                          <div>
                            <Link
                              style={{ width: 264, marginRight: 0 }}
                              onClick={() => {
                                toggleRightMenu(null);
                                toggleDrawer(false);
                              }}
                              to={`${url}/administrator`}
                            >
                              <DrawerButton style={{ width: '100%' }}>
                                <SupervisedUserCircle style={{ width: 48, color: 'white', height: '1.5em' }} />
                                <Typography color="textSecondary">Manage User</Typography>
                              </DrawerButton>
                            </Link>
                          </div>
                        )
                      }
                    </>
                  )
                }
                <Button onClick={logout} style={{ color: 'white', width: 264 }}><ExitToApp style={{ marginRight: 12 }} /> Logout</Button>
              </DrawerContents>
            );
          })()
        }
      </Drawer>
      {
        (
          isRightAssetEnabled
        )
        && (
          isMobile
          && (
            location.pathname === '/dashboard'
            && (
              <BottomMobileDrawer>
                {
                  (userAccesses.asset === 'all' || userAccesses.map === 'all')
                  && (
                    <RightListItem style={{ padding: 0 }} onClick={() => toggleRightMenu('assets')} selected={selectedRightMenu === 'assets'} button>
                      <ListItemIcon style={{ padding: 8 }}><img style={{ width: 24 }} src={IconAssets} alt="icon" /></ListItemIcon>
                    </RightListItem>
                  )
                }
                {
                  (userAccesses.message !== 'no-access')
                  && (
                    <RightListItem style={{ padding: 0 }} onClick={() => { toggleRightMenu('message'); setNeedCheckMessage(false); }} selected={selectedRightMenu === 'message'} button>
                      <ListItemIcon style={{ padding: 8 }}><img style={{ width: 24 }} src={IconMail} alt="icon" /></ListItemIcon>
                      {
                        needCheckMessage
                        && (
                        <div style={{
                          position: 'absolute', width: 8, height: 8, borderRadius: 8, background: 'red', top: 3, right: 5,
                        }}
                        />
                        )
                      }
                    </RightListItem>
                  )
                }
                {
                  (userAccesses.report !== 'no-access' || userAccesses.map === 'all')
                  && (
                    <RightListItem style={{ padding: 0 }} onClick={() => toggleRightMenu('report')} selected={selectedRightMenu === 'report'} button>
                      <ListItemIcon style={{ padding: 8 }}><img style={{ width: 24 }} src={IconStat} alt="icon" /></ListItemIcon>
                    </RightListItem>
                  )
                }
              </BottomMobileDrawer>
            )
            || <> </>
          ) || (
            <RightDrawer
              variant="permanent"
              className={clsx(classes.drawer, classes.drawerClose)}
              classes={{
                paper: classes.drawerClose,
              }}
              style={{
                position: 'fixed',
                right: 0,
              }}
              anchor="right"
            >
              <Divider />
              <List>
                {
                  (userAccesses.asset === 'all' || userAccesses.map === 'all')
                  && (

                  <Tooltip
                    placement="bottom"
                    title="Asset"
                  >
                    <RightListItem style={{ padding: 0 }} onClick={() => toggleRightMenu('assets')} selected={selectedRightMenu === 'assets'} button>
                      <ListItemIcon style={{ padding: 8 }}><img style={{ width: 24 }} src={IconAssets} alt="icon" /></ListItemIcon>
                    </RightListItem>
                  </Tooltip>
                  )
                }
                {
                  (userAccesses.message !== 'no-access')
                  && (
                    <Tooltip
                      placement="bottom"
                      title="Message"
                    >
                      <RightListItem style={{ padding: 0 }} onClick={() => { toggleRightMenu('message'); setNeedCheckMessage(false); }} selected={selectedRightMenu === 'message'} button>
                        <ListItemIcon style={{ padding: 8 }}><img style={{ width: 24 }} src={IconMail} alt="icon" /></ListItemIcon>
                        {
                          needCheckMessage
                          && (
                          <div style={{
                            position: 'absolute', width: 8, height: 8, borderRadius: 8, background: 'red', top: 3, right: 5,
                          }}
                          />
                          )
                        }
                      </RightListItem>
                    </Tooltip>
                  )
                }
                {
                  (userAccesses.report !== 'no-access' || userAccesses.map === 'all')
                  && (
                    <Tooltip
                      placement="bottom"
                      title="Reports"
                    >
                      <RightListItem style={{ padding: 0 }} onClick={() => toggleRightMenu('report')} selected={selectedRightMenu === 'report'} button>
                        <ListItemIcon style={{ padding: 8 }}><img style={{ width: 24 }} src={IconStat} alt="icon" /></ListItemIcon>
                      </RightListItem>
                    </Tooltip>
                  )
                }
              </List>
            </RightDrawer>
          )
        )
      }
      {
        isRightAssetEnabled
        && (isMobile
          && location.pathname !== '/dashboard' && <></>
          || isMobile && (
            <>
              <MobileRightAssets open={selectedRightMenu === 'assets' && mobileShowRightAsset}>
                <WichiTabs
                  style={{
                    background: '#30303040',
                  }}
                  padding={32}
                  isMobile={isMobile}
                  value={selectedAssetTab}
                  onChange={(event, newValue) => setSelectedAssetTab(newValue)}
                >
                  <WichiTab style={{ color: '#fff' }} value="recent" label={<AccessTime />} />
                  {
                    userAccesses.asset === 'all'
                    && (
                      [
                        <WichiTab style={{ color: '#fff' }} value="group" label={<Layers />} />,
                        <WichiTab style={{ color: '#fff' }} value="filter" label={<FilterList />} />,
                      ]
                    )
                  }
                  <WichiTab style={{ color: '#fff' }} value="option" label={<Settings />} />
                </WichiTabs>
                {
                  getWhichisLoading
                  && (
                  <div style={{
                    width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 144,
                  }}
                  >
                    <CircularProgress color="secondary" />
                  </div>
                  )
                  || selectedAssetTab === 'recent'
                  && (
                  <AssetList isMobile={isMobile} adjustment={selectedAsset ? -550 : 0}>
                    {
                      mapAssets()
                    }
                  </AssetList>
                  )
                  || selectedAssetTab === 'group'
                  && (
                    <>
                      <AssetList isMobile={isMobile} adjustment={selectedAsset ? -550 : 0}>
                        <AssetGroupIndicator isMobile={isMobile}>
                          Assets &gt; Group &gt;
                          <SquareSelect
                            value={selectedAssetGroup}
                            onChange={event => setSelectedAssetGroup(event.target.value)}
                          >
                            {
                              Object.keys(groupedAssets).map(groupKey => (<MenuItem value={groupKey}>{groupKey}</MenuItem>))
                            }
                          </SquareSelect>
                        </AssetGroupIndicator>
                        {
                          mapGroupAssets()
                        }
                      </AssetList>
                    </>
                  )
                  || selectedAssetTab === 'filter'
                  && (
                    <>
                      {
                      !selectedAsset
                      && (
                        <RightAssetFilter isMobile={isMobile}>
                          <div>
                            <div>
                              <span>Type</span>
                              <SquareSelect value={assetFilters.type || ''} onChange={event => handleAssetFilterChange(event.target.value, 'type')} displayEmpty>
                                <MenuItem value="">ALL</MenuItem>
                                {
                                  assetInfoTypes.map(type => (
                                    <MenuItem value={type}>{type}</MenuItem>
                                  ))
                                }
                              </SquareSelect>
                            </div>
                            <div>
                              <span>Usage</span>
                              <SquareSelect value={assetFilters.usage || ''} onChange={event => handleAssetFilterChange(event.target.value, 'usage')} displayEmpty>
                                <MenuItem value="">ALL</MenuItem>
                                {
                                  (assetFilters.type
                                  && assetInfoUsage[assetFilters.type]
                                  || allAssetInfoUsage).map(usage => (
                                    <MenuItem value={usage}>{usage}</MenuItem>
                                  ))
                                }
                              </SquareSelect>
                            </div>
                          </div>
                          <div>
                            <div>
                              <span>Status</span>
                              <SquareSelect value={assetFilters.status || ''} onChange={event => handleAssetFilterChange(event.target.value, 'status')} displayEmpty>
                                <MenuItem value="">ALL</MenuItem>
                                {
                                  assetInfoStatus.map(status => (
                                    <MenuItem value={status}>{capitalize(status)}</MenuItem>
                                  ))
                                }
                              </SquareSelect>
                            </div>
                            <div>
                              <span>Group</span>
                              <SquareSelect value={assetFilters.group || ''} onChange={event => handleAssetFilterChange(event.target.value, 'group')} displayEmpty>
                                <MenuItem value="">ALL</MenuItem>
                                {
                                  Object.keys(groupedAssets).map(groupKey => (
                                    <MenuItem value={groupKey}>{groupKey}</MenuItem>
                                  ))
                                }
                              </SquareSelect>
                            </div>
                          </div>
                          <div>
                            <div>
                              { assetFilters.startDate ? assetFilters.startDate.format('YYYY/MM/DD') : 'Start Date'}
                              <KeyboardDatePicker
                                value={assetFilters.startDate}
                                onChange={date => handleAssetFilterChange(date, 'startDate')}
                                format="YYYY/MM/DD"
                              />
                            </div>
                            <div>
                              { assetFilters.endDate ? assetFilters.endDate.format('YYYY/MM/DD') : 'End Date'}
                              <KeyboardDatePicker
                                color="secondary"
                                value={assetFilters.endDate}
                                onChange={date => handleAssetFilterChange(date, 'endDate')}
                                format="YYYY/MM/DD"
                              />
                            </div>
                          </div>
                          <div>
                            <WichiPrimaryButton onClick={applyAssetFilter} variant="contained">
                              Apply
                            </WichiPrimaryButton>
                            <WichiPrimaryButton onClick={resetAssetFilter} variant="contained">
                              Refresh
                            </WichiPrimaryButton>
                          </div>
                        </RightAssetFilter>
                      )
                    }
                      <AssetList isMobile={isMobile} adjustment={-160 + (selectedAsset ? -550 : 0)}>
                        {
                          mapFilteredAssets()
                        }
                      </AssetList>
                    </>
                  ) || selectedAssetTab === 'option'
                  && (
                    <div style={{ height: 'auto', overflow: 'auto' }}>
                      <AssetExpansionPanel isMobile={isMobile}>
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMore />}
                        >
                          <Typography style={{ display: 'flex' }} color="textSecondary"><MapOutlined style={{ marginRight: 12 }} />Map Settings</Typography>
                        </ExpansionPanelSummary>
                        <AssetExpansionPanelDetails>
                          <div>
                            Map Style
                            <SquareSelect
                              value={mapOption.mapType}
                              onChange={event => setMapOption('mapType', event.target.value)}
                              style={{ width: 240 }}
                            >
                              <MenuItem value="roadmap">Road Map</MenuItem>
                              <MenuItem value="satellite">Satellite</MenuItem>
                              <MenuItem value="hybrid">Hybrid</MenuItem>
                              <MenuItem value="terrain">Terrain</MenuItem>
                            </SquareSelect>
                          </div>
                          <div>
                            Brightness
                            <Slider
                              defaultValue={100}
                              step={10}
                              min={10}
                              max={100}
                              value={mapOption.opacity}
                              onChange={(event, value) => setMapOption('opacity', value)}
                              style={{ width: 240 }}
                            />
                          </div>
                        </AssetExpansionPanelDetails>
                      </AssetExpansionPanel>
                      <AssetExpansionPanel isMobile={isMobile}>
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMore />}
                        >
                          <Typography style={{ display: 'flex' }} color="textSecondary"><DirectionsCar style={{ marginRight: 12 }} /> Asset Settings</Typography>
                        </ExpansionPanelSummary>
                        <AssetExpansionPanelDetails>
                          <div>
                            Default (Ungroup) Asset Color
                            <p onClick={() => setOnColorPick(true)} style={{ cursor: 'pointer' }}><GroupIcon color={mapOption.defaultColor} /><Edit /></p>
                          </div>
                          <div>
                            Asset Icon Size
                            <Slider
                              defaultValue={20}
                              step={5}
                              min={10}
                              max={30}
                              value={mapOption.assetSize}
                              onChange={(event, value) => setMapOption('assetSize', value)}
                              style={{ width: 240 }}
                            />
                          </div>
                          <div>
                            Track Icon Size
                            <Slider
                              defaultValue={20}
                              step={5}
                              min={10}
                              max={30}
                              value={mapOption.trackSize}
                              onChange={(event, value) => setMapOption('trackSize', value)}
                              style={{ width: 240 }}
                            />
                          </div>
                          <div>
                            Show Label
                            <Switch checked={mapOption.showLabel} onChange={() => setMapOption('showLabel', !mapOption.showLabel)} />
                          </div>
                          <div>
                            Maximum number of tracks
                            <SquareSelect
                              style={{ width: 240 }}
                              value={mapOption.maxTrack}
                              onChange={event => setMapOption('maxTrack', event.target.value)}
                            >
                              <MenuItem value={100}>100</MenuItem>
                              <MenuItem value={250}>250</MenuItem>
                              <MenuItem value={500}>500</MenuItem>
                              <MenuItem value={1000}>1000</MenuItem>
                              <MenuItem value={2500}>2500</MenuItem>
                            </SquareSelect>
                          </div>
                        </AssetExpansionPanelDetails>
                      </AssetExpansionPanel>
                      <AssetExpansionPanel isMobile={isMobile}>
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMore />}
                        >
                          <Typography style={{ display: 'flex' }} color="textSecondary"><SquareFoot style={{ marginRight: 12 }} /> Unit Settings</Typography>
                        </ExpansionPanelSummary>
                        <AssetExpansionPanelDetails>
                          <div>
                            Speed
                            <SquareSelect
                              style={{ width: 240 }}
                              value={mapOption.speedUnit}
                              onChange={event => setMapOption('speedUnit', event.target.value)}
                            >
                              <MenuItem value="knot">knot</MenuItem>
                              <MenuItem value="km/h">km/h</MenuItem>
                            </SquareSelect>
                          </div>
                          <div>
                            Altitude
                            <SquareSelect
                              style={{ width: 240 }}
                              value={mapOption.altUnit}
                              onChange={event => setMapOption('altUnit', event.target.value)}
                            >
                              <MenuItem value="feet">feet</MenuItem>
                              <MenuItem value="meter">meter</MenuItem>
                            </SquareSelect>
                          </div>
                        </AssetExpansionPanelDetails>
                      </AssetExpansionPanel>
                    </div>
                  )
                }
                {
                  selectedAsset && selectedAssetTab !== 'option'
                  && (
                    <RightAssetDetail isMobile={isMobile}>
                      <div>
                        <AssetSVG style={{ marginRight: 8 }} icon={selectedAsset.icon || selectedAsset.whichigroup && selectedAsset.whichigroup.icon} color={selectedAsset.whichigroup && selectedAsset.whichigroup.color} />
                        <Typography>
                          {
                            selectedAsset.name
                          }
                          {
                            userAccesses.asset !== 'no-access'
                            && (
                            <Link onClick={() => toggleRightMenu('assets')} to={`${url}/assets?selected=${selectedAsset.id}`}>
                              <Edit />
                              Edit
                            </Link>
                            )
                          }
                        </Typography>
                        <Close onClick={unSelectAsset} />
                      </div>
                      <AssetDetailInfo isMobile={isMobile}>
                        <img src={photoURL(selectedAsset && selectedAsset.profilepicture)} alt="" style={{ backgroundImage: `url(${NoProfile})`, backgroundSize: '100% 100%' }} />
                        <div>
                          <Typography>
                            <span>Group</span>
                            <GroupIcon color={selectedAsset.whichigroup && selectedAsset.whichigroup.color}>{selectedAsset.whichigroup && firstUpperLetter(selectedAsset.whichigroup.name)}</GroupIcon>
                            {
                              selectedAsset.whichigroup
                              && selectedAsset.whichigroup.name
                              || 'Ungroup'
                            }
                          </Typography>
                          <Typography>
                            <span>Pilot</span>
                            { selectedAsset.pilot || '-' }
                          </Typography>
                          <Typography>
                            <span>Type</span>
                            { selectedAsset.type || '-' }
                          </Typography>
                          <Typography>
                            <span>Usage</span>
                            { selectedAsset.usage || '-' }
                          </Typography>
                          <Typography>
                            <span>Status</span>
                            { capitalize(selectedAsset.status) || '-' }
                            {
                              selectedAsset.status === 'distress'
                              && (
                              <DistressOffButton onClick={() => turnDistressOff(selectedAsset.imei)} variant="contained">
                                Turn Off
                              </DistressOffButton>
                              )
                            }
                          </Typography>
                        </div>
                      </AssetDetailInfo>
                      <AssetDetailTrack isMobile={isMobile}>
                        <div>
                          <Button className={lastSelectedTrack === '6hour' && 'last-selected' || ''} onClick={() => autoSelectTrackDate(6, 'hour')} variant="contained">
                            6 Hour
                          </Button>
                          <Button className={lastSelectedTrack === '12hour' && 'last-selected' || ''} onClick={() => autoSelectTrackDate(12, 'hour')} variant="contained">
                            12 Hour
                          </Button>
                          <Button className={lastSelectedTrack === '1day' && 'last-selected' || ''} onClick={() => autoSelectTrackDate(1, 'day')} variant="contained">
                            1 Day
                          </Button>
                          <Button className={lastSelectedTrack === '3day' && 'last-selected' || ''} onClick={() => autoSelectTrackDate(3, 'day')} variant="contained">
                            3 Days
                          </Button>
                          <Button className={lastSelectedTrack === '1week' && 'last-selected' || ''} onClick={() => autoSelectTrackDate(1, 'week')} variant="contained">
                            1 Week
                          </Button>
                          <Button
                            className={lastSelectedTrack === 'all' && 'last-selected' || ''}
                            onClick={() => {
                              setTrackStartDate(null);
                              setTrackEndDate(null);
                              setLastSelectedTrack('all');
                            }}
                            variant="contained"
                          >
                            All
                          </Button>
                        </div>
                        <div>
                          <AssetTimeline>
                            { trackStartDate ? trackStartDate.format('YYYY/MM/DD') : 'Start Date'}
                            <KeyboardDatePicker

                              value={trackStartDate}
                              onChange={date => setTrackStartDate(date)}
                              format="YYYY/MM/DD"
                            />
                          </AssetTimeline>
                          <AssetTimeline>
                            { trackEndDate ? trackEndDate.format('YYYY/MM/DD') : 'End Date'}
                            <KeyboardDatePicker
                              color="secondary"
                              value={trackEndDate}
                              onChange={date => setTrackEndDate(date)}
                              format="YYYY/MM/DD"
                            />
                          </AssetTimeline>
                        </div>
                        <div style={{ display: 'flex', marginTop: 8 }}>
                          <WichiPrimaryButton style={{ margin: 0 }} width={userAccesses.asset !== 'no-access' ? '50%' : '100%'} onClick={showTrackFromSelecedAsset} variant="contained">
                            Show Track
                          </WichiPrimaryButton>
                          {
                            userAccesses.asset !== 'no-access'
                            && (
                              <WichiPrimaryButton
                                style={{ margin: 0, marginLeft: 8 }}
                                width="50%"
                                onClick={() => {
                                  getRecentSettings({
                                    variables: {
                                      imei: selectedAsset.imei,
                                    },
                                  });
                                  setOnSendPollRequest(true);
                                }}
                                variant="contained"
                              >
                                Send Poll Request
                              </WichiPrimaryButton>
                            )
                          }
                        </div>
                      </AssetDetailTrack>
                      <AssetDetailOthers isMobile={isMobile}>
                        <Typography>
                          <span>
                            Information
                          </span>
                          {
                            selectedAsset.information || '-'
                          }
                        </Typography>
                        <Typography>
                          <span>
                            IMEI
                          </span>
                          {
                            selectedAsset.imei || '-'
                          }
                        </Typography>
                        <Typography>
                          <span>
                            Serial Number
                          </span>
                          {
                            selectedAsset.serialNumber || '-'
                          }
                        </Typography>
                        <Typography>
                          <span>
                            Service Plan
                          </span>
                          {
                            selectedAsset.servicePlan || '-'
                          }
                        </Typography>
                      </AssetDetailOthers>
                    </RightAssetDetail>
                  )
                }
                {
                  selectedRightMenu === 'assets' && <MobileRightAssetHandle onClick={() => setMobileShowRightAsset(!mobileShowRightAsset)} />
                }
              </MobileRightAssets>
              <MobileRightAssets open={selectedRightMenu === 'message' && mobileShowRightAsset}>
                <WichiTabs
                  padding={32}
                  style={{
                    background: '#30303040',
                  }}
                  value={selectedMessageTab}
                  onChange={(event, newValue) => setSelectedMessageTab(newValue)}
                >
                  <WichiTab minWidth="33.33%" style={{ color: '#fff' }} value="recent" label="Recent" />
                </WichiTabs>
                <FilterWrapper padding={0}>
                  <Search style={{ color: '999999', marginRight: 12 }} /><InputBase value={messageSearchKeyword} onChange={event => setMessageSearchKeyword(event.target.value)} style={{ width: '100%' }} placeholder="Search" />
                </FilterWrapper>
                {
                  getWhichisLoading
                  && (
                  <div style={{
                    width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 144,
                  }}
                  >
                    <CircularProgress color="secondary" />
                  </div>
                  )
                  || selectedMessageTab === 'recent'
                  && (
                  <AssetList isMobile={isMobile} noBackground adjustment={selectedMessageView ? -400 : 0}>
                    {
                      mapMessages()
                    }
                  </AssetList>
                  )
                }
                {
                  selectedMessageView
                  && (
                  <MessageView isMobile={isMobile}>
                    <div>
                      <Typography>
                        {
                          selectedMessageView.name
                        }
                      </Typography>
                      <IconButton
                        onClick={() => setSelectedMessageView(null)}
                        style={{
                          position: 'absolute', padding: 0, right: 6, top: 6,
                        }}
                      >
                        <Close color="primary" />
                      </IconButton>
                    </div>
                    {
                      messagesLoading
                      && (
                      <div style={{
                        width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%',
                      }}
                      >
                        <CircularProgress color="secondary" />
                      </div>
                      )
                      || (
                        <>
                          <div ref={messageLogsRef}>
                            {
                              mapMessageLogs([...messagesData.GetMessages.messages])
                            }
                          </div>
                          <div>
                            <InputBase
                              inputRef={messageInputRef}
                              inputComponent="textarea"
                              value={messageInput}
                              onKeyDown={onEnter(sendMessage, () => { if (getByteLength(messageInput) > 269) return; setMessageInput(`${messageInput}\n`); setTimeout(() => { messageInputRef.current.style.height = ''; messageInputRef.current.style.height = `${messageInputRef.current.scrollHeight - 13}px`; window.messageInputRef = messageInputRef; }, 10); })}
                              onChange={(event) => {
                                if (event.keyCode === 13 && !event.ctrlKey) return;
                                if (getByteLength(event.target.value) > 269) return;
                                setMessageInput(event.target.value); messageInputRef.current.style.height = ''; messageInputRef.current.style.height = `${messageInputRef.current.scrollHeight - 13}px`; window.messageInputRef = messageInputRef;
                              }}
                              style={{ width: '100%' }}
                              placeholder="Text to send..."
                            />
                            {
                              sendMessageLoading
                              && <CircularProgress color="primary" />
                              || <Send onClick={sendMessage} />
                            }
                          </div>
                        </>
                      )
                      }
                  </MessageView>
                  )
                }
                {
                  selectedRightMenu === 'message' && <MobileRightAssetHandle onClick={() => setMobileShowRightAsset(!mobileShowRightAsset)} />
                }
              </MobileRightAssets>
              <MobileRightAssets open={selectedRightMenu === 'report' && mobileShowRightAsset}>
                <WichiTabs
                  style={{
                    background: '#30303040',
                  }}
                  padding={32}
                  value={selectedReportTab}
                  onChange={(event, newValue) => setSelectedReportTab(newValue)}
                >
                  <WichiTab minWidth="33.33%" style={{ color: '#fff' }} value="recent" label={<AccessTime />} />
                  {
                    userAccesses.asset === 'all'
                    && (
                      [
                        <WichiTab style={{ color: '#fff' }} value="group" label={<Layers />} />,
                        <WichiTab style={{ color: '#fff' }} value="filter" label={<FilterList />} />,
                      ]
                    )
                  }
                </WichiTabs>
                {
                  getWhichisLoading
                  && (
                  <div style={{
                    width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 144,
                  }}
                  >
                    <CircularProgress color="secondary" />
                  </div>
                  )
                  || selectedReportTab === 'recent'
                  && (
                  <AssetList isMobile={isMobile} adjustment={selectedReportAsset ? -550 : 0}>
                    {
                      mapReportAssets()
                    }
                  </AssetList>
                  )
                  || selectedReportTab === 'group'
                  && (
                    <>
                      <AssetList isMobile={isMobile} adjustment={selectedReportAsset ? -550 : 0}>
                        <AssetGroupIndicator isMobile={isMobile}>
                          Assets &gt; Group &gt;
                          <SquareSelect
                            value={selectedReportGroup}
                            onChange={event => setSelectedReportGroup(event.target.value)}
                          >
                            {
                              Object.keys(groupedAssets).map(groupKey => (<MenuItem value={groupKey}>{groupKey}</MenuItem>))
                            }
                          </SquareSelect>
                        </AssetGroupIndicator>
                        {
                          mapGroupReportAssets()
                        }
                      </AssetList>
                    </>
                  )
                  || selectedReportTab === 'filter'
                  && (
                    <>
                      {
                        !selectedReportAsset
                        && (
                          <RightAssetFilter isMobile={isMobile}>
                            <div>
                              <div>
                                <span>Type</span>
                                <SquareSelect value={reportFilters.type || ''} onChange={event => handleReportFilterChange(event.target.value, 'type')} displayEmpty>
                                  <MenuItem value="">ALL</MenuItem>
                                  {
                                    assetInfoTypes.map(type => (
                                      <MenuItem value={type}>{type}</MenuItem>
                                    ))
                                  }
                                </SquareSelect>
                              </div>
                              <div>
                                <span>Usage</span>
                                <SquareSelect value={reportFilters.usage || ''} onChange={event => handleReportFilterChange(event.target.value, 'usage')} displayEmpty>
                                  <MenuItem value="">ALL</MenuItem>
                                  {
                                    (reportFilters.type
                                    && assetInfoUsage[reportFilters.type]
                                    || allAssetInfoUsage).map(usage => (
                                      <MenuItem value={usage}>{usage}</MenuItem>
                                    ))
                                  }
                                </SquareSelect>
                              </div>
                            </div>
                            <div>
                              <div>
                                <span>Status</span>
                                <SquareSelect value={reportFilters.status || ''} onChange={event => handleReportFilterChange(event.target.value, 'status')} displayEmpty>
                                  <MenuItem value="">ALL</MenuItem>
                                  {
                                    assetInfoStatus.map(status => (
                                      <MenuItem value={status}>{capitalize(status)}</MenuItem>
                                    ))
                                  }
                                </SquareSelect>
                              </div>
                              <div>
                                <span>Group</span>
                                <SquareSelect value={reportFilters.group || ''} onChange={event => handleReportFilterChange(event.target.value, 'group')} displayEmpty>
                                  <MenuItem value="">ALL</MenuItem>
                                  {
                                    Object.keys(groupedAssets).map(groupKey => (
                                      <MenuItem value={groupKey}>{groupKey}</MenuItem>
                                    ))
                                  }
                                </SquareSelect>
                              </div>
                            </div>
                            <div>
                              <div>
                                { reportFilters.startDate ? reportFilters.startDate.format('YYYY/MM/DD') : 'Start Date'}
                                <KeyboardDatePicker
                                  value={reportFilters.startDate}
                                  onChange={date => handleReportFilterChange(date, 'startDate')}
                                  format="YYYY/MM/DD"
                                />
                              </div>
                              <div>
                                { reportFilters.endDate ? reportFilters.endDate.format('YYYY/MM/DD') : 'End Date'}
                                <KeyboardDatePicker
                                  color="secondary"
                                  value={reportFilters.endDate}
                                  onChange={date => handleReportFilterChange(date, 'endDate')}
                                  format="YYYY/MM/DD"
                                />
                              </div>
                            </div>
                            <div>
                              <WichiPrimaryButton onClick={applyReportFilter} variant="contained">
                                Apply
                              </WichiPrimaryButton>
                              <WichiPrimaryButton onClick={resetReportFilter} variant="contained">
                                Refresh
                              </WichiPrimaryButton>
                            </div>
                          </RightAssetFilter>
                        )
                      }
                      <AssetList isMobile={isMobile} adjustment={-160 + (selectedReportAsset ? -550 : 0)}>
                        {
                          mapFilteredReportAssets()
                        }
                      </AssetList>
                    </>
                  )
                }
                {
                  selectedReportAsset
                  && (
                  <ReportInner style={{ height: 402 }}>
                    <div>
                      <WichiTabs padding={32} value={selectedReportInnerTab} onChange={(event, newValue) => setSelectedReportInnerTab(newValue)}>
                        <WichiTab minWidth="33.33%" style={{ color: '#fff' }} value="event" label="Event" />
                        <WichiTab minWidth="33.33%" style={{ color: '#fff' }} value="average" label="Average" />
                        <WichiTab minWidth="33.33%" style={{ color: '#fff' }} value="position" label="Position" />
                      </WichiTabs>
                    </div>
                    <ReportAssetHeader>
                      <Typography>
                        <span>
                          <GroupIcon
                            group={selectedReportAsset.whichigroup}
                          >
                            {selectedReportAsset.whichigroup && firstUpperLetter(selectedReportAsset.whichigroup.name)}
                          </GroupIcon>
                          {
                            selectedReportAsset.whichigroup
                            && selectedReportAsset.whichigroup.name
                            || 'Ungroup'
                          }
                          <KeyboardArrowRight />
                        </span>
                        <span style={{
                          overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%', display: 'inline-block',
                        }}
                        >
                          {
                            selectedReportAsset.name
                          }
                        </span>
                        {
                          selectedReportInnerTab === 'event'
                          && <WichiPrimaryButton href={`${API_HOST}/event_download?whichiid=${selectedReportAsset && selectedReportAsset.id}&from=${selectedReportStartDate && selectedReportStartDate.toISOString()}&to=${selectedReportEndDate && selectedReportEndDate.toISOString()}`} style={{ flexShrink: 0 }} width="160px">Export Excel File</WichiPrimaryButton>
                          || selectedReportInnerTab === 'average' && <WichiPrimaryButton href={`${API_HOST}/distance_download?whichiid=${selectedReportAsset && selectedReportAsset.id}&from=${selectedReportStartDate && selectedReportStartDate.toISOString()}&to=${selectedReportEndDate && selectedReportEndDate.toISOString()}`} style={{ flexShrink: 0 }} width="160px">Export Excel File</WichiPrimaryButton>
                          || selectedReportInnerTab === 'position' && <WichiPrimaryButton href={`${API_HOST}/position_download?whichiid=${selectedReportAsset && selectedReportAsset.id}&from=${selectedReportStartDate && selectedReportStartDate.toISOString()}&to=${selectedReportEndDate && selectedReportEndDate.toISOString()}`} style={{ flexShrink: 0 }} width="160px">Export Excel File</WichiPrimaryButton>
                        }
                      </Typography>
                    </ReportAssetHeader>
                    <ReportAssetFilters>
                      <ReportAssetTimeline>
                        <DatePicker
                          label="Start Date"
                          value={selectedReportStartDate}
                          onChange={date => setReportStartDate(date)}
                          format="YYYY/MM/DD"
                          placeholder="Start Date"
                        />
                      </ReportAssetTimeline>
                      <ReportAssetTimeline>
                        <DatePicker
                          label="End Date"
                          color="secondary"
                          value={selectedReportEndDate}
                          onChange={date => setReportEndDate(moment(date.format('YYYY/MM/DD 23:59:59')))}
                          format="YYYY/MM/DD"
                          placeholder="End Date"
                        />
                      </ReportAssetTimeline>
                      <GrowPadder />
                      <WichiPrimaryButton onClick={refetchReportInfo} width="160px">Applys</WichiPrimaryButton>
                    </ReportAssetFilters>
                    <ReportList>
                      {
                        mapReports()
                      }
                    </ReportList>
                  </ReportInner>
                  )
                }
                {
                  selectedRightMenu === 'report' && <MobileRightAssetHandle onClick={() => setMobileShowRightAsset(!mobileShowRightAsset)} />
                }
              </MobileRightAssets>
            </>
          )
          || (
            <>
              <RightAssets open={selectedRightMenu === 'assets'}>
                <WichiTabs
                  style={{
                    width: 'calc(100% + 48px)',
                    marginLeft: '-24px',
                    background: '#303030 ',
                  }}
                  padding={32}
                  value={selectedAssetTab}
                  onChange={(event, newValue) => setSelectedAssetTab(newValue)}
                >
                  <WichiTab style={{ color: '#fff' }} value="recent" label={<AccessTime />} />
                  {
                    userAccesses.asset === 'all'
                    && (
                      [
                        <WichiTab style={{ color: '#fff' }} value="group" label={<Layers />} />,
                        <WichiTab style={{ color: '#fff' }} value="filter" label={<FilterList />} />,
                      ]
                    )
                  }
                  <WichiTab style={{ color: '#fff' }} value="option" label={<Settings />} />
                </WichiTabs>
                {
                  getWhichisLoading
                  && (
                  <div style={{
                    width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 144,
                  }}
                  >
                    <CircularProgress color="secondary" />
                  </div>
                  )
                  || selectedAssetTab === 'recent'
                  && (
                  <AssetList adjustment={selectedAsset ? -550 : 0}>
                    {
                      mapAssets()
                    }
                  </AssetList>
                  )
                  || selectedAssetTab === 'group'
                  && (
                    <>
                      <AssetList adjustment={selectedAsset ? -550 : 0}>
                        <AssetGroupIndicator>
                          Assets &gt; Group &gt;
                          <SquareSelect
                            value={selectedAssetGroup}
                            onChange={event => setSelectedAssetGroup(event.target.value)}
                          >
                            {
                              Object.keys(groupedAssets).map(groupKey => (<MenuItem value={groupKey}>{groupKey}</MenuItem>))
                            }
                          </SquareSelect>
                        </AssetGroupIndicator>
                        {
                          mapGroupAssets()
                        }
                      </AssetList>
                    </>
                  )
                  || selectedAssetTab === 'filter'
                  && (
                    <>
                      <RightAssetFilter>
                        <div>
                          <div>
                            <span>Type</span>
                            <SquareSelect value={assetFilters.type || ''} onChange={event => handleAssetFilterChange(event.target.value, 'type')} displayEmpty>
                              <MenuItem value="">ALL</MenuItem>
                              {
                                assetInfoTypes.map(type => (
                                  <MenuItem value={type}>{type}</MenuItem>
                                ))
                              }
                            </SquareSelect>
                          </div>
                          <div>
                            <span>Usage</span>
                            <SquareSelect value={assetFilters.usage || ''} onChange={event => handleAssetFilterChange(event.target.value, 'usage')} displayEmpty>
                              <MenuItem value="">ALL</MenuItem>
                              {
                                (assetFilters.type
                                && assetInfoUsage[assetFilters.type]
                                || allAssetInfoUsage).map(usage => (
                                  <MenuItem value={usage}>{usage}</MenuItem>
                                ))
                              }
                            </SquareSelect>
                          </div>
                        </div>
                        <div>
                          <div>
                            <span>Status</span>
                            <SquareSelect value={assetFilters.status || ''} onChange={event => handleAssetFilterChange(event.target.value, 'status')} displayEmpty>
                              <MenuItem value="">ALL</MenuItem>
                              {
                                assetInfoStatus.map(status => (
                                  <MenuItem value={status}>{capitalize(status)}</MenuItem>
                                ))
                              }
                            </SquareSelect>
                          </div>
                          <div>
                            <span>Group</span>
                            <SquareSelect value={assetFilters.group || ''} onChange={event => handleAssetFilterChange(event.target.value, 'group')} displayEmpty>
                              <MenuItem value="">ALL</MenuItem>
                              {
                                Object.keys(groupedAssets).map(groupKey => (
                                  <MenuItem value={groupKey}>{groupKey}</MenuItem>
                                ))
                              }
                            </SquareSelect>
                          </div>
                        </div>
                        <div>
                          <div>
                            { assetFilters.startDate ? assetFilters.startDate.format('YYYY/MM/DD') : 'Start Date'}
                            <KeyboardDatePicker
                              value={assetFilters.startDate}
                              onChange={date => handleAssetFilterChange(date, 'startDate')}
                              format="YYYY/MM/DD"
                            />
                          </div>
                          <div>
                            { assetFilters.endDate ? assetFilters.endDate.format('YYYY/MM/DD') : 'End Date'}
                            <KeyboardDatePicker
                              color="secondary"
                              value={assetFilters.endDate}
                              onChange={date => handleAssetFilterChange(date, 'endDate')}
                              format="YYYY/MM/DD"
                            />
                          </div>
                        </div>
                        <div>
                          <WichiPrimaryButton onClick={applyAssetFilter} variant="contained">
                            Apply
                          </WichiPrimaryButton>
                          <WichiPrimaryButton onClick={resetAssetFilter} variant="contained">
                            Refresh
                          </WichiPrimaryButton>
                        </div>
                      </RightAssetFilter>
                      <AssetList adjustment={-160 + (selectedAsset ? -550 : 0)}>
                        {
                          mapFilteredAssets()
                        }
                      </AssetList>
                    </>
                  ) || selectedAssetTab === 'option'
                  && (
                    <>
                      <AssetExpansionPanel>
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMore />}
                        >
                          <Typography style={{ display: 'flex' }} color="textSecondary"><MapOutlined style={{ marginRight: 12 }} /> Map Settings</Typography>
                        </ExpansionPanelSummary>
                        <AssetExpansionPanelDetails>
                          <div>
                            Map Style
                            <SquareSelect
                              value={mapOption.mapType}
                              onChange={event => setMapOption('mapType', event.target.value)}
                              style={{ width: 240 }}
                            >
                              <MenuItem value="roadmap">Road Map</MenuItem>
                              <MenuItem value="satellite">Satellite</MenuItem>
                              <MenuItem value="hybrid">Hybrid</MenuItem>
                              <MenuItem value="terrain">Terrain</MenuItem>
                            </SquareSelect>
                          </div>
                          <div>
                            Brightness
                            <Slider
                              defaultValue={100}
                              step={10}
                              min={10}
                              max={100}
                              value={mapOption.opacity}
                              onChange={(event, value) => setMapOption('opacity', value)}
                              style={{ width: 240 }}
                            />
                          </div>
                        </AssetExpansionPanelDetails>
                      </AssetExpansionPanel>
                      <AssetExpansionPanel>
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMore />}
                        >
                          <Typography style={{ display: 'flex' }} color="textSecondary"><DirectionsCar style={{ marginRight: 12 }} /> Asset Settings</Typography>
                        </ExpansionPanelSummary>
                        <AssetExpansionPanelDetails>
                          <div>
                            Default (Ungroup) Asset Color
                            <p onClick={() => setOnColorPick(true)} style={{ cursor: 'pointer' }}><GroupIcon color={mapOption.defaultColor} /><Edit /></p>
                          </div>
                          <div>
                            Asset Icon Size
                            <Slider
                              defaultValue={20}
                              step={5}
                              min={10}
                              max={30}
                              value={mapOption.assetSize}
                              onChange={(event, value) => setMapOption('assetSize', value)}
                              style={{ width: 240 }}
                            />
                          </div>
                          <div>
                            Track Icon Size
                            <Slider
                              defaultValue={20}
                              step={5}
                              min={10}
                              max={30}
                              value={mapOption.trackSize}
                              onChange={(event, value) => setMapOption('trackSize', value)}
                              style={{ width: 240 }}
                            />
                          </div>
                          <div>
                            Show Label
                            <Switch checked={mapOption.showLabel} onChange={() => setMapOption('showLabel', !mapOption.showLabel)} />
                          </div>
                          <div>
                            Maximum number of tracks
                            <SquareSelect
                              style={{ width: 240 }}
                              value={mapOption.maxTrack}
                              onChange={event => setMapOption('maxTrack', event.target.value)}
                            >
                              <MenuItem value={100}>100</MenuItem>
                              <MenuItem value={250}>250</MenuItem>
                              <MenuItem value={500}>500</MenuItem>
                              <MenuItem value={1000}>1000</MenuItem>
                              <MenuItem value={2500}>2500</MenuItem>
                            </SquareSelect>
                          </div>
                        </AssetExpansionPanelDetails>
                      </AssetExpansionPanel>
                      <AssetExpansionPanel>
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMore />}
                        >
                          <Typography style={{ display: 'flex' }} color="textSecondary"><SquareFoot style={{ marginRight: 12 }} /> Unit Settings</Typography>
                        </ExpansionPanelSummary>
                        <AssetExpansionPanelDetails>
                          <div>
                            Speed
                            <SquareSelect
                              style={{ width: 240 }}
                              value={mapOption.speedUnit}
                              onChange={event => setMapOption('speedUnit', event.target.value)}
                            >
                              <MenuItem value="knot">knot</MenuItem>
                              <MenuItem value="km/h">km/h</MenuItem>
                            </SquareSelect>
                          </div>
                          <div>
                            Altitude
                            <SquareSelect
                              style={{ width: 240 }}
                              value={mapOption.altUnit}
                              onChange={event => setMapOption('altUnit', event.target.value)}
                            >
                              <MenuItem value="feet">feet</MenuItem>
                              <MenuItem value="meter">meter</MenuItem>
                            </SquareSelect>
                          </div>
                        </AssetExpansionPanelDetails>
                      </AssetExpansionPanel>
                    </>
                  )
                }
                {
                  selectedAsset && selectedAssetTab !== 'option'
                  && (
                    <RightAssetDetail>
                      <div>
                        <AssetSVG style={{ marginRight: 8 }} icon={selectedAsset.icon || selectedAsset.whichigroup && selectedAsset.whichigroup.icon} color={selectedAsset.whichigroup && selectedAsset.whichigroup.color} />
                        <Typography>
                          {
                            selectedAsset.name
                          }
                          {
                            userAccesses.asset !== 'no-access'
                            && (
                            <Link onClick={() => toggleRightMenu('assets')} to={`${url}/assets?selected=${selectedAsset.id}`}>
                              <Edit />
                              Edit
                            </Link>
                            )
                          }
                        </Typography>
                        <Close onClick={unSelectAsset} />
                      </div>
                      <AssetDetailInfo>
                        <img src={photoURL(selectedAsset && selectedAsset.profilepicture)} alt="" style={{ backgroundImage: `url(${NoProfile})`, backgroundSize: '100% 100%' }} />
                        <div>
                          <Typography>
                            <span>Group</span>
                            <GroupIcon color={selectedAsset.whichigroup && selectedAsset.whichigroup.color}>{selectedAsset.whichigroup && firstUpperLetter(selectedAsset.whichigroup.name)}</GroupIcon>
                            {
                              selectedAsset.whichigroup
                              && selectedAsset.whichigroup.name
                              || 'Ungroup'
                            }
                          </Typography>
                          <Typography>
                            <span>Pilot</span>
                            { selectedAsset.pilot || '-' }
                          </Typography>
                          <Typography>
                            <span>Type</span>
                            { selectedAsset.type || '-' }
                          </Typography>
                          <Typography>
                            <span>Usage</span>
                            { selectedAsset.usage || '-' }
                          </Typography>
                          <Typography>
                            <span>Status</span>
                            { capitalize(selectedAsset.status) || '-' }
                            {
                              selectedAsset.status === 'distress'
                              && (
                              <DistressOffButton onClick={() => turnDistressOff(selectedAsset.imei)} variant="contained">
                                Turn Off
                              </DistressOffButton>
                              )
                            }
                          </Typography>
                        </div>
                      </AssetDetailInfo>
                      <AssetDetailTrack>
                        <div>
                          <Button className={lastSelectedTrack === '6hour' && 'last-selected' || ''} onClick={() => autoSelectTrackDate(6, 'hour')} variant="contained">
                            6 Hour
                          </Button>
                          <Button className={lastSelectedTrack === '12hour' && 'last-selected' || ''} onClick={() => autoSelectTrackDate(12, 'hour')} variant="contained">
                            12 Hour
                          </Button>
                          <Button className={lastSelectedTrack === '1day' && 'last-selected' || ''} onClick={() => autoSelectTrackDate(1, 'day')} variant="contained">
                            1 Day
                          </Button>
                          <Button className={lastSelectedTrack === '3day' && 'last-selected' || ''} onClick={() => autoSelectTrackDate(3, 'day')} variant="contained">
                            3 Days
                          </Button>
                          <Button className={lastSelectedTrack === '1week' && 'last-selected' || ''} onClick={() => autoSelectTrackDate(1, 'week')} variant="contained">
                            1 Week
                          </Button>
                          <Button
                            className={lastSelectedTrack === 'all' && 'last-selected' || ''}
                            onClick={() => {
                              setTrackStartDate(null);
                              setTrackEndDate(null);
                              setLastSelectedTrack('all');
                            }}
                            variant="contained"
                          >
                            All
                          </Button>
                        </div>
                        <div>
                          <AssetTimeline>
                            { trackStartDate ? trackStartDate.format('YYYY/MM/DD') : 'Start Date'}
                            <KeyboardDatePicker

                              value={trackStartDate}
                              onChange={date => setTrackStartDate(date)}
                              format="YYYY/MM/DD"
                            />
                          </AssetTimeline>
                          <AssetTimeline>
                            { trackEndDate ? trackEndDate.format('YYYY/MM/DD') : 'End Date'}
                            <KeyboardDatePicker
                              color="secondary"
                              value={trackEndDate}
                              onChange={date => setTrackEndDate(date)}
                              format="YYYY/MM/DD"
                            />
                          </AssetTimeline>
                        </div>
                        <div style={{ display: 'flex', marginTop: 8 }}>
                          <WichiPrimaryButton style={{ margin: 0 }} width={userAccesses.asset !== 'no-access' ? '50%' : '100%'} onClick={showTrackFromSelecedAsset} variant="contained">
                            Show Track
                          </WichiPrimaryButton>
                          {
                            userAccesses.asset !== 'no-access'
                            && (
                              <WichiPrimaryButton
                                style={{ margin: 0, marginLeft: 8 }}
                                width="50%"
                                onClick={() => {
                                  getRecentSettings({
                                    variables: {
                                      imei: selectedAsset.imei,
                                    },
                                  });
                                  setOnSendPollRequest(true);
                                }}
                                variant="contained"
                              >
                                Send Poll Request
                              </WichiPrimaryButton>
                            )
                          }
                        </div>
                      </AssetDetailTrack>
                      <AssetDetailOthers>
                        <Typography>
                          <span>
                            Information
                          </span>
                          {
                            selectedAsset.information || '-'
                          }
                        </Typography>
                        <Typography>
                          <span>
                            IMEI
                          </span>
                          {
                            selectedAsset.imei || '-'
                          }
                        </Typography>
                        <Typography>
                          <span>
                            Serial Number
                          </span>
                          {
                            selectedAsset.serialNumber || '-'
                          }
                        </Typography>
                        <Typography>
                          <span>
                            Service Plan
                          </span>
                          {
                            selectedAsset.servicePlan || '-'
                          }
                        </Typography>
                      </AssetDetailOthers>
                    </RightAssetDetail>
                  )
                }
              </RightAssets>
              <RightAssets open={selectedRightMenu === 'message'}>
                <WichiTabs
                  padding={32}
                  style={{
                    width: 'calc(100% + 48px)',
                    marginLeft: '-24px',
                    background: '#303030 ',
                  }}
                  value={selectedMessageTab}
                  onChange={(event, newValue) => setSelectedMessageTab(newValue)}
                >
                  <WichiTab minWidth="33.33%" style={{ color: '#fff' }} value="recent" label="Recent" />
                </WichiTabs>
                <FilterWrapper padding={0}>
                  <Search style={{ color: '999999', marginRight: 12 }} /><InputBase value={messageSearchKeyword} onChange={event => setMessageSearchKeyword(event.target.value)} style={{ width: '100%' }} placeholder="Search" />
                </FilterWrapper>
                {
                  getWhichisLoading
                  && (
                  <div style={{
                    width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 144,
                  }}
                  >
                    <CircularProgress color="secondary" />
                  </div>
                  )
                  || selectedMessageTab === 'recent'
                  && (
                  <AssetList noBackground adjustment={selectedMessageView ? -400 : 0}>
                    {
                      mapMessages()
                    }
                  </AssetList>
                  )
                }
                {
                  selectedMessageView
                  && (
                  <MessageView>
                    <div>
                      <Typography>
                        {
                          selectedMessageView.name
                        }
                      </Typography>
                      <IconButton
                        onClick={() => setSelectedMessageView(null)}
                        style={{
                          position: 'absolute', padding: 0, right: 6, top: 6,
                        }}
                      >
                        <Close color="primary" />
                      </IconButton>
                    </div>
                    {
                      messagesLoading
                      && (
                      <div style={{
                        width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%',
                      }}
                      >
                        <CircularProgress color="secondary" />
                      </div>
                      )
                      || (
                        <>
                          <div ref={messageLogsRef}>
                            {
                              mapMessageLogs([...messagesData.GetMessages.messages])
                            }
                          </div>
                          <div>
                            <InputBase
                              inputRef={messageInputRef}
                              inputComponent="textarea"
                              value={messageInput}
                              onKeyDown={onEnter(sendMessage, () => { if (getByteLength(messageInput) > 269) return; setMessageInput(`${messageInput}\n`); setTimeout(() => { messageInputRef.current.style.height = ''; messageInputRef.current.style.height = `${messageInputRef.current.scrollHeight - 13}px`; window.messageInputRef = messageInputRef; }, 10); })}
                              onChange={(event) => {
                                if (event.keyCode === 13 && !event.ctrlKey) return;
                                if (getByteLength(event.target.value) > 269) return;
                                setMessageInput(event.target.value); messageInputRef.current.style.height = ''; messageInputRef.current.style.height = `${messageInputRef.current.scrollHeight - 13}px`; window.messageInputRef = messageInputRef;
                              }}
                              style={{ width: '100%' }}
                              placeholder="Text to send..."
                            />
                            {
                              sendMessageLoading
                              && <CircularProgress color="primary" />
                              || <Send onClick={sendMessage} />
                            }
                          </div>
                        </>
                      )
                      }
                  </MessageView>
                  )
                }
              </RightAssets>
              <RightAssets open={selectedRightMenu === 'report'}>
                <WichiTabs
                  style={{
                    width: 'calc(100% + 48px)',
                    marginLeft: '-24px',
                    background: '#303030 ',
                  }}
                  padding={32}
                  value={selectedReportTab}
                  onChange={(event, newValue) => setSelectedReportTab(newValue)}
                >
                  <WichiTab minWidth="33.33%" style={{ color: '#fff' }} value="recent" label={<AccessTime />} />
                  {
                    userAccesses.asset === 'all'
                    && (
                      [
                        <WichiTab style={{ color: '#fff' }} value="group" label={<Layers />} />,
                        <WichiTab style={{ color: '#fff' }} value="filter" label={<FilterList />} />,
                      ]
                    )
                  }
                </WichiTabs>
                {
                  getWhichisLoading
                  && (
                  <div style={{
                    width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 144,
                  }}
                  >
                    <CircularProgress color="secondary" />
                  </div>
                  )
                  || selectedReportTab === 'recent'
                  && (
                  <AssetList adjustment={selectedReportAsset ? -550 : 0}>
                    {
                      mapReportAssets()
                    }
                  </AssetList>
                  )
                  || selectedReportTab === 'group'
                  && (
                    <>
                      <AssetList adjustment={selectedReportAsset ? -550 : 0}>
                        <AssetGroupIndicator>
                          Assets &gt; Group &gt;
                          <SquareSelect
                            value={selectedReportGroup}
                            onChange={event => setSelectedReportGroup(event.target.value)}
                          >
                            {
                              Object.keys(groupedAssets).map(groupKey => (<MenuItem value={groupKey}>{groupKey}</MenuItem>))
                            }
                          </SquareSelect>
                        </AssetGroupIndicator>
                        {
                          mapGroupReportAssets()
                        }
                      </AssetList>
                    </>
                  )
                  || selectedReportTab === 'filter'
                  && (
                    <>
                      <RightAssetFilter>
                        <div>
                          <div>
                            <span>Type</span>
                            <SquareSelect value={reportFilters.type || ''} onChange={event => handleReportFilterChange(event.target.value, 'type')} displayEmpty>
                              <MenuItem value="">ALL</MenuItem>
                              {
                                assetInfoTypes.map(type => (
                                  <MenuItem value={type}>{type}</MenuItem>
                                ))
                              }
                            </SquareSelect>
                          </div>
                          <div>
                            <span>Usage</span>
                            <SquareSelect value={reportFilters.usage || ''} onChange={event => handleReportFilterChange(event.target.value, 'usage')} displayEmpty>
                              <MenuItem value="">ALL</MenuItem>
                              {
                                (reportFilters.type
                                && assetInfoUsage[reportFilters.type]
                                || allAssetInfoUsage).map(usage => (
                                  <MenuItem value={usage}>{usage}</MenuItem>
                                ))
                              }
                            </SquareSelect>
                          </div>
                        </div>
                        <div>
                          <div>
                            <span>Status</span>
                            <SquareSelect value={reportFilters.status || ''} onChange={event => handleReportFilterChange(event.target.value, 'status')} displayEmpty>
                              <MenuItem value="">ALL</MenuItem>
                              {
                                assetInfoStatus.map(status => (
                                  <MenuItem value={status}>{capitalize(status)}</MenuItem>
                                ))
                              }
                            </SquareSelect>
                          </div>
                          <div>
                            <span>Group</span>
                            <SquareSelect value={reportFilters.group || ''} onChange={event => handleReportFilterChange(event.target.value, 'group')} displayEmpty>
                              <MenuItem value="">ALL</MenuItem>
                              {
                                Object.keys(groupedAssets).map(groupKey => (
                                  <MenuItem value={groupKey}>{groupKey}</MenuItem>
                                ))
                              }
                            </SquareSelect>
                          </div>
                        </div>
                        <div>
                          <div>
                            { reportFilters.startDate ? reportFilters.startDate.format('YYYY/MM/DD') : 'Start Date'}
                            <KeyboardDatePicker
                              value={reportFilters.startDate}
                              onChange={date => handleReportFilterChange(date, 'startDate')}
                              format="YYYY/MM/DD"
                            />
                          </div>
                          <div>
                            { reportFilters.endDate ? reportFilters.endDate.format('YYYY/MM/DD') : 'End Date'}
                            <KeyboardDatePicker
                              color="secondary"
                              value={reportFilters.endDate}
                              onChange={date => handleReportFilterChange(date, 'endDate')}
                              format="YYYY/MM/DD"
                            />
                          </div>
                        </div>
                        <div>
                          <WichiPrimaryButton onClick={applyReportFilter} variant="contained">
                            Apply
                          </WichiPrimaryButton>
                          <WichiPrimaryButton onClick={resetReportFilter} variant="contained">
                            Refresh
                          </WichiPrimaryButton>
                        </div>
                      </RightAssetFilter>
                      <AssetList adjustment={-160 + (selectedReportAsset ? -550 : 0)}>
                        {
                          mapFilteredReportAssets()
                        }
                      </AssetList>
                    </>
                  )
                }
                {
                  selectedReportAsset
                  && (
                  <ReportInner style={{ width: 'calc(100% + 48px)', marginLeft: -24, height: 550 }}>
                    <div>
                      <WichiTabs padding={32} value={selectedReportInnerTab} onChange={(event, newValue) => setSelectedReportInnerTab(newValue)}>
                        <WichiTab minWidth="33.33%" style={{ color: '#fff' }} value="event" label="Event" />
                        <WichiTab minWidth="33.33%" style={{ color: '#fff' }} value="average" label="Average" />
                        <WichiTab minWidth="33.33%" style={{ color: '#fff' }} value="position" label="Position" />
                      </WichiTabs>
                    </div>
                    <ReportAssetHeader>
                      <Typography>
                        <span>
                          <GroupIcon
                            group={selectedReportAsset.whichigroup}
                          >
                            {selectedReportAsset.whichigroup && firstUpperLetter(selectedReportAsset.whichigroup.name)}
                          </GroupIcon>
                          {
                            selectedReportAsset.whichigroup
                            && selectedReportAsset.whichigroup.name
                            || 'Ungroup'
                          }
                          <KeyboardArrowRight />
                        </span>
                        <span style={{
                          overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%', display: 'inline-block',
                        }}
                        >
                          {
                            selectedReportAsset.name
                          }
                        </span>
                        {
                          selectedReportInnerTab === 'event'
                          && <WichiPrimaryButton href={`${API_HOST}/event_download?whichiid=${selectedReportAsset && selectedReportAsset.id}&from=${selectedReportStartDate && selectedReportStartDate.toISOString()}&to=${selectedReportEndDate && selectedReportEndDate.toISOString()}`} style={{ flexShrink: 0 }} width="160px">Export Excel File</WichiPrimaryButton>
                          || selectedReportInnerTab === 'average' && <WichiPrimaryButton href={`${API_HOST}/distance_download?whichiid=${selectedReportAsset && selectedReportAsset.id}&from=${selectedReportStartDate && selectedReportStartDate.toISOString()}&to=${selectedReportEndDate && selectedReportEndDate.toISOString()}`} style={{ flexShrink: 0 }} width="160px">Export Excel File</WichiPrimaryButton>
                          || selectedReportInnerTab === 'position' && <WichiPrimaryButton href={`${API_HOST}/position_download?whichiid=${selectedReportAsset && selectedReportAsset.id}&from=${selectedReportStartDate && selectedReportStartDate.toISOString()}&to=${selectedReportEndDate && selectedReportEndDate.toISOString()}`} style={{ flexShrink: 0 }} width="160px">Export Excel File</WichiPrimaryButton>
                        }
                      </Typography>
                    </ReportAssetHeader>
                    <ReportAssetFilters>
                      <ReportAssetTimeline>
                        <DatePicker
                          label="Start Date"
                          value={selectedReportStartDate}
                          onChange={date => setReportStartDate(date)}
                          format="YYYY/MM/DD"
                          placeholder="Start Date"
                        />
                      </ReportAssetTimeline>
                      <ReportAssetTimeline>
                        <DatePicker
                          label="End Date"
                          color="secondary"
                          value={selectedReportEndDate}
                          onChange={date => setReportEndDate(moment(date.format('YYYY/MM/DD 23:59:59')))}
                          format="YYYY/MM/DD"
                          placeholder="End Date"
                        />
                      </ReportAssetTimeline>
                      <GrowPadder />
                      <WichiPrimaryButton onClick={refetchReportInfo} width="160px">Applys</WichiPrimaryButton>
                    </ReportAssetFilters>
                    <ReportListHeader>
                      {
                        selectedReportInnerTab === 'event'
                        && (
                        <EventReportLayout simple style={{ height: '100%' }}>
                          <Typography color="textSecondary">
                            Date
                          </Typography>
                          <Typography color="textSecondary">
                            Status
                          </Typography>
                          <Typography color="textSecondary">
                            Detail
                          </Typography>
                        </EventReportLayout>
                        )
                        || selectedReportInnerTab === 'average'
                        && (
                        <AverageReportLayout style={{ height: '100%' }} mini>
                          <Typography color="textSecondary">
                            Date
                          </Typography>
                          <Typography color="textSecondary">
                            Distance Moved (km)
                          </Typography>
                          <Typography color="textSecondary">
                            Average Speed ({mapOption.speedUnit})
                          </Typography>
                        </AverageReportLayout>
                        )
                        || selectedReportInnerTab === 'position'
                        && (
                        <PositionReportLayout mini style={{ paddingRight: 20, height: '100%' }}>
                          <Typography variant="body2" color="textSecondary">
                            Date
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Latitude
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Longitude
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Altitude
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Speed
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Course
                          </Typography>
                        </PositionReportLayout>
                        )
                      }
                    </ReportListHeader>
                    <ReportList>
                      {
                        mapReports()
                      }
                    </ReportList>
                  </ReportInner>
                  )
                }
              </RightAssets>
            </>
          )
        )
      }
      <Route exact path={`${path}/`}>
        {
          !getWhichisLoading && isInitialized
          && (
            <Map
              isRightAssetEnabled={isRightAssetEnabled}
              onClickMarker={(asset) => {
                setSelectedRightMenu('assets');
                loadAsset(asset);
              }}
              pingColor={pingColor}
              isMobile={isMobile}
              open={open}
              center={center}
              zoom={zoom}
              setMapPosition={setMapPosition}
              assetPings={assetPings}
              mapOption={mapOption}
              assetMarkers={(userAccesses.asset === 'all' || userAccesses.map === 'all') ? assetMarkers : []}
            />
          )
        }
      </Route>
      <Route path={`${path}/assets`}>
        <AssetPage mapOption={mapOption} userAccess={userAccesses.asset} configAccess={userAccesses.asset_config} isRightAssetEnabled={isRightAssetEnabled} profileData={profileData && profileData.GetMyProfile.user} isMobile={isMobile} open={open} assets={getWhichisData && getWhichisData.GetUsersWhichis.whichis || null} loading={getWhichisLoading} />
      </Route>
      <Route path={`${path}/messages`}>
        <MessagePage mapOption={mapOption} isRightAssetEnabled={isRightAssetEnabled} profileData={profileData && profileData.GetMyProfile.user} isMobile={isMobile} open={open} assets={getWhichisData && getWhichisData.GetUsersWhichis.whichis || null} loading={getWhichisLoading} />
      </Route>
      <Route path={`${path}/report`}>
        <ReportPage mapOption={mapOption} isRightAssetEnabled={isRightAssetEnabled} profileData={profileData && profileData.GetMyProfile.user} isMobile={isMobile} open={open} assets={getWhichisData && getWhichisData.GetUsersWhichis.whichis || null} loading={getWhichisLoading} />
      </Route>
      <Route path={`${path}/setting`}>
        <SettingPage isRightAssetEnabled={isRightAssetEnabled} isMobile={isMobile} profileData={profileData && profileData.GetMyProfile.user} open={open} assets={getWhichisData && getWhichisData.GetUsersWhichis.whichis || null} loading={getWhichisLoading} />
      </Route>
      <Route path={`${path}/profile`}>
        <ProfilePage isRightAssetEnabled={isRightAssetEnabled} isMobile={isMobile} profileData={profileData && profileData.GetMyProfile.user} open={open} assets={getWhichisData && getWhichisData.GetUsersWhichis.whichis || null} loading={getWhichisLoading} />
      </Route>
      <Route path={`${path}/administrator`}>
        <AdminPage isRightAssetEnabled={isRightAssetEnabled} isMobile={isMobile} profileData={profileData && profileData.GetMyProfile.user} open={open} assets={getWhichisData && getWhichisData.GetUsersWhichis.whichis || null} loading={getWhichisLoading} />
      </Route>
      {
        isMobile
        && <div style={{ height: 64 }} />
      }
      <Dialog open={onSendPollRequest} onClose={pollRequestLoading ? () => {} : () => setOnSendPollRequest(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Send Poll Request</DialogTitle>
        <DialogContent>
          <SpanHighlighted>
            Sending poll request to the asset <span>{ selectedAsset && selectedAsset.name}</span><br />
            {
              lastSentPollRequest
              && (
                <>
                  Poll request last sent to this asset at:&nbsp;{humanizeDate(lastSentPollRequest.toISOString())}
                  <br />
                </>
              )
            }
            <br />
            Please select the report port number.<br />
          </SpanHighlighted>
          {
            recentSettingsLoading
            && (
              <CircularProgress color="secondary" size={12} style={{ marginTop: 8 }} />
            )
            || (
              <Select
                value={selectedPollRequestReportNum}
                onChange={event => setPollRequestReportNum(event.target.value)}
              >
                <MenuItem disabled={!pollReportStatus.report1} value="31">Report 1</MenuItem>
                <MenuItem disabled={!pollReportStatus.report2} value="32">Report 2</MenuItem>
                <MenuItem disabled={!pollReportStatus.report3} value="33">Report 3</MenuItem>
                <MenuItem disabled={!pollReportStatus.report4} value="34">Report 4</MenuItem>
              </Select>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button disabled={pollRequestLoading} onClick={pollRequestLoading ? () => {} : () => setOnSendPollRequest(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={pollRequestLoading ? () => {} : sendPollRequest} color="primary">
            {
              pollRequestLoading
              && <CircularProgress size={24} />
              || 'Apply'
            }
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={onColorPick}
        onClose={() => setOnColorPick(false)}
      >
        <DialogTitle>Pick Color</DialogTitle>
        <StyledColorPicker
          color={mapOption.defaultColor || '#7F7F7F'}
          onChange={(color) => {
            if (color) {
              setMapOption('defaultColor', color.hex);
              setOnColorPick(false);
              alert('Color config will be applied after you refresh the page.');
            }
          }}
        />
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={snackbarMessage}
        autoHideDuration={3000}
        onClose={() => setSnackbarMessage(null)}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{snackbarMessage}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            className={classes.close}
            onClick={() => setSnackbarMessage(null)}
          >
            <Close />
          </IconButton>,
        ]}
      />
    </div>
  );
}
