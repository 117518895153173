import styled from 'styled-components';
import {
  Button, Typography, ExpansionPanel, ExpansionPanelDetails,
} from '@material-ui/core';
import { CirclePicker } from 'react-color';
import React, { useEffect, useState } from 'react';
import { WICHI_MAPOPTION_KEY } from '../constants';

export const StyledColorPicker = styled(CirclePicker)`
  margin: 24px;
  margin-right: 10px !important;
  margin-bottom: 10px !important;
`;


export const AdminOuterExpansionPanel = styled(ExpansionPanel)`
  background-color: transparent;
  box-shadow: none;

  > div {
    > div:first-child {
      margin-left: 32px;
    }
    > div + div {
      color: white;
      color: white;
      margin-right: 0;
      position: absolute;
      left: 12px;
    }
  }
`;

export const AssetExpansionPanel = styled(ExpansionPanel)`
  background-color: #555;
  box-shadow: none;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  ${
  props => !props.isMobile && `
  margin-left: -24px !important;
  width: calc(100% + 48px);
  `
}
  border-radius: 0 !important;

  > div:first-child {
    min-height: 48px !important;
    border-bottom: 1px solid white;

    > div:first-child {
      height: 48px;
    }
  }

  > div {
    > div:first-child {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    > div + div {
      color: white;
    }
  }
`;

export const AssetExpansionPanelDetails = styled(ExpansionPanelDetails)`
  background-color: #777;
  padding: 0;
  display: flex;
  flex-direction: column;

  > div {
    width: 100%;
    height: 48px;
    display: flex;
    padding: 0px 24px;
    align-items: center;
    justify-content: space-between;
    color: white;
    border-bottom: 1px solid white;

    span {
      color: white !important;
    }
  }
`;

export const AdminInnerExpansionPanel = styled(ExpansionPanel)`
  background-color: transparent;
  box-shadow: none;
  width: 100%;

  > div {
    > div:first-child {
      margin-left: 8px;
    }
    > div + div {
      color: white;
      color: white;
      margin-right: 0;
      position: absolute;
      left: -8px;
    }
  }
`;

export const MessageView = styled.div`
height: 402px;
background: white;
width: 100%;
position: absolute;
bottom: 0;
left: 0;
display: flex;
flex-direction: column;

> div:first-child {
  height: 36px;
  padding: 0px 16px;
  width: 100%;
  border-bottom: 1px solid #999;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

> div:nth-child(2) {
  height: 328px;
  padding: 16px;
  overflow: auto;
  flex-shrink: 1;
  background: white;
  ::-webkit-scrollbar {
    width: 16px;
  }
  ::-webkit-scrollbar-track {
    background: white;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.4);
    width: 16px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 8px;
  }
}

> div:nth-child(3) {
  flex-shrink: 0;
  min-height: 36px;
  display: flex;
  align-items: center;
  padding: 0px 12px 0px 12px;
  background: #ddd;
}
`;

export const MessageLog = styled.div`
position: relative;
width: 100%;
display: flex;
justify-content: flex-start;
margin-bottom: 8px;

${props => props.isOptimistic && 'opacity: 0.6;'}

> img, > svg {
  height: 40px;
  width: 32px;
  margin-right: 8px;
}

> div {
  width: calc(100% - 40px);
  position: relative;
  display: inline-block;
  background: ${props => props.received && '#CCC' || '#DCEAFD'};
  box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.4);

  min-height: 40px;
  border-radius: 4px;
  display: flex;
  padding: 10px;
  &:after {
    content: '';
    position: absolute;
    top: 20px;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    margin-top: -8px;
    ${props => !props.received && `
      right: 0;
      border-left-color: #DCEAFD;
      border-right: 0;
      margin-right: -8px;
    `
    || `
      left: 0;
      border-right-color: #CCC;
      border-left: 0;
      margin-left: -8px;
    `}
  }
  margin-bottom: 16px;

  > p + p {
    position: absolute;
    right: 0;
    bottom: -20px;
    font-size: 0.8rem;
    font-weight: bold;
    color: #999;
  }
}
`;

const GroupIconOrigin = styled.div`
 width: 24px;
 height: 24px;
 margin-right: 8px;
 color: white;
 font-weight: bold;
 background: ${props => props.defaultColor || '#7F7F7F'};
 background: ${props => (props.group ? props.group.color : (props.background || props.color))};
 border-radius: 4px;
 display: inline-flex;
 justify-content: center;
 align-items: center;
`;

export const GroupIcon = (originProps) => {
  const [defaultColor, setDefaultColor] = useState(window.defaultColorCache || null);

  useEffect(() => {
    if (defaultColor) return;

    try {
      const savedMapOption = JSON.parse(localStorage.getItem(WICHI_MAPOPTION_KEY));

      setDefaultColor(savedMapOption.defaultColor);
    } catch (err) {
      // console.log(err);
    }
  }, []);

  return <GroupIconOrigin {...originProps} defaultColor={defaultColor} />;
};

export const ErrorWrapper = styled.div`
  width: 100%;
  height: 144px;
  background: transparent !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.palette.grey.main};

  > svg {
    width: 32px;
    height: 32px;
  }

  > h6 + p {
    color: ${props => props.theme.palette.text.secondary};
  }
`;

export const AssetListItem = styled(Button)`
width: 100%;
color: white;
display: flex;
height: 36px;
padding-left: 36px;
cursor: pointer;

> span {
  text-transform: none;
  font-size: 1rem;
  font-weight: normal;
  text-align: left;
  display: inherit;
  align-items: flex-start;
  justify-content: flex-start;
}

${props => props.selected && `
  color: ${props.theme.palette.secondary.main};
`}
`;

export const SpanHighlighted = styled(Typography)`
  > span {
    font-weight: bold;
    color: ${props => props.color || props.theme.palette.secondary.main};
  }
`;

export const Blinder = styled.div`
  left: -300vw;
  top: -300vh;
  position: fixed;
  width: 600vw;
  height: 600vh;
  background: black;
  opacity: ${props => (100 - props.opacity) / 100};
`;
