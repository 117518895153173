import gql from 'graphql-tag';

export const GET_USER_LIST = gql`
  {
    GetUsers {
      ok
      error
      users {
        id
        userid
        firstName
        lastName
        nickname
        role
        issuerUser {
          id
          firstName
          lastName
          nickname
        }
        parentUser {
          id
          nickname
        }
        createdAt
        updatedAt
        email
        phoneNumber
        registrant
        profilePhoto
        fax
        address
        postalCode
        companyCode
        activate
        uwpipe {
          id
          name
          imei
          serialNumber
          type
          usage
          servicePlan
        }
        usersAsUser {
          id
          userid
          firstName
          lastName
          nickname
          role
          issuerUser {
            id
            firstName
            lastName
            nickname
          }
          createdAt
          updatedAt
          email
          phoneNumber
          registrant
          profilePhoto
          fax
          address
          postalCode
          companyCode
          activate
          uwpipe {
            id
            name
            imei
            serialNumber
            type
            usage
            servicePlan
          }
          usersAsUser {
            id
            userid
            firstName
            lastName
            nickname
            role
            issuerUser {
              id
              firstName
              lastName
              nickname
            }
            uwpipe {
              id
              name
              imei
              serialNumber
              type
              usage
              servicePlan
            }
            createdAt
            updatedAt
            email
            phoneNumber
            registrant
            profilePhoto
            fax
            address
            postalCode
            companyCode
            activate
          }
        }
      }
    }
  }
`;

export const GET_CHILD_USER_LIST = gql`
  query GetChildUsers($userid: Int!) {
    GetChildUsers (
      userid: $userid
    ) {
      ok
      error
      users {
        id
        userid
        firstName
        lastName
        nickname
        role
        issuerUser {
          id
          firstName
          lastName
          nickname
        }
        createdAt
        updatedAt
        email
        phoneNumber
        registrant
        profilePhoto
        fax
        address
        postalCode
        companyCode
        activate
      }
    }
  }
`;

export const ADD_USER = gql`
  mutation SignUp(
    $userid: String!
    $role: String!
    $firstName: String!
    $lastName: String!
    $nickname: String!
    $email: String!
    $password: String!
    $phoneNumber: String!
    $assetParentId: Int
    $customerParentId: Int
    $companyCode: String
    $registrant: String
    $fax: String
    $address: String
    $postalCode: String
  ) {
    SignUp(
      userid: $userid
      role: $role
      firstName: $firstName
      nickname: $nickname
      lastName: $lastName
      email: $email
      password: $password
      phoneNumber: $phoneNumber
      assetParentId: $assetParentId
      customerParentId: $customerParentId
      companyCode: $companyCode
      registrant: $registrant
      fax: $fax
      address: $address
      postalCode: $postalCode
    ){
      ok
      error
      newUser {
        id
      }
    }
  }
`;

export const ADD_WHICHI = gql`
  mutation AddWhichi(
    $name: String!
    $imei: String!
    $type: String
    $usage: String
    $serialNumber: String
    $servicePlan: String
  ) {
    AddWhichi (
      name: $name
      imei: $imei
      type: $type
      usage: $usage
      serialNumber: $serialNumber
      servicePlan: $servicePlan
    ) {
      ok
      error 
      newWhichi {
        id
        imei
      }
    }
  }
`;

export const CONNECT_WHICHI_TO_USER = gql`
  mutation AddWhichiToUser (
    $id: Int!
    $imei: String!
  ) {
    AddWhichiToUser(
      id: $id
      imei: $imei
    ) {
      ok
      error
    }
  }
`;

export const EDIT_USER = gql`
  mutation EditUser(
    $userId: Int!
    $firstName: String
    $lastName: String
    $phoneNumber: String
    $email: String
    $nickname: String
    $profilePhoto: String
    $registrant: String
    $age: Int
    $companyCode: String
    $fax: String
    $address: String
    $postalCode: String
    $activate: Boolean
  ) {
    UpdateProfileByAdmin(
      userId: $userId
      nickname: $nickname
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      email: $email
      profilePhoto: $profilePhoto
      age: $age
      companyCode: $companyCode
      registrant: $registrant
      fax: $fax
      address: $address
      postalCode: $postalCode
      activate: $activate
    )
    {
      ok
      error
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($userid: Int!) {
    DeleteUser(userid: $userid) {
      ok
      error
    }
  }
`;

export const CHECK_DUPLICATE = gql`
  query CHECK_DUPLICATE(
    $nickname: String
    $userid: String
  ) {
    CheckUserSameField(
      nickname: $nickname
      userid: $userid
    ) {
      ok
      error
      has
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation CHANGE_PASSWORD(
    $userid: Int!
    $newPassword: String!
    $confirmPassword: String!
  ) {
    ChangePasswordByAdmin(
      userid: $userid
      newPassword: $newPassword
      confirmPassword: $confirmPassword
    ) {
      ok
      error
    }
  }
`;

export const CHANGE_MY_PASSWORD = gql`
  mutation CHANGE_MY_PASSWORD(
    $userid: Int!
    $previousPassword: String!
    $newPassword: String!
    $confirmPassword: String!
  ) {
    ChangeMyPassword(
      userid: $userid
      previousPassword: $previousPassword
      newPassword: $newPassword
      confirmPassword: $confirmPassword
    ) {
      ok
      error
    }
  }
`;
