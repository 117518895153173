import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import {
  Typography, CircularProgress, Button, InputBase, useMediaQuery,
} from '@material-ui/core';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  ExpandMore, Pageview, Send, Search, CancelPresentation, Close, List, ZoomIn,
} from '@material-ui/icons';
import gql from 'graphql-tag';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { AssetSVG } from '../images/svgs';
import {
  humanizeDate, onEnter, debounce, firstUpperLetter, getFullName, getUTCTime, preventDefault, getByteLength,
} from '../libs/helpers';
import apolloClient from '../libs/apolloClient';
import { GET_WHICHI_MESSAGES, GET_WHICHIS, GET_WHOLETREE_WHICHIS } from '../graphql/whichi';
import { drawerWidth } from '../constants';
import {
  MessageView, MessageLog, GroupIcon, ErrorWrapper, AssetListItem, AdminInnerExpansionPanel, AdminOuterExpansionPanel,
} from '../components/views';
import { AssetComponent, KeepAliveGoogleMap } from '../components/map';
import { FilterWrapper } from '../components/input';
import { MobileAssetListSearch, MobileAssetList } from '../components/mobile';

const SEND_WHICHI_MESSAGE = gql`
  mutation SendWhichiMessage($imei: String!, $message_body: String!) {
    AddTxMessage(imei: $imei, message_body: $message_body)
    {
      ok
      error
    }
  }
`;

const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    padding: 0,
    paddingRight: 48,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    marginTop: 64,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const ExpansionPanel = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles({
  root: {
    padding: 0,
    paddingLeft: 12,
    flexDirection: 'column',
  },
})(MuiExpansionPanelDetails);

const Contents = styled.div`
  display: flex;
  height: calc(100vh - 64px);
`;

const ContentLeft = styled.div`
  width: 300px;
  background: #0E0F1A;
  height: 100%;
  border-left: 4px solid black;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 16px;
  }
  ::-webkit-scrollbar-track {
    background: #0E0F1A;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.4);
    width: 16px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 8px;
  }
`;

const ContentCenter = styled.div`
  width: 400px;
  background: #262730;
  height: 100%;
  padding: 0;
  overflow: auto;
  position: relative;
  ::-webkit-scrollbar {
    width: 16px;
  }
  ::-webkit-scrollbar-track {
    background: #0E0F1A;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.4);
    width: 16px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 8px;
  }
`;

const ContentRight = styled.div`
${props => !props.isMobile && `
  height: 100%
` || `
  height: calc(100% - 40px);
  margin-top: 40px;
`}
  flex-grow: 1;
  background: white;
  display: flex;
`;

const RightInnerLeft = styled.div`
  height: 100%;
  flex-grow: 2;
  ${props => !props.isMobile && `
    min-width: 480px;
  `}
  position: relative;
  > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    > div:nth-child(2) {
      flex-grow: 1;
    }
  }
`;

const RightInnerRight = styled.div`
  height: 100%;
  min-width: 280px;
  flex-grow: 3;
  background: #0E0F1A;

  >p:first-child {
    display: flex;
    padding: 8px 16px;
  }
`;

const OuterExpansionPanel = styled(ExpansionPanel)`
  background-color: transparent;
  box-shadow: none;

  > div {
    > div:first-child {
      margin-left: 32px;
    }
    > div + div {
      color: white;
      color: white;
      margin-right: 0;
      position: absolute;
      left: 12px;
    }
  }
`;

const InnerExpansionPanel = styled(ExpansionPanel)`
  background-color: transparent;
  box-shadow: none;
  width: 100%;

  > div {
    > div:first-child {
      margin-left: 32px;
    }
    > div + div {
      color: white;
      color: white;
      margin-right: 0;
      position: absolute;
      left: 12px;
    }
  }
`;

const MessageAssetListItem = styled.div`
  width: 100%;
  display: flex;
  padding: 16px 16px;
  align-items: center;
  border-bottom: 1px solid #999;
  cursor: pointer;
  > img {
    height: 40px;
    width: 32px;
    margin-right: 12px;
  }
  > div {
    flex-grow: 1;
    > p {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      align-items: center;
      position: relative;
      > span {
        font-size: 0.825rem;
        position: absolute;
        right: 0;
        font-weight: lighter;
      }
      > div {
        margin-right: 16px;
      }
    }
    > p + p {
      margin-top: 12px;
    }
  }

`;

const InformationBlock = styled(Typography)`
  color: white;
  font-weight: bold;
  font-size: 0.825rem;
  > span {
    font-weight: normal;
    display: inline-block;
    width: 100px;
  }
  padding: 12px;
  border-bottom: 1px solid #777;
`;

export default function MessagePage(props) {
  const {
    open, assets, loading, isRightAssetEnabled, profileData, mapOption,
  } = props;
  const classes = useStyles();
  const [selectedMessageView, setSelectedMessageView] = useState(null);
  const [messageInput, setMessageInput] = useState('');
  const [recentAssets, setRecentAssets] = useState(localStorage.getItem(`${profileData ? profileData.id : 'null'}whichiten-message-recent`) ? JSON.parse(localStorage.getItem(`${profileData ? profileData.id : 'null'}whichiten-message-recent`)) : []);
  const [messageSearchKeyword, _setMessageSearchKeyword] = useState('');
  const [appliedMessageSearchKeyword, setAppliedMessageSearchKeyword] = useState('');
  const [isOptimisticMessage, setIsOptimisticMessage] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(profileData ? profileData.id : null);
  const [isInitialized, setInitialized] = useState(false);
  const [showMobileAssetList, setShowMobileAssetList] = useState(false);
  const [assetSearchKeyword, setAssetSearchKeyword] = useState('');

  useEffect(() => {
    if (currentUserId !== (profileData ? profileData.id : null)) {
      setCurrentUserId(profileData ? profileData.id : null);
      setRecentAssets(localStorage.getItem(`${profileData ? profileData.id : 'null'}whichiten-message-recent`) ? JSON.parse(localStorage.getItem(`${profileData ? profileData.id : 'null'}whichiten-message-recent`)) : []);
    }
  });

  const messageLogsRef = useRef();
  const messageInputRef = useRef();

  const [getMessages, {
    loading: messagesLoading, data: messagesData, startPolling: startMessagePolling, stopPolling: stopMessagePolling,
  }] = useLazyQuery(GET_WHICHI_MESSAGES, {
    onCompleted: () => {
      startMessagePolling(5000);
      if (messageLogsRef.current) {
        messageLogsRef.current.scrollTop = messageLogsRef.current.scrollHeight;
      }
    },
  });
  const [
    emitSendMessage,
    {
      loading: sendMessageLoading,
    },
  ] = useMutation(SEND_WHICHI_MESSAGE);

  /* 지도 관련 */
  const [assetMarkers, setAssetMarkers] = useState([]);
  const [{ center, zoom }, setMapPosition] = useState({
    center: {
      lat: 37.532600,
      lng: 127.024612,
    },
    zoom: 14,
  });

  const createMapOptions = () => ({
    zoomControl: false,
    fullscreenControl: false,
  });

  const createAssetMarker = (place, name, whichi) => {
    clearTimeout(window.setMapTimeout);
    setMapPosition({
      center: {
        lat: place.lat,
        lng: place.lng,
      },
      zoom,
    });
    window.setMapTimeout = setTimeout(() => {
      setMapPosition({
        center: {
          lat: place.lat,
          lng: place.lng,
        },
        zoom,
      });
    }, 500);

    setAssetMarkers([
      { ...whichi, ...place },
    ]);
  };

  const setMessageSearchKeyword = (keyword) => {
    _setMessageSearchKeyword(keyword);
    debounce({ key: 'APPLY_MESSAGE_SEARCH', callback: () => setAppliedMessageSearchKeyword(keyword), timeout: 500 });
  };


  const getWhichiLocations = async (whichiId, name, whichi) => {
    const fetchedData = await apolloClient.query({
      query: gql`
        query getLocations($whichiId: Int!) {
          GetMyPlaces(whichiId: $whichiId) {
            ok
            error
            places {
              id
              utc
              lat
              lng
              alt
              speed
              course
              imei
            }
          }
        }
      `,
      variables: {
        whichiId,
      },

    });

    if (fetchedData.errors) {
      return window.alert('API Fetching error');
    }

    const { data: { GetMyPlaces: { places } } } = fetchedData;

    // console.log(places);

    if (places.length) {
      createAssetMarker({ ...places[places.length - 1] }, name, whichi);
    } else {
      window.alert('해당 에셋의 위치 정보가 없습니다.');
    }
  };

  /* 지도 관련 끝 */


  const groupedAssets = {};
  const groups = {};

  const addOrCreateToGroup = (asset, groupName, group) => {
    if (!groupedAssets[groupName]) {
      groupedAssets[groupName] = [];

      if (groupName !== 'Ungroup') {
        groups[groupName] = group;
      }
    }
    groupedAssets[groupName].push(asset);
  };

  if (!loading) {
    assets.map((asset) => {
      if (assetSearchKeyword) {
        if (!(asset.name && asset.name.includes(assetSearchKeyword))) {
          return;
        }
      }
      if (!asset.whichigroup) {
        addOrCreateToGroup(asset, 'Ungroup');
      } else {
        addOrCreateToGroup(asset, asset.whichigroup.name, asset.whichigroup);
      }
    });
  }

  const addRecent = (whichiId) => {
    const savedRecents = localStorage.getItem(`${profileData ? profileData.id : 'null'}whichiten-message-recent`);
    const currentRecent = savedRecents ? JSON.parse(savedRecents) : [];

    const newRecent = currentRecent.filter(recent => recent !== whichiId);
    newRecent.push(whichiId);
    localStorage.setItem(`${profileData ? profileData.id : 'null'}whichiten-message-recent`, JSON.stringify(newRecent.slice(-5)));
    setRecentAssets(newRecent.slice(-5));
  };

  const loadWhichiMessages = (whichiId, whichiName, imei, whichi) => {
    addRecent(whichiId);
    getWhichiLocations(whichiId, whichiName, whichi);
    getMessages({
      variables: {
        whichiIds: [whichiId],
      },
    });
    if (stopMessagePolling) {
      stopMessagePolling();
    }
    setSelectedMessageView({
      id: whichiId,
      name: whichiName,
      imei,
      ...whichi,
    });
  };

  const getRecentAssets = () => recentAssets.map(whichiId => assets.find(asset => asset.id === whichiId)).filter(asset => asset).reverse();

  const mapAssets = innerAssets =>
    // console.log(innerAssets);
    innerAssets.map(asset => <AssetListItem key={asset.id} selected={selectedMessageView && selectedMessageView.id === asset.id} onClick={() => loadWhichiMessages(asset.id, asset.name, asset.imei, asset)} variant="text">{asset.name}</AssetListItem>);
  const mapGroupedAssets = () => Object.keys(groupedAssets).sort((prev, next) => prev === 'Ungroup' && 1 || next === 'Ungroup' && -1 || 0).map((groupKey) => {
    const currentGroup = groups[groupKey] || {};
    if (!groupedAssets[groupKey] || !groupedAssets[groupKey].length) return;
    return (
      <InnerExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <GroupIcon style={{ color: 'white', fontWeight: 'bold' }} group={currentGroup}>{currentGroup && currentGroup.name ? currentGroup.name.slice(0, 1).toUpperCase() : ''}</GroupIcon>
          <Typography color="textSecondary" variant="body1">{groupKey}<GroupIcon background="white" style={{ color: 'black', marginLeft: 8, fontSize: '0.7rem' }}>+{groupedAssets[groupKey].length}</GroupIcon></Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {
              mapAssets(groupedAssets[groupKey])
          }
        </ExpansionPanelDetails>
      </InnerExpansionPanel>
    );
  });

  const mapMessageAssets = () => {
    const filteredAssets = assets.filter((whichi) => {
      if (appliedMessageSearchKeyword) {
        if (whichi.last_message && whichi.last_message.message_body) {
          if (whichi.last_message.message_body.includes(appliedMessageSearchKeyword) || whichi.name.includes(appliedMessageSearchKeyword) || whichi.imei.includes(appliedMessageSearchKeyword)) {
            return true;
          }
        }
        return false;
      }

      return whichi.last_message;
    });

    // console.log('mapMessage', filteredAssets, assets);

    if (!filteredAssets.length) {
      return (
        <ErrorWrapper>
          <Search />
          <Typography variant="h6">No results found.</Typography>
        </ErrorWrapper>
      );
    }

    return filteredAssets.map(asset => (
      <MessageAssetListItem onClick={() => loadWhichiMessages(asset.id, asset.name, asset.imei, asset)} selected={selectedMessageView && selectedMessageView.id === asset.id}>
        <AssetSVG style={{ marginRight: 8 }} imgOnly icon={asset.icon || asset.whichigroup && asset.whichigroup.icon} color={asset.whichigroup && asset.whichigroup.color} />
        <div>
          <Typography color="textSecondary">
            <GroupIcon group={asset.whichigroup}>
              {asset.whichigroup && firstUpperLetter(asset.whichigroup.name)}
            </GroupIcon>
            {asset.name}
            <span>{humanizeDate(new Date(asset.last_message.createdAt))}</span>
          </Typography>
          <Typography
            style={{
              maxWidth: 260, whiteSpace: 'nowrap', textOverflow: 'ellipsis', display: 'block', overflow: 'hidden',
            }}
            color="textSecondary"
            variant="body2"
          >{asset.last_message.message_body}
          </Typography>
        </div>
      </MessageAssetListItem>
    ));
  };

  const mapMessageLogs = messages => messages.map((message, idx) =>
    // console.log(message);
    (
      <MessageLog key={message.id} received={message.txrx === 'rx'} isOptimistic={isOptimisticMessage && idx === messages.length - 1}>
        {
          message.txrx === 'rx'
          && <AssetSVG style={{ width: 24, marginRight: 8 }} imgOnly icon={selectedMessageView.icon || selectedMessageView.whichigroup && selectedMessageView.whichigroup.icon} color={selectedMessageView.whichigroup && selectedMessageView.whichigroup.color} />
          || <svg style={{ marginRight: 32 }} />
        }
        <div style={message.txrx === 'rx' ? { marginRight: 32, width: 'auto', minWidth: 120 } : { width: 'auto', marginLeft: 'auto', minWidth: 120 }}>
          <Typography style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }} variant="body2">{message.message_body}</Typography>
          <Typography style={{ whiteSpace: 'nowrap' }}>{humanizeDate(message.createdAt)} | {message.payload_length || 'Unknown'} bytes</Typography>
        </div>
      </MessageLog>
    ));

  const sendMessage = () => {
    if (sendMessageLoading) return;
    if (messageLogsRef.current) {
      messageLogsRef.current.scrollTop = messageLogsRef.current.scrollHeight;
    }
    const { imei } = selectedMessageView;
    const tempMessageId = new Date().getTime();
    const tempMessage = {
      txrx: 'tx',
      message_body: messageInput,
      id: tempMessageId,
      createdAt: new Date(),
      __typename: 'Message',
    };

    // console.log(messageInput);

    emitSendMessage({
      variables: {
        imei,
        message_body: messageInput,
      },
      refetchQueries: [{ query: GET_WHICHIS }],
      optimisticResponse: {
        __typename: 'Mutation',
        AddTxMessage: {
          isOptimistic: true,
          ok: true,
          error: null,
          __typename: 'AddTxMessageResponse',
        },
      },
      update(proxy, { data: { AddTxMessage: { isOptimistic, ok } } }) {
        const { GetMessages } = proxy.readQuery({ query: GET_WHICHI_MESSAGES, variables: { whichiIds: [selectedMessageView.id] } });
        if (isOptimistic) {
          messageLogsRef.current.scrollTop = messageLogsRef.current.scrollHeight;
          setIsOptimisticMessage(true);
          proxy.writeQuery({
            query: GET_WHICHI_MESSAGES,
            variables: { whichiIds: [selectedMessageView.id] },
            data: {
              GetMessages: {
                ...GetMessages,
                messages: [
                  ...GetMessages.messages,
                  {
                    ...tempMessage,
                    isOptimistic,
                  },
                ],
              },
            },
          });
          setMessageInput('');
        } else if (ok) {
          setIsOptimisticMessage(false);
          messageLogsRef.current.scrollTop = messageLogsRef.current.scrollHeight;
          proxy.writeQuery({
            query: GET_WHICHI_MESSAGES,
            variables: { whichiIds: [selectedMessageView.id] },
            data: {
              GetMessages: {
                ...GetMessages,
                messages: [
                  ...GetMessages.messages,
                  {
                    ...tempMessage,
                  },
                ],
              },
            },
          });
        }
      },
    });
  };

  // Use another user list query for administrator users.

  const [isAdminInitialized, setIsAdminInitialized] = useState(false);
  const [allUserList, setAllUserList] = useState(null);

  const [getAllUsers] = useLazyQuery(GET_WHOLETREE_WHICHIS, {
    onCompleted: (data) => {
      // console.log('GETALLUSER', data);
      const uwPipes = [];
      const findUWPipe = (user) => {
        if (user.uwpipe && user.uwpipe.length) {
          uwPipes.push(...user.uwpipe);
        }

        if (user.usersAsUser && user.usersAsUser.length) {
          user.usersAsUser.forEach(innerUser => findUWPipe(innerUser));
        }
      };
      if (data.GetUsers.ok && data.GetUsers.users) {
        setAllUserList(data.GetUsers.users);
        if (data.GetUsers.users.length) {
          data.GetUsers.users.map(user => findUWPipe(user));

          if (uwPipes.length) {
            if (recentAssets && recentAssets.length) {
              // console.log('INRECENT', recentAssets, uwPipes);
              const foundLastAsset = uwPipes.find(asset => asset.id === recentAssets[recentAssets.length - 1]);

              if (foundLastAsset) {
                loadWhichiMessages(foundLastAsset.id, foundLastAsset.name, foundLastAsset.imei, foundLastAsset);
              }
            }
          }
        }
      } else {
        alert('Failed to fetch user list. Please check server status or connection status.');
      }
    },
    fetchPolicy: 'no-cache',
  });

  const initializeAllUserData = async () => {
    if (isAdminInitialized) return;

    setIsAdminInitialized(true);

    await getAllUsers();
  };

  useEffect(() => {
    if (profileData && ['administrator', 'agent'].includes(profileData.role)) {
      initializeAllUserData();
    }
  });

  const mapUsersAsUser = (usersAsUser, level, noFilter) => {
    // console.log(usersAsUser);
    if (assetSearchKeyword && !noFilter) {
      const uwPipes = [];

      const findUwPipes = (user) => {
        if (user.uwpipe && user.uwpipe.length) {
          uwPipes.push(...user.uwpipe);
        }

        if (user.usersAsUser && user.usersAsUser.length) {
          user.usersAsUser.forEach(findUwPipes);
        }
      };
      usersAsUser.forEach(findUwPipes);
      return uwPipes.filter(whichi => whichi.name && whichi.name.includes(assetSearchKeyword)).map(whichi => <AssetListItem selected={selectedMessageView && selectedMessageView.id === whichi.id} onClick={() => loadWhichiMessages(whichi.id, whichi.name, whichi.imei, whichi)} variant="text">{whichi.name}</AssetListItem>);
    }
    if (level > 1) {
      return usersAsUser.map((user) => {
        if (!user.usersAsUser || !user.usersAsUser.length) return null;
        if (level === 3) {
          let isLeafExist = false;

          user.usersAsUser.forEach((child) => {
            if (child.usersAsUser && child.usersAsUser.length) {
              isLeafExist = true;
            }
          });

          if (!isLeafExist) {
            return null;
          }
        }

        return (
          <AdminInnerExpansionPanel>
            <ExpansionPanelSummary
              style={{ position: 'relative' }}
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography color="textSecondary" variant="body1">{user.nickname || getFullName(user)}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              {
                mapUsersAsUser(user.usersAsUser, level - 1)
              }
            </ExpansionPanelDetails>
          </AdminInnerExpansionPanel>
        );
      });
    }

    return usersAsUser.filter(user => user.uwpipe && user.uwpipe.length).map((assetUser) => {
      const currentUserWhichi = assetUser.uwpipe[0];

      return (
        <AssetListItem selected={selectedMessageView && selectedMessageView.id === currentUserWhichi.id} onClick={() => loadWhichiMessages(currentUserWhichi.id, currentUserWhichi.name, currentUserWhichi.imei, currentUserWhichi)} variant="text">{currentUserWhichi.name}</AssetListItem>
      );
    });
  };

  // END OF CODES FOR ADMINISTRATOR USER LIST QUERY //

  useEffect(() => {
    if (!isInitialized && profileData) {
      setInitialized(true);
      const currentRecentAssets = localStorage.getItem(`${profileData ? profileData.id : 'null'}whichiten-asset-recent`) ? JSON.parse(localStorage.getItem(`${profileData ? profileData.id : 'null'}whichiten-asset-recent`)) : [];

      setRecentAssets(currentRecentAssets);

      if (assets && assets.length) {
        if (currentRecentAssets && currentRecentAssets.length) {
          const foundLastAsset = assets.find(asset => asset.id === currentRecentAssets[currentRecentAssets.length - 1]);

          if (foundLastAsset) {
            loadWhichiMessages(foundLastAsset.id, foundLastAsset.name, foundLastAsset.imei, foundLastAsset);
          }
        }
      }
    }
  });

  // Mobile

  const isMobile = useMediaQuery('(max-width: 500px)');


  return (
    <main
      className={clsx(classes.content, {
        [classes.contentShift]: open,
      })}
      style={isRightAssetEnabled && !isMobile ? {} : { paddingRight: 0, position: 'relative' }}
    >
      <Contents isMobile={isMobile}>
        {
          isMobile && (
            <>
              <MobileAssetListSearch onClick={() => setShowMobileAssetList(true)}>
                <Search />
                <InputBase
                  value={assetSearchKeyword}
                  onChange={(event) => {
                    setAssetSearchKeyword(event.target.value);
                  }}
                />
                {
                  showMobileAssetList && (
                  <Close
                    onClick={(event) => {
                      preventDefault(event);
                      setShowMobileAssetList(false);
                    }}
                  />
                  )
                  || <List />
                }
              </MobileAssetListSearch>
              {
                showMobileAssetList && (
                  <MobileAssetList>
                    {
                      allUserList
                      && allUserList.map(admin => (
                        <AdminOuterExpansionPanel defaultExpanded>
                          <ExpansionPanelSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography color="textSecondary" variant="h6">{!assetSearchKeyword && 'User List' || 'Search Result'}</Typography>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            {
                              admin.usersAsUser && admin.usersAsUser.length
                              && mapUsersAsUser(admin.usersAsUser, profileData.role === 'administrator' ? 3 : 2) || false
                            }
                          </ExpansionPanelDetails>
                        </AdminOuterExpansionPanel>
                      ))
                      || profileData && !['administrator', 'agent'].includes(profileData.role)
                      && (
                        <OuterExpansionPanel style={{ margin: 0 }} defaultExpanded>
                          <ExpansionPanelSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography color="textSecondary" variant="h6">All</Typography>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            {
                              loading
                              && (
                              <div style={{
                                width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 144,
                              }}
                              >
                                <CircularProgress style={{ color: 'white' }} />
                              </div>
                              )
                              || mapGroupedAssets()
                            }
                          </ExpansionPanelDetails>
                        </OuterExpansionPanel>
                      )
                      || (
                        <div style={{
                          width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 144,
                        }}
                        >
                          <CircularProgress style={{ color: 'white' }} />
                        </div>
                      )
                    }
                    <OuterExpansionPanel style={{ margin: 0 }} defaultExpanded>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography color="textSecondary" variant="h6">Recent</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        {
                          !loading
                          && mapAssets(getRecentAssets())
                        }
                      </ExpansionPanelDetails>
                    </OuterExpansionPanel>
                  </MobileAssetList>
                )
              }
            </>
          ) || (
            <ContentLeft>
              {
                allUserList
                && allUserList.map(admin => (
                  <AdminOuterExpansionPanel defaultExpanded>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography color="textSecondary" variant="h6">{!assetSearchKeyword && 'User List' || 'Search Result'}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      {
                        admin.usersAsUser && admin.usersAsUser.length
                        && mapUsersAsUser(admin.usersAsUser, profileData.role === 'administrator' ? 3 : 2) || false
                      }
                    </ExpansionPanelDetails>
                  </AdminOuterExpansionPanel>
                ))
                || profileData && !['administrator', 'agent'].includes(profileData.role)
                && (
                  <OuterExpansionPanel defaultExpanded>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography color="textSecondary" variant="h6">All</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      {
                        loading
                        && (
                        <div style={{
                          width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 144,
                        }}
                        >
                          <CircularProgress style={{ color: 'white' }} />
                        </div>
                        )
                        || mapGroupedAssets()
                      }
                    </ExpansionPanelDetails>
                  </OuterExpansionPanel>
                )
                || (
                  <div style={{
                    width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 144,
                  }}
                  >
                    <CircularProgress style={{ color: 'white' }} />
                  </div>
                )
              }
              <OuterExpansionPanel defaultExpanded>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography color="textSecondary" variant="h6">Recent</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  {
                    !loading
                    && mapAssets(getRecentAssets())
                  }
                </ExpansionPanelDetails>
              </OuterExpansionPanel>
            </ContentLeft>
          )
        }
        {
          !isMobile
          && (
          <ContentCenter>
            <Typography
              style={{
                display: 'block', width: '100%', padding: '8px 16px', borderBottom: '1px solid #999',
              }}
              color="textSecondary"
            >History
            </Typography>
            <FilterWrapper style={{ width: '100%', borderBottom: '1px solid #999', marginLeft: 0 }}>
              <Search style={{ color: '999999', marginRight: 12 }} /><InputBase value={messageSearchKeyword} onChange={event => setMessageSearchKeyword(event.target.value)} style={{ width: '100%' }} placeholder="Search" />
            </FilterWrapper>
            {
              loading
              && (
              <div style={{
                width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 144,
              }}
              >
                <CircularProgress style={{ color: 'white' }} />
              </div>
              )
              || mapMessageAssets()
            }
          </ContentCenter>
          )
        }
        <ContentRight isMobile={isMobile}>
          {
            selectedMessageView
            && (
              <>
                <RightInnerLeft isMobile={isMobile}>
                  <MessageView>
                    <div>
                      <Typography>
                        {
                      selectedMessageView.name
                    }
                      </Typography>
                    </div>
                    {
                      messagesLoading
                      && (
                      <div style={{
                        width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 144,
                      }}
                      >
                        <CircularProgress color="secondary" />
                      </div>
                      )
                      || (
                        <>
                          <div ref={messageLogsRef}>
                            {
                              mapMessageLogs([...messagesData.GetMessages.messages])
                            }
                          </div>
                          <div>
                            <InputBase
                              inputRef={messageInputRef}
                              inputComponent="textarea"
                              value={messageInput}
                              onKeyDown={onEnter(sendMessage, () => { if (getByteLength(messageInput) > 269) return; setMessageInput(`${messageInput}\n`); setTimeout(() => { messageInputRef.current.style.height = ''; messageInputRef.current.style.height = `${messageInputRef.current.scrollHeight - 13}px`; window.messageInputRef = messageInputRef; }, 10); })}
                              onChange={(event) => {
                                if (event.keyCode === 13 && !event.ctrlKey) return;
                                if (getByteLength(event.target.value) > 269) return;
                                setMessageInput(event.target.value); messageInputRef.current.style.height = ''; messageInputRef.current.style.height = `${messageInputRef.current.scrollHeight - 13}px`; window.messageInputRef = messageInputRef;
                              }}
                              style={{ width: '100%' }}
                              placeholder="Text to send..."
                            />
                            {
                              sendMessageLoading
                              && <CircularProgress size={16} color="primary" />
                              || <Send onClick={sendMessage} />
                            }
                          </div>
                        </>
                      )
                    }
                  </MessageView>
                </RightInnerLeft>
              </>
            )
            || (
            <div style={{
              width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: 1,
            }}
            >
              <Pageview style={{ fontSize: 48 }} />
              <Typography>Please select the asset from left navigation.</Typography>
            </div>
            )
          }
          {
            !isMobile
            && (
              <RightInnerRight>
                <Typography color="textSecondary">Information</Typography>
                <div style={{ height: '280px', width: '100%' }}>
                  <KeepAliveGoogleMap
                    onChange={({ center: newCenter, zoom: newZoom }) => setMapPosition({ center: newCenter, zoom: newZoom })}
                    center={
                      center
                    }
                    zoom={
                      zoom
                    }
                    options={createMapOptions}
                  >
                    {
                  assetMarkers.map(marker => (
                    <AssetComponent
                      style={{ zIndex: 100 }}
                      lat={marker.lat}
                      id={marker.id}
                      lng={marker.lng}
                      course={marker.course}
                      name={marker.name}
                      icon={marker.icon}
                      group={marker.whichigroup}
                      marker={marker}
                    />
                  ))
                }
                  </KeepAliveGoogleMap>
                </div>
                {
                  selectedMessageView
                  && assetMarkers && assetMarkers.length
                  && (
                    <>
                      <InformationBlock>
                        <span>
                        Asset Name
                        </span>
                        {
                          selectedMessageView.name
                        }
                      </InformationBlock>
                      <InformationBlock>
                        <span>
                        IMEI
                        </span>
                        {
                          selectedMessageView.imei
                        }
                      </InformationBlock>
                      <InformationBlock>
                        <span>
                        UTC
                        </span>
                        {
                          assetMarkers[0] && getUTCTime(assetMarkers[0]) || '-'
                        }
                      </InformationBlock>
                      <InformationBlock>
                        <span>
                        Latitude
                        </span>
                        {
                          assetMarkers[0] ? assetMarkers[0].lat && `${assetMarkers[0].lat}°` || '-' : '-'
                        }
                      </InformationBlock>
                      <InformationBlock>
                        <span>
                        Longitude
                        </span>
                        {
                          assetMarkers[0] ? assetMarkers[0].lng && `${assetMarkers[0].lng}°` || '-' : '-'
                        }
                      </InformationBlock>
                      <InformationBlock>
                        <span>
                        Speed
                        </span>
                        {
                          assetMarkers[0] ? assetMarkers[0].speed && `${parseFloat(mapOption.speedUnit !== 'km/h' ? assetMarkers[0].speed : assetMarkers[0].speed * 1.852).toFixed(2)}${mapOption.speedUnit}` || '-' : '-'
                        }
                      </InformationBlock>
                      <InformationBlock>
                        <span>
                        Course
                        </span>
                        {
                          assetMarkers[0] ? assetMarkers[0].course && `${assetMarkers[0].course}°` || '-' : '-'
                        }
                      </InformationBlock>
                      <InformationBlock>
                        <span>
                        Altitude
                        </span>
                        {
                          assetMarkers[0] ? assetMarkers[0].alt && `${parseFloat(mapOption.altUnit !== 'feet' ? assetMarkers[0].alt : assetMarkers[0].alt * 3.281).toFixed(2)}${mapOption.altUnit !== 'feet' ? 'm' : 'feet'}` || '-' : '-'
                        }
                      </InformationBlock>
                    </>
                  )
                }
              </RightInnerRight>
            )
          }
        </ContentRight>
      </Contents>
    </main>
  );
}
