export const assetInfoTypes = ['Automobile', 'Aircraft', 'Facility', 'Vessel'];

export const assetInfoUsage = {
  Vessel: ['None', 'Fishing', 'Passenger ', 'General cargo', 'Bulk carrier', 'Container', 'Tanker', 'Auto carrier', 'Oil industry ', 'Tow and Tug', 'Barge', 'Offshore', 'Specialty', 'Other'],
  Automobile: ['None', 'Car', 'Van', 'Bus', 'Truck', 'Trailer', 'Train', 'Freight Train', 'Other'],
  Aircraft: ['None', 'Airplane', 'Helicopter', 'Other'],
  Facility: ['None'],
};


export const allAssetInfoUsage = ['None', 'Fishing', 'Passenger ', 'General cargo', 'Bulk carrier', 'Container', 'Tanker', 'Auto carrier', 'Oil industry ', 'Tow and Tug', 'Barge', 'Offshore', 'Specialty', 'Car', 'Van', 'Bus', 'Truck', 'Trailer', 'Train', 'Freight Train', 'Airplane', 'Helicopter', 'Other'];

export const assetInfoStatus = ['active', 'inactive', 'distress', 'not use'];
