import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { AssetSVG } from '../images/svgs';
import { drawerWidth, WICHI_MAPOPTION_KEY } from '../constants';
import { AssetComponent, PingComponent, KeepAliveGoogleMap } from '../components/map';
import { Blinder } from '../components/views';


/* Triangle Ping
export const AssetPings = styled.div`
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid black;
  margin-left: -20px;
  margin-top: -10px;
  z-index: 50;
  position: relative;

  &::after{
    content: "";
    width: 0;
    height: 0;
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-bottom: 14px solid red;
    position: absolute;
    left: -14px;
    bottom: -17.5px;
  }
`;
*/

const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    padding: 0,
    paddingRight: 48,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    marginTop: 64,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export default function Map(props) {
  const classes = useStyles();

  const {
    open, center, zoom, setMapPosition, assetPings, assetMarkers, isMobile, pingColor, onClickMarker, mapOption,
  } = props;
  const [lastHash, setLastHash] = useState(mapOption ? mapOption.hash : null);
  const [currentMap, setCurrentMap] = useState(window.currentMap || null);

  const createMapOptions = () => ({
    zoomControl: false,
    fullscreenControl: false,
    minZoom: 3,
  });

  useEffect(() => {
    if (mapOption && mapOption.hash !== lastHash) {
      setLastHash(mapOption.hash);
      // console.log(mapOption, mapOption.lastChanged, currentMap);

      if (mapOption && mapOption.lastChanged === 'mapType' && currentMap) {
        // console.log('CHANGING MAP TYPE');
        currentMap.setMapTypeId(mapOption.mapType);
      }
    }
  });

  const handleApiLoaded = (map, maps) => {
    // console.log('GOOGLE MAP API LOADED', map, maps);

    setCurrentMap(map);

    window.currentMap = map;

    try {
      const savedMapOptions = JSON.parse(localStorage.getItem(WICHI_MAPOPTION_KEY));

      const {
        mapType,
      } = savedMapOptions;

      if (mapType) {
        map.setMapTypeId(mapType);
      }
    } catch (err) {
      // console.log(err);
    }
  };

  console.log(mapOption);

  return (
    <main
      style={isMobile ? {
        paddingRight: 0,
      } : {}}
      className={clsx(classes.content, {
        [classes.contentShift]: open,
      })}
    >
      <div className={classes.drawerHeader} />
      <div style={{ height: isMobile ? 'calc(100vh - 128px)' : 'calc(100vh - 64px)', width: '100%' }}>
        <KeepAliveGoogleMap
          onChange={({ center: newCenter, zoom: newZoom }) => {
            // console.log('SETTING MAP', newCenter, newZoom);
            setMapPosition({ center: newCenter, zoom: newZoom });
          }}
          center={
          center
        }
          zoom={
          zoom
        }
          options={createMapOptions}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        >
          <Blinder opacity={mapOption.opacity} />
          {
          assetPings.map((ping, index) => (
            <PingComponent
              defaultColor={mapOption.defaultColor}
              lat={ping.lat}
              lng={ping.lng}
              kind={ping.kind}
              id={ping.id}
              size={mapOption.trackSize}
              course={ping.course}
              color={pingColor}
              opacity={(index + (assetPings.length / 10 * 3)) / (assetPings.length + (assetPings.length / 10 * 3))}
              ping={ping}
              speedUnit={mapOption.speedUnit}
              altUnit={mapOption.altUnit}
            />
          ))
        }
          {
          assetMarkers.map(marker => (
            <AssetComponent
              defaultColor={mapOption.defaultColor}
              style={{ zIndex: 100 }}
              lat={marker.lat}
              id={marker.id}
              lng={marker.lng}
              course={marker.course}
              size={mapOption.assetSize}
              showLabel={mapOption.showLabel}
              speedUnit={mapOption.speedUnit}
              altUnit={mapOption.altUnit}
              color={marker.whichigroup && marker.whichigroup.color}
              group={marker.whichigroup}
              name={marker.name}
              icon={marker.icon}
              marker={marker}
              onClickMarker={() => (onClickMarker ? onClickMarker(marker) : (() => {}))}
            />
          ))
        }
        </KeepAliveGoogleMap>
      </div>
    </main>
  );
}
