import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Provider as KeepAliveProvider } from 'react-keep-alive';
import MomentUtils from '@date-io/moment';
import theme from './theme';
import App from './App';
import apolloClient from './libs/apolloClient';

const styledTheme = {
  palette: {
    default: '#000000',
    primary: {
      main: '#3D6BEB',
      backgroundGradient: 'linear-gradient(217deg, #3D6BEB, #5F63ED)',
      contrast: '#ffffff',
    },
    secondary: {
      main: '#06A1AF',
    },
    grey: {
      main: '#BBBBBB',
      light: '#EFEFEF',
    },
    text: {
      primary: '#333333',
      secondary: '#999999',
    },
    background: {
      light: '#ffffff',
    },
    misc: {
      rating: '#EBDB4D',
    },
    error: '#f44336',
  },
  zIndex: {
    sidebar: 1000,
  },
  sizes: {
    headerHeight: 56,
    sidebarWidth: 240,
  },
};

const Root = () => (
  <ApolloProvider client={apolloClient}>
    <StylesProvider injectFirst>
      <StyledThemeProvider theme={styledTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <ThemeProvider theme={theme}>
            <KeepAliveProvider>
              <CssBaseline />
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </KeepAliveProvider>
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </StyledThemeProvider>
    </StylesProvider>
  </ApolloProvider>
);

export default Root;
