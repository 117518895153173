import styled from 'styled-components';
import { Tabs, Tab } from '@material-ui/core';

export const WichiTabs = styled(Tabs)`
width: 100%;
padding: 0;
margin-top: 0;

> div > div > span {
  background-color: ${props => props.theme.palette.secondary.main};
}
`;

export const WichiTab = styled(Tab)`
& {
  text-transform: none;
  min-width: ${props => props.minWidth || '25%'} !important;
  font-size: 1rem;
  padding-bottom: 8px;
  &:hover {
    color: ${props => props.theme.palette.secondary.main};
    opacity: 0.8;
  }
  &[aria-selected = true] {
    color: ${props => props.theme.palette.secondary.main};
  }
  &:focus {
    color: ${props => props.theme.palette.secondary.main};
  }
}
`;
