import moment from 'moment';
import { API_HOST } from '../constants';

class Debouncer {
  debounce = ({ key: debounceKey, callback, timeout }: {key: any, callback: Function, timeout: number}) => {
    clearTimeout(this[debounceKey]);
    this[debounceKey] = setTimeout(callback, timeout);
  }
}

const debouncer = new Debouncer();

export const { debounce } = debouncer;

export const intervalUntil = async ({ until, repeat, interval }) => {
  const awaiter = innerInterval => new Promise((resolve) => {
    setTimeout(resolve, innerInterval);
  });
  let result;
  while (!result || !until(result)) {
    result = await repeat(); // eslint-disable-line
    // console.log(result);
    await awaiter(interval); // eslint-disable-line
  }
  return result;
};

export const getByteLength = (s, b, i, c) => {
  for (b = i = 0; c = s.charCodeAt(i++); b += c >> 11 ? 3 : c >> 7 ? 2 : 1); // eslint-disable-line
  return b;
};

export const onEnter = (callback, ctrlKeyCallback) => (event) => {
  if (event.keyCode === 13 && !event.ctrlKey) {
    callback();
  }

  if (event.keyCode === 13 && event.ctrlKey) {
    console.log('ctrlKey');
    if (ctrlKeyCallback) {
      ctrlKeyCallback();
    }
  }
};

export const humanizeDate = (dateString, options) => {
  const momentDate = moment(dateString);
  const { year } = (options || {});
  if (moment().dayOfYear() === momentDate.dayOfYear()) {
    return `Today, ${momentDate.format('HH:mm')}`;
  }
  if ((moment() - momentDate) < 1000 * 60 * 60 * 24 * 365 && !year) {
    return momentDate.format('MM-DD HH:mm');
  }
  if (year) {
    return momentDate.format('YYYY/MM/DD  HH:mm:ss');
  }
  return momentDate.format('YYYY/MM/DD');
};

export const getUTCTime = ({ utc }, { dateOnly, hourOnly, separateDateHour } = {}) => {
  try {
    if (!utc) return null;

    if (separateDateHour) {
      const currentMoment = moment(utc).utc();
      return [currentMoment.format('YYYY/MM/DD'), currentMoment.format('HH:mm:ss')];
    }
    return moment(utc).utc().format(dateOnly && 'YYYY/MM/DD' || hourOnly && 'HH:mm:ss' || 'YYYY/MM/DD HH:mm:ss');
  } catch (err) {
    // console.log(err);

    return null;
  }
};

export const firstUpperLetter = targetString => targetString.slice(0, 1).toUpperCase();

export const capitalize = targetString => targetString && `${targetString.slice(0, 1).toUpperCase()}${targetString.slice(1)}` || null;

export const filterBlankToNull = (targetObject) => {
  const newObject = {};
  Object.keys(targetObject).forEach((targetKey) => {
    if (targetObject[targetKey] === '') {
      newObject[targetKey] = null;
      return;
    }
    newObject[targetKey] = targetObject[targetKey];
  });

  return newObject;
};

export const preventDefault = (event) => {
  if (event.stopPropagation) event.stopPropagation();
  if (event.preventDefault) event.preventDefault();

  return event;
};

export const getFullName = user => user && `${user.firstName} ${user.lastName}` || null;

export const photoURL = photoId => `${API_HOST}/photos/${photoId}`;
