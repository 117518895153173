
class AuthHandler {
  constructor() {
    const savedAccessToken = localStorage.getItem('wichi-access-token');

    if (savedAccessToken) {
      this.accessToken = savedAccessToken;
    }
  }

  signIn = (accessToken) => {
    this.accessToken = accessToken;
    localStorage.setItem('wichi-access-token', accessToken);
  }

  signOut = () => {
    this.accessToken = null;
    localStorage.removeItem('wichi-access-token');
  }

  getAccessToken = () => this.accessToken
}

const authHandler = new AuthHandler();

export default authHandler;
