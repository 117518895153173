import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  AllOut, Stars, SettingsInputAntenna, ControlCamera,
} from '@material-ui/icons';
import { Tooltip, Typography } from '@material-ui/core';
import { KeepAlive, bindLifecycle } from 'react-keep-alive';
import GoogleMapReact from 'google-map-react';
import DoubleArrow from '../images/DoubleArrow.png';
import Report from '../images/Report.png';
import Room from '../images/Room.png';
import ReportOff from '../images/ReportOff.png';
import { AssetSVG } from '../images/svgs';
import { humanizeDate, getUTCTime } from '../libs/helpers';


export const AssetMarker = styled.div`
  > .asset-img {
    height: 64px;
    transform-origin: center;
    transform: scale(${props => 1 + (props.size - 50) / 50});
  }
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -20px;
  margin-top: -20px;
  z-index: 100;
  transform: rotateZ(${props => props.course}deg);
`;

export const AssetPings = styled.img`
  width: 30px;
  height: 30px;
  z-index: 50;
  position: relative;
  color: ${props => props.color || '#777777'};
  transform-origin: center;
  transform: scale(${props => 1 + (props.size - 50) / 50}) rotateZ(-90deg);
`;

const PollPings = styled.img`
  width: 30px;
  height: 30px;
  z-index: 50;
  position: relative;
  color: ${props => props.color || '#777777'};
  transform-origin: center;
  transform: scale(${props => 1 + (props.size - 50) / 50});
`;

const DistressPings = styled.img`
  width: 30px;
  height: 30px;
  z-index: 50;
  position: relative;
  color: ${props => props.color || '#777777'};
  transform-origin: center;
  transform: scale(${props => 1 + (props.size - 50) / 50});
`;

const DistressOffPings = styled.img`
  width: 30px;
  height: 30px;
  z-index: 50;
  position: relative;
  color: ${props => props.color || '#777777'};
  transform-origin: center;
  transform: scale(${props => 1 + (props.size - 50) / 50});
`;

export const AssetComponent = ({
  course, id, icon, color, group, marker, onClickMarker, showLabel, size, speedUnit, altUnit, defaultColor,
}) => (
  showLabel
  && (
  <Tooltip
    classes={{
      tooltip: 'mui-custom-tooltip',
    }}
    onClick={onClickMarker || (() => {})}
    placement="top"
    title={(
      <Typography style={{ position: 'relative' }}>
        {marker && marker.name || 'Unknown'}
        <br />
        Recieved at: {marker.last_position && marker.last_position.createdAt ? humanizeDate(marker.last_position.createdAt, { year: true }) : 'Date unknown'}
        <br /> UTC: {marker.last_position && getUTCTime(marker.last_position) || '-'}
        <br /> GPS: {marker.last_position && marker.last_position.lat && `${marker.last_position.lat}°` || '-'}, {marker.last_position && marker.last_position.lng && `${marker.last_position.lng}°` || '-'}
        <br /> S: {marker.last_position && marker.last_position.speed && `${parseFloat(speedUnit !== 'km/h' ? marker.last_position.speed : marker.last_position.speed * 1.852).toFixed(2)} ${speedUnit}` || '-'}, C: {marker.last_position && marker.last_position.course && `${marker.last_position.course}°` || '-'} A: {marker.last_position && marker.last_position.alt && `${parseFloat(altUnit !== 'feet' ? marker.last_position.alt : marker.last_position.alt * 3.281).toFixed(2)}${altUnit !== 'feet' ? 'm' : 'feet'}` || '-'}
        {
          marker.last_position && marker.last_position.kind === 'place_rx_poll'
          && <PollPings src={Room} color="white" style={{ position: 'absolute', right: 0, top: 0 }} />
          || (marker.last_position.kind === 'distress_rx' || marker.last_position.kind === 'distress_rx_on')
          && <DistressPings src={Report} color="#f44336" style={{ position: 'absolute', right: 0, top: 0 }} />
          || marker.last_position.kind === 'distress_rx_off'
          && <DistressOffPings src={ReportOff} color="white" style={{ position: 'absolute', right: 0, top: 0 }} />
        }
      </Typography>
    )}
  >
    <div key={id} style={{ zIndex: 100 }}><AssetMarker size={size} course={course}><AssetSVG icon={icon || group && group.icon} color={color || group && group.color || defaultColor} /></AssetMarker>
    </div>
  </Tooltip>
  )
  || <div key={id} style={{ zIndex: 100 }}><AssetMarker size={size} course={course}><AssetSVG icon={icon || group && group.icon} color={color || group && group.color || defaultColor} /></AssetMarker></div>
);

export const PingComponent = ({
  course, id, ping, speedUnit, altUnit, opacity, color, size, defaultColor, kind,
}) => (
  <Tooltip
    classes={{
      tooltip: 'mui-custom-tooltip',
    }}
    title={(
      <Typography>
        Recieved at: {ping && ping.createdAt ? humanizeDate(ping.createdAt, { year: true }) : 'Date unknown'}
        <br /> UTC: {getUTCTime(ping) || '-'}
        <br /> GPS: {ping.lat && `${ping.lat}°` || '-'}, {ping.lng && `${ping.lng}°` || '-'}
        <br /> S: {ping.speed && `${parseFloat(speedUnit !== 'km/h' ? ping.speed : ping.speed * 1.852).toFixed(2)} ${speedUnit}` || '-'}, C: {ping.course && `${ping.course}°` || '-'} A: {ping.alt && `${parseFloat(altUnit !== 'feet' ? ping.alt : ping.alt * 3.281).toFixed(2)}${altUnit !== 'feet' ? 'm' : 'feet'}` || '-'}
      </Typography>
    )}
    placement="top"
  >{
      kind === 'place_rx_poll'
      && (
        <PollPings
          src={Room}
          style={{
            width: 30, height: 30, marginLeft: -15, marginTop: -15, zIndex: 50, transform: ['place_rx_poll', 'distress_rx', 'distress_rx_off', 'distress_rx_on'].includes(kind) && ' ' || `rotateZ(${course}deg)`, opacity,
          }}
          size={size}
          color={color || defaultColor || '#777777'}
        />
      ) || (kind === 'distress_rx_on' || kind === 'distress_rx')
      && (
        <DistressPings
          src={Report}
          style={{
            width: 30, height: 30, marginLeft: -15, marginTop: -15, zIndex: 50, transform: ['place_rx_poll', 'distress_rx', 'distress_rx_off', 'distress_rx_on'].includes(kind) && ' ' || `rotateZ(${course}deg)`, opacity,
          }}
          size={size}
          color="#f44336"
        />
      ) || kind === 'distress_rx_off'
      && (
        <DistressOffPings
          src={ReportOff}
          style={{
            width: 30, height: 30, marginLeft: -15, marginTop: -15, zIndex: 50, transform: ['place_rx_poll', 'distress_rx', 'distress_rx_off', 'distress_rx_on'].includes(kind) && ' ' || `rotateZ(${course}deg)`, opacity,
          }}
          size={size}
          color="#777777"
        />
      ) || (
        <AssetPings
          src={DoubleArrow}
          style={{
            width: 30, height: 30, marginLeft: -15, marginTop: -15, zIndex: 50, transform: ['place_rx_poll', 'distress_rx', 'distress_rx_off', 'distress_rx_on'].includes(kind) && ' ' || `rotateZ(${course}deg)`, opacity,
          }}
          size={size}
          color={color || defaultColor || '#777777'}
        />
      )
    }
  </Tooltip>
);

let setGoogleMapProps;

const BoundedGoogleMap = (originProps) => {
  const [props, setProps] = useState(originProps);

  setGoogleMapProps = setProps;

  const { children, ...restProps } = props || {};

  return (
    <GoogleMapReact
      {...restProps}
      bootstrapURLKeys={{
        key: 'AIzaSyCxzM8PLKExD5SPgUM_4CvnLl-we-dM6iQ',
      }}
    >
      {children}
    </GoogleMapReact>
  );
};

export const KeepAliveGoogleMap = (props) => {
  useEffect(() => {
    if (setGoogleMapProps) {
      setGoogleMapProps(props);
    }
  });

  const { children, ...restProps } = props;
  return (
    <KeepAlive key="googleMap">
      <BoundedGoogleMap
        {...restProps}
      >
        {children}
      </BoundedGoogleMap>
    </KeepAlive>
  );
};
