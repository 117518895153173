import React from 'react';
import styled from 'styled-components';
import { Route } from 'react-router-dom';
import SignIn from 'pages/SignIn';
import Dashboard from 'pages/Dashboard';
import ErrorBoundary from './components/error';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  a {
    color: #0060B6;
    text-decoration: none;
}

a:hover 
{
     color:#00A0C6; 
     text-decoration:none; 
     cursor:pointer;  
}
`;

function App() {
  return (
    <ErrorBoundary>
      <Wrapper>
        <Route exact path="/" component={SignIn} />
        <Route path="/dashboard" component={Dashboard} />
      </Wrapper>
    </ErrorBoundary>
  );
}

export default App;
