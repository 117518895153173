import gql from 'graphql-tag';

export const other = '';

export const ADD_WHICHI_GROUP = gql`
  mutation AddWhichiGroup(
    $name: String
  ) {
    AddWhichiGroup(
      name: $name
      color: ""
      manager: ""
      pilots: ""
      icon: ""
    ) {
      ok
      error
      groupid
    }
  }
`;

export const DELETE_WHICHI_GROUP = gql`
  mutation DeleteWhichiGroup(
   $whichiGroupId: Int! 
  ) {
    DelWhichiGroup (whichiGroupId: $whichiGroupId) {
      ok
      error
    }
  }
`;
