import gql from 'graphql-tag';

export const GET_USAGE_DATA = gql`
  query GetMessageUseBytes(
    $whichiId: Int!
    $yearMonth: String
  ) {
    GetMessageUseBytes(
      whichiId: $whichiId
    ) {
      ok
      error
      tx
      rx
    }
    MonthlyUseBytes: GetMessageUseBytes(
      whichiId: $whichiId
      yearMonth: $yearMonth
    ) {
      ok
      error
      tx
      rx
    }
  }
`;

export const other = '';
