import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
  Typography, CircularProgress, Button, MenuItem, IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  DialogContentText,
  useMediaQuery,
  InputBase,
  Select,
} from '@material-ui/core';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  ExpandMore, Pageview, ArrowForwardIos, Face, Edit, Check, Cancel, Settings, Delete, CloudUpload, Search, List, Close,
} from '@material-ui/icons';
import gql from 'graphql-tag';
import { useMutation, useQuery as useApolloQuery, useLazyQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import { AssetSVG, allAssetIcons } from '../images/svgs';
import {
  filterBlankToNull, firstUpperLetter, getFullName, capitalize, photoURL, preventDefault,
} from '../libs/helpers';
import TruckProfile from '../images/truck.png';
import { SquareTextField, HiddenInput } from '../components/input';
import { SquareSelect } from '../components/select';
import { assetInfoTypes, assetInfoUsage, assetInfoStatus } from '../libs/assetInfoFilters';
import {
  EDIT_WHICH, GET_WHICHIS, EDIT_WHICHI_GROUP, GET_WHICHI_GROUPS, GET_WHOLETREE_WHICHIS,
} from '../graphql/whichi';
import {
  GroupIcon, AssetListItem, AdminOuterExpansionPanel, AdminInnerExpansionPanel, StyledColorPicker,
} from '../components/views';
import { drawerWidth } from '../constants';
import { AssetComponent, KeepAliveGoogleMap } from '../components/map';
import { ADD_WHICHI_GROUP, DELETE_WHICHI_GROUP } from '../graphql/groups';
import { GET_USAGE_DATA } from '../graphql/usage';
import { servicesPlans } from '../libs/userInfoFilters';
import { UPLOAD_FILE } from '../graphql/file';
import { MobileAssetListSearch, MobileAssetList } from '../components/mobile';


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const DatePickerThemed = styled(DatePicker)`
  label {
    color: white !important;
  }
  input {
    color: white !important;
  }
  div {

  &::after, &::before {
    border-bottom-color: white !important;
  }
}
`;

const DEFAULT_ASSET_INFO = {
  name: 'default',
  group: '',
  pilot: '',
  type: '',
  usage: '',
  status: '',
  IMEI: '',
  information: '',
  mission: '',
  activity: '',
  destination: '',
  servicePlan: '',
  serialNumber: '',
};

const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    padding: 0,
    paddingRight: 48,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    marginTop: 64,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const ExpansionPanel = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
    alignItems: 'center',
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles({
  root: {
    padding: 0,
    paddingLeft: 12,
    flexDirection: 'column',
  },
})(MuiExpansionPanelDetails);

const Contents = styled.div`
  display: flex;
  height: ${props => props.isMobile && '100%' || 'calc(100vh - 64px)'};
`;

const ContentLeft = styled.div`
  width: 300px;
  background: #0E0F1A;
  height: 100%;
  border-left: 4px solid black;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 16px;
  }
  ::-webkit-scrollbar-track {
    background: #0E0F1A;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.4);
    width: 16px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 8px;
  }
`;

const ContentRight = styled.div`
  height: 100%;
  flex-grow: 1;
  background: white;
  display: flex;
`;


const RightInner = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #252525;
  position: relative;
  align-items: center;

  >p:first-child {
    display: flex;
    padding: 8px 16px;
  }
`;


const OuterExpansionPanel = styled(ExpansionPanel)`
  background-color: transparent;
  box-shadow: none;

  > div {
    > div:first-child {
      margin-left: 32px;
    }
    > div + div {
      color: white;
      color: white;
      margin-right: 0;
      position: absolute;
      left: 12px;
    }
  }
`;

const InnerExpansionPanel = styled(ExpansionPanel)`
  background-color: transparent;
  box-shadow: none;
  width: 100%;

  > div {
    > div:first-child {
      margin-left: 32px;
    }
    > div + div {
      color: white;
      color: white;
      margin-right: 0;
      position: absolute;
      left: 12px;
    }
  }
`;


const InformationBlock = styled(Typography)`
  color: white;
  font-size: 0.825rem;
  width: 100%;
  > span {
    text-align: left;
    > span {
      font-weight: normal;
      display: inline-block;
      width: 160px;
    }
    font-weight: bold;
    display: inline-flex;
${
  props => props.isMobile
  && `
    width: 100%;
  `
  || `
    width: 707px;
  `
}
    position: relative;
    align-items: center;
    justify-content: flex-start;

    > svg {
      position: absolute;
      right: 0;
    }
  }
  text-align: center;
  padding: 12px;
  border-bottom: 1px solid #777;
`;

const SelectedAssetInfo = styled.div`
  background: #555555;
  display: flex;
  align-items: center;
  > div:first-child {
    flex-grow: 1;
    padding: 16px;
    > div {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: calc(50% - 12px);
      margin-bottom: 12px;
      > p {
        color: white;
        margin: 0;
        display: flex;
        height: 23px;
        align-items: center;
      }
      > p + p {
        font-weight: bold;
      }
    }
  }
${
  props => props.isMobile && `
    width: 100%;
    height: fit-content;
    position: relative;
    flex-direction: column;
    > div:first-child {
      >div:first-child, div:nth-child(2) {
        width: 100%;
      }
    }
    > img {
      width: 30%;
      padding-bottom: 30%;
      position: absolute;
      top: 0;
      right: 0;
    }
  ` || `
    height: 200px;
    width: 707px;
    position: absolute;
    top: 300px;
    > img {
      width: 200px;
      height: 200px;
    }
  `
}
`;

const AssetInfoEdits = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
${
  props => props.isMobile && `
  right: 16px;
  ` || `
  right: 0;
  `
}
  top: -36px;
  > span {
    cursor: pointer;
    >svg {
      margin-right: 8px;
    }
    font-size: 0.825rem;
    margin-left: 12px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Blinder = styled.div`
  position: absolute;
  width: 100%;
${
  props => props.isMobile
  && `
    height: 240px;
  `
  || `
    height: 500px;
  `
}
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.4);
`;

const IconSelector = styled.div`
  width: 252px;
  display: flex;
  flex-wrap: wrap;
  margin: 24px;
  margin-right: 10px;
  margin-bottom: 10px;

  > div {
    width: 48px;
    height: 48px;
    margin-right: 14px;
    margin-bottom: 14px;
    cursor: pointer;
    &:hover {
      > svg {
        transform: scale(1.2);
        transform-origin: center center;
      }
    }

    > svg {
      transition: all 0.1s ease-out;
      width: 100%;
      height: 100%;
    }
  }
`;

export default function AssetPage(props) {
  const classes = useStyles();
  const {
    open, assets, loading, isRightAssetEnabled, userAccess, profileData, configAccess, mapOption,
  } = props;
  const [agentAssets, setAgentAssets] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [onEditAsset, setOnEditAsset] = useState(false);
  const [assetInfoInput, setAssetInfoInput] = useState(DEFAULT_ASSET_INFO);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [onEditGroup, setOnEditGroup] = useState(false);
  const [groupInfoInput, setGroupInfoInput] = useState({});
  const [recentAssets, setRecentAssets] = useState(localStorage.getItem(`${profileData ? profileData.id : 'null'}whichiten-asset-recent`) ? JSON.parse(localStorage.getItem(`${profileData ? profileData.id : 'null'}whichiten-asset-recent`)) : []);
  const [commitEditAsset, { loading: editAssetLoading }] = useMutation(EDIT_WHICH);
  const [commitEditGroup, { loading: editGroupLoading }] = useMutation(EDIT_WHICHI_GROUP);
  const [commitAddGroup, { loading: addGroupLoading }] = useMutation(ADD_WHICHI_GROUP);
  const [commitDeleteGroup, { loading: deleteGroupLoading }] = useMutation(DELETE_WHICHI_GROUP);
  const [isInitialized, setInitialized] = useState(false);
  const [isAssetUpdated, setIsAssetUpdated] = useState(false);
  const [isGroupUpdated, setIsGroupUpdated] = useState(false);
  const [isPreSelected, setIsPreSelected] = useState(false);
  const [onColorPick, setOnColorPick] = useState(false);
  const [onGroupIconPick, setOnGroupIconPick] = useState(false);
  const [onAssetIconPick, setOnAssetIconPick] = useState(false);
  const [openNewGroup, setOpenNewGroup] = useState(false);
  const [onDeleteGroup, setOnDeleteGroup] = useState(false);
  const [isAgentAssetUpdated, setIsAgentAssetUpdated] = useState(false);
  const [showMobileAssetList, setShowMobileAssetList] = useState(false);
  const [assetSearchKeyword, setAssetSearchKeyword] = useState('');
  const [groupNameInput, setGroupNameInput] = useState('');
  const [usageMonth, setUsageMonth] = useState(moment());
  const query = useQuery();
  const { loading: getGroupsLoading, data: getGroupsData } = useApolloQuery(GET_WHICHI_GROUPS);
  const [currentUserId, setCurrentUserId] = useState(profileData ? profileData.id : null);
  const hiddenUploadInputRef = useRef(null);

  const [getWhichiUsage, { data: usageData, loading: usageLoading }] = useLazyQuery(GET_USAGE_DATA, { fetchPolicy: 'no-cache' });


  useEffect(() => {
    if (currentUserId !== (profileData ? profileData.id : null)) {
      setCurrentUserId(profileData ? profileData.id : null);
      setRecentAssets(localStorage.getItem(`${profileData ? profileData.id : 'null'}whichiten-asset-recent`) ? JSON.parse(localStorage.getItem(`${profileData ? profileData.id : 'null'}whichiten-asset-recent`)) : []);
    }
  });

  /* 지도 관련 */
  const [assetMarkers, setAssetMarkers] = useState([]);
  const [{ center, zoom }, setMapPosition] = useState({
    center: {
      lat: 37.532600,
      lng: 127.024612,
    },
    zoom: 14,
  });

  const createMapOptions = () => ({
    zoomControl: false,
    fullscreenControl: false,
  });

  const createAssetMarker = (place) => {
    clearTimeout(window.setMapTimeout);
    setMapPosition({
      center: {
        lat: place.lat,
        lng: place.lng,
      },
      zoom,
    });
    window.setMapTimeout = setTimeout(() => {
      setMapPosition({
        center: {
          lat: place.lat,
          lng: place.lng,
        },
        zoom,
      });
    }, 500);

    setAssetMarkers([
      { ...place },
    ]);
  };

  /* 지도 관련 끝 */

  const groupedAssets = {};
  const groups = {};

  if (!getGroupsLoading && getGroupsData.GetMyWhichiGroups && getGroupsData.GetMyWhichiGroups.ok) {
    getGroupsData.GetMyWhichiGroups.whichigroups.map((group) => {
      groupedAssets[group.name] = [];
      groups[group.name] = group;
    });
  }

  const addOrCreateToGroup = (asset, groupName, group) => {
    if (!groupedAssets[groupName]) {
      groupedAssets[groupName] = [];

      if (groupName !== 'Ungroup') {
        groups[groupName] = group;
      }
    }
    groupedAssets[groupName].push(asset);
  };

  if (!loading) {
    assets.map((asset) => {
      if (assetSearchKeyword) {
        if (!(asset.name && asset.name.includes(assetSearchKeyword))) {
          return;
        }
      }
      if (!asset.whichigroup) {
        addOrCreateToGroup(asset, 'Ungroup');
      } else {
        addOrCreateToGroup(asset, asset.whichigroup.name, asset.whichigroup);
      }
    });
  }

  const addRecent = (whichiId) => {
    const savedRecents = localStorage.getItem(`${profileData ? profileData.id : 'null'}whichiten-asset-recent`);
    const currentRecent = savedRecents ? JSON.parse(savedRecents) : [];

    const newRecent = currentRecent.filter(recent => recent !== whichiId);
    newRecent.push(whichiId);
    localStorage.setItem(`${profileData ? profileData.id : 'null'}whichiten-asset-recent`, JSON.stringify(newRecent.slice(-5)));
    setRecentAssets(newRecent.slice(-5));
  };

  const setAssetInfoFromAsset = (asset) => {
    setAssetInfoInput({
      ...asset,
      group: asset.whichigroup ? asset.whichigroup.name : '',
      type: asset.type || '',
      usage: asset.usage || '',
      servicePlan: asset.servicePlan || '',
      serialNumber: asset.serialNumber || '',
    });
  };

  const setGroupInfoFromGroup = (group) => {
    setGroupInfoInput({
      ...group,
    });
  };

  const loadAsset = (asset) => {
    setSelectedGroup(null);
    setShowMobileAssetList(false);
    setSelectedAsset(asset);
    setAssetInfoFromAsset(asset);
    getWhichiUsage({
      variables: {
        whichiId: asset.id,
        yearMonth: usageMonth.format('YYYY.MM'),
      },
    });
    addRecent(asset.id);
    // console.log(asset);
    if (asset.last_position) {
      createAssetMarker({ ...asset, ...asset.last_position }, asset.name);
    } else {
      console.log('에셋의 위치정보가 없습니다.');
    }
  };

  useEffect(() => {
    if (!isInitialized && profileData) {
      setInitialized(true);
      const currentRecentAssets = localStorage.getItem(`${profileData ? profileData.id : 'null'}whichiten-asset-recent`) ? JSON.parse(localStorage.getItem(`${profileData ? profileData.id : 'null'}whichiten-asset-recent`)) : [];

      setRecentAssets(currentRecentAssets);

      if (assets && assets.length) {
        if (currentRecentAssets && currentRecentAssets.length) {
          const foundLastAsset = assets.find(asset => asset.id === currentRecentAssets[currentRecentAssets.length - 1]);

          if (foundLastAsset) {
            const lastAssetFoundInAssets = assets && assets.find(asset => asset.id === foundLastAsset.id);
            console.log(foundLastAsset, lastAssetFoundInAssets);
            loadAsset(lastAssetFoundInAssets || foundLastAsset);
          }
        }
      }
    }
  });

  const loadGroup = (group) => {
    setSelectedAsset(null);
    setAssetMarkers([]);
    setSelectedGroup(group);
    setGroupInfoFromGroup(group);
  };

  useEffect(() => {
    if (isAssetUpdated && selectedAsset) {
      if (profileData.role === 'agent') {
        if (isAgentAssetUpdated) {
          const updatedAsset = agentAssets.find(asset => asset.id === selectedAsset.id);

          // console.log('UA', updatedAsset);

          if (updatedAsset) {
            loadAsset(updatedAsset);
            setIsAssetUpdated(false);
            setIsAgentAssetUpdated(false);
          }
        }
      } else {
        const updatedAsset = assets.find(asset => asset.id === selectedAsset.id);

        if (updatedAsset) {
          loadAsset(updatedAsset);
          setIsAssetUpdated(false);
        }
      }
    }

    if (isGroupUpdated && selectedGroup) {
      const updatedGroup = groups[selectedGroup.name];
      loadGroup(updatedGroup);
      setIsGroupUpdated(false);
    }
  });

  const getRecentAssets = () => recentAssets.map(whichiId => (agentAssets ? assets && assets.find(asset => asset.id === whichiId) || agentAssets.find(asset => asset.id === whichiId) : assets.find(asset => asset.id === whichiId))).filter(asset => asset).reverse();

  const mapAssets = innerAssets => innerAssets.map(asset => <AssetListItem selected={selectedAsset && selectedAsset.id === asset.id} onClick={onEditAsset || onEditGroup ? () => {} : () => loadAsset(asset)} variant="text">{asset.name}</AssetListItem>);

  const mapGroupedAssets = () => Object.keys(groupedAssets).sort((prev, next) => prev === 'Ungroup' && 1 || next === 'Ungroup' && -1 || 0).map((groupKey) => {
    const currentGroup = groups[groupKey] || {};
    if (!groupedAssets[groupKey] || !groupedAssets[groupKey].length) return;
    return (
      <InnerExpansionPanel>
        <ExpansionPanelSummary
          style={{ position: 'relative' }}
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {
            groupKey !== 'Ungroup'
            && (
            <IconButton
              style={{
                position: 'absolute', left: -24, color: 'white', padding: 4,
              }}
              onClick={onEditAsset || onEditGroup ? () => {} : (event) => {
                if (event.stopPropagation) {
                  event.stopPropagation();
                }

                loadGroup(groups[groupKey]);
              }}
            >
              <Settings />
            </IconButton>
            )
          }
          <GroupIcon style={{ color: 'white', fontWeight: 'bold' }} color={currentGroup.color}>{currentGroup && currentGroup.name ? currentGroup.name.slice(0, 1).toUpperCase() : ''}</GroupIcon>
          <Typography color="textSecondary" variant="body1">{groupKey}<GroupIcon color="white" style={{ color: 'black', marginLeft: 8, fontSize: '0.7rem' }}>+{groupedAssets[groupKey].length}</GroupIcon></Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {
              mapAssets(groupedAssets[groupKey])
          }
        </ExpansionPanelDetails>
      </InnerExpansionPanel>
    );
  });

  const startEditAsset = () => {
    setOnEditAsset(true);
  };
  const startEditGroup = () => {
    setOnEditGroup(true);
  };

  const saveOrQuitEditAsset = async (isQuit) => {
    if (isQuit) {
      setOnEditAsset(false);
      return setAssetInfoFromAsset(selectedAsset);
    }
    setIsAgentAssetUpdated(false);

    let innerSelectedGroup = null;

    if (assetInfoInput.group && assetInfoInput.group !== 'Ungroup') {
      innerSelectedGroup = groups[assetInfoInput.group].id;
    }

    await commitEditAsset({
      variables: {
        ...filterBlankToNull(assetInfoInput),
        whichigroup: innerSelectedGroup,
      },
      refetchQueries: () => ([{ query: GET_WHICHIS }, { query: GET_WHOLETREE_WHICHIS }]),
      awaitRefetchQueries: true,
    });

    if (profileData.role === 'agent') {
      setIsAgentAssetUpdated(true);
      const { data } = await refetchAllUsers(); // eslint-disable-line
      refineUWPipes(data); // eslint-disable-line
    }

    setOnEditAsset(false);
    setIsAssetUpdated(true);
  };

  const saveOrQuitEditGroup = async (isQuit) => {
    if (isQuit) {
      setOnEditGroup(false);
      return setGroupInfoFromGroup(selectedGroup);
    }

    await commitEditGroup({
      variables: {
        ...filterBlankToNull(groupInfoInput),
      },
      refetchQueries: () => ([{ query: GET_WHICHIS }]),
      awaitRefetchQueries: true,
    });

    setOnEditGroup(false);
    setIsGroupUpdated(true);
  };

  const onChangeAssetInfoInput = (newValue, key) => {
    if (key === 'group' && newValue === 'CREATE_NEW_GROUP') {
      setOpenNewGroup(true);
    }
    setAssetInfoInput({
      ...assetInfoInput,
      [key]: newValue,
    });
  };

  const onChangeGroupInfoInput = (newValue, key) => {
    // console.log(newValue, key);
    setGroupInfoInput({
      ...groupInfoInput,
      [key]: newValue,
    });
  };

  const addAndSelectGroup = async () => {
    if (!groupNameInput) return;

    try {
      const addResult = await commitAddGroup({
        variables: {
          name: groupNameInput,
          imei: selectedAsset.imei,
        },
        refetchQueries: [{ query: GET_WHICHI_GROUPS }, { query: GET_WHICHIS }],
        awaitRefetchQueries: true,
      });

      if (addResult.data.AddWhichiGroup.ok) {
        onChangeAssetInfoInput(groupNameInput, 'group');
      }

      setOpenNewGroup(false);
    } catch (err) {
      console.log(err);

      if (err.message.includes('ER_DUP_ENTRY')) {
        onChangeAssetInfoInput(groupNameInput, 'group');
        setOpenNewGroup(false);
      }
    }
  };

  useEffect(() => {
    if (assets && !isPreSelected && query.get('selected')) {
      setIsPreSelected(true);
      const preSelectAsset = assets.find(asset => asset.id === parseFloat(query.get('selected')));
      if (preSelectAsset) {
        loadAsset(preSelectAsset);
      }
    }
  });

  const mapIconSelects = onSelect => allAssetIcons.map(assetIcon => (
    <div style={{ display: 'flex', justifyContent: 'center' }} onClick={() => onSelect(assetIcon.name)}>
      <AssetSVG imgOnly icon={assetIcon.name} color={groupInfoInput && groupInfoInput.color || selectedAsset && selectedAsset.whichigroup && selectedAsset.whichigroup.color} />
    </div>
  ));

  const deleteSelectedGroup = async () => {
    if (!selectedGroup) return;

    await commitDeleteGroup({
      variables: {
        whichiGroupId: selectedGroup.id,
      },
      refetchQueries: [{ query: GET_WHICHIS }, { query: GET_WHICHI_GROUPS }],
      awaitRefetchQueries: true,
    });

    setSelectedGroup(null);
    setOnDeleteGroup(null);
  };

  // Use another user list query for administrator users.

  const [isAdminInitialized, setIsAdminInitialized] = useState(false);
  const [allUserList, setAllUserList] = useState(null);

  const refineUWPipes = (data) => {
    // console.log('GETALLUSER', data);
    const uwPipes = [];
    const findUWPipe = (user) => {
      if (user.uwpipe && user.uwpipe.length) {
        uwPipes.push(...user.uwpipe);
      }

      if (user.usersAsUser && user.usersAsUser.length) {
        user.usersAsUser.forEach(innerUser => findUWPipe(innerUser));
      }
    };
    if (data.GetUsers.ok && data.GetUsers.users) {
      setAllUserList(data.GetUsers.users);
      if (data.GetUsers.users.length) {
        data.GetUsers.users.map(user => findUWPipe(user));

        setAgentAssets(uwPipes);
        setIsAgentAssetUpdated(true);
        if (uwPipes.length) {
          if (recentAssets && recentAssets.length) {
            // console.log('INRECENT', recentAssets, uwPipes);
            const foundLastAsset = uwPipes.find(asset => asset.id === recentAssets[recentAssets.length - 1]);

            if (foundLastAsset) {
              const lastAssetFoundInAssets = assets && assets.find(asset => asset.id === foundLastAsset.id);
              loadAsset(lastAssetFoundInAssets || foundLastAsset);
            }
          }
        }
      }
    } else {
      alert('Failed to fetch user list. Please check server status or connection status.');
    }
  };

  const [getAllUsers, { refetch: refetchAllUsers }] = useLazyQuery(GET_WHOLETREE_WHICHIS, {
    onCompleted: refineUWPipes,
    fetchPolicy: 'no-cache',
  });

  const initializeAllUserData = async () => {
    // console.log('EFFECT', isAdminInitialized);
    if (isAdminInitialized) return;

    setIsAdminInitialized(true);
    // console.log('INVOKE: getAllUsers');
    await getAllUsers();
  };

  useEffect(() => {
    if (profileData && ['administrator', 'agent'].includes(profileData.role)) {
      initializeAllUserData();
    }
  });

  const loadAdminAsset = (targetAsset) => {
    const foundOriginAsset = assets.find(asset => asset.id === targetAsset.id);

    if (foundOriginAsset) return loadAsset(foundOriginAsset);

    return loadAsset(targetAsset);
  };

  const mapUsersAsUser = (usersAsUser, level, noFilter) => {
    // console.log(usersAsUser);
    if (assetSearchKeyword && !noFilter) {
      const uwPipes = [];

      const findUwPipes = (user) => {
        if (user.uwpipe && user.uwpipe.length) {
          uwPipes.push(...user.uwpipe);
        }

        if (user.usersAsUser && user.usersAsUser.length) {
          user.usersAsUser.forEach(findUwPipes);
        }
      };
      usersAsUser.forEach(findUwPipes);
      return uwPipes.filter(whichi => whichi.name && whichi.name.includes(assetSearchKeyword)).map(whichi => <AssetListItem selected={selectedAsset && selectedAsset.id === whichi.id} onClick={onEditAsset ? () => {} : () => loadAdminAsset(whichi)} variant="text">{whichi.name}</AssetListItem>);
    }
    if (level > 1) {
      return usersAsUser.map((user) => {
        if (!user.usersAsUser || !user.usersAsUser.length) return null;
        if (level === 3) {
          let isLeafExist = false;

          user.usersAsUser.forEach((child) => {
            if (child.usersAsUser && child.usersAsUser.length) {
              isLeafExist = true;
            }
          });

          if (!isLeafExist) {
            return null;
          }
        }
        return (
          <AdminInnerExpansionPanel>
            <ExpansionPanelSummary
              style={{ position: 'relative' }}
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography color="textSecondary" variant="body1">{user.nickname || getFullName(user)}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              {
                mapUsersAsUser(user.usersAsUser, level - 1)
              }
            </ExpansionPanelDetails>
          </AdminInnerExpansionPanel>
        );
      });
    }

    return usersAsUser.filter(user => user.uwpipe && user.uwpipe.length).map((assetUser) => {
      const currentUserWhichi = assetUser.uwpipe[0];

      return (
        <AssetListItem selected={selectedAsset && selectedAsset.id === currentUserWhichi.id} onClick={onEditAsset ? () => {} : () => loadAdminAsset(currentUserWhichi)} variant="text">{currentUserWhichi.name}</AssetListItem>
      );
    });
  };

  // END OF CODES FOR ADMINISTRATOR USER LIST QUERY //

  const usageInnerData = usageData && usageData.GetMessageUseBytes;

  const usageMonthData = usageData && usageData.MonthlyUseBytes;

  const isMobile = useMediaQuery('(max-width: 500px)');

  const openUpload = () => {
    try {
      hiddenUploadInputRef.current.click();
    } catch (err) {
      // console.log(err);
    }
  };

  const [uploadFile] = useMutation(UPLOAD_FILE);

  const deleteProfile = async (event) => {
    // console.log(event.target.files);


    // console.log('UPLOAD', uploadable);

    event.target.files = null; // eslint-disable-line

    try {
      await uploadFile({
        variables: {
          file: null,
          ...(
            selectedAsset
            && {
              whichiId: selectedAsset.id,
            }
            || selectedGroup
            && {
              whichigroupId: selectedGroup.id,
            }
          ),
        },
        refetchQueries: [{ query: GET_WHICHIS }],
        awaitRefetchQueries: true,
      });
      if (selectedAsset) {
        setIsAssetUpdated(true);
      } else {
        setIsGroupUpdated(true);
      }

      // console.log(result);
    } catch (err) {
      // console.log(err);
    }
  };

  const uploadProfile = async (event) => {
    // console.log(event.target.files);

    const uploadable = event.target.files[0];

    // console.log('UPLOAD', uploadable);

    event.target.files = null; // eslint-disable-line

    try {
      const result = await uploadFile({
        variables: {
          file: uploadable,
          ...(
            selectedAsset
            && {
              whichiId: selectedAsset.id,
            }
            || selectedGroup
            && {
              whichigroupId: selectedGroup.id,
            }
          ),
        },
        refetchQueries: [{ query: GET_WHICHIS }],
        awaitRefetchQueries: true,
      });
      if (selectedAsset) {
        setIsAssetUpdated(true);
      } else {
        setIsGroupUpdated(true);
      }

      // console.log(result);
    } catch (err) {
      // console.log(err);
    }
  };

  return (
    <main
      className={clsx(classes.content, {
        [classes.contentShift]: open,
      })}
      style={isRightAssetEnabled && !isMobile ? {} : { paddingRight: 0, position: 'relative' }}
    >
      <Contents isMobile={isMobile}>
        {
          isMobile && (
            <>
              <MobileAssetListSearch onClick={() => setShowMobileAssetList(true)}>
                <Search />
                <InputBase
                  value={assetSearchKeyword}
                  onChange={(event) => {
                    setAssetSearchKeyword(event.target.value);
                  }}
                />
                {
                  showMobileAssetList && (
                  <Close
                    onClick={(event) => {
                      preventDefault(event);
                      setShowMobileAssetList(false);
                    }}
                  />
                  )
                  || <List />
                }
              </MobileAssetListSearch>
              {
                showMobileAssetList && (
                  <MobileAssetList>
                    {
                      allUserList
                      && allUserList.map(admin => (
                        <AdminOuterExpansionPanel defaultExpanded>
                          <ExpansionPanelSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography color="textSecondary" variant="h6">{!assetSearchKeyword && 'User List' || 'Search Result'}</Typography>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            {
                              admin.usersAsUser && admin.usersAsUser.length
                              && mapUsersAsUser(admin.usersAsUser, profileData.role === 'administrator' ? 3 : 2) || false
                            }
                          </ExpansionPanelDetails>
                        </AdminOuterExpansionPanel>
                      ))
                      || profileData && !['administrator', 'agent'].includes(profileData.role)
                      && (
                        <OuterExpansionPanel defaultExpanded style={{ margin: 0 }}>
                          <ExpansionPanelSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography color="textSecondary" variant="h6">All</Typography>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            {
                              loading
                              && (
                              <div style={{
                                width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 144,
                              }}
                              >
                                <CircularProgress style={{ color: 'white' }} />
                              </div>
                              )
                              || mapGroupedAssets()
                            }
                          </ExpansionPanelDetails>
                        </OuterExpansionPanel>
                      )
                      || (
                        <div style={{
                          width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 144,
                        }}
                        >
                          <CircularProgress style={{ color: 'white' }} />
                        </div>
                      )
                    }
                    <OuterExpansionPanel style={{ margin: 0 }} defaultExpanded>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography color="textSecondary" variant="h6">Recent</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        {
                          !loading
                          && mapAssets(getRecentAssets())
                        }
                      </ExpansionPanelDetails>
                    </OuterExpansionPanel>
                  </MobileAssetList>
                )
              }
            </>
          ) || (
            <ContentLeft>
              {
                allUserList
                && allUserList.map(admin => (
                  <AdminOuterExpansionPanel defaultExpanded>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography color="textSecondary" variant="h6">{!assetSearchKeyword && 'User List' || 'Search Result'}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      {
                        admin.usersAsUser && admin.usersAsUser.length
                        && mapUsersAsUser(admin.usersAsUser, profileData.role === 'administrator' ? 3 : 2) || false
                      }
                    </ExpansionPanelDetails>
                  </AdminOuterExpansionPanel>
                ))
                || profileData && !['administrator', 'agent'].includes(profileData.role)
                && (
                  <OuterExpansionPanel defaultExpanded>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography color="textSecondary" variant="h6">All</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      {
                        loading
                        && (
                        <div style={{
                          width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 144,
                        }}
                        >
                          <CircularProgress style={{ color: 'white' }} />
                        </div>
                        )
                        || mapGroupedAssets()
                      }
                    </ExpansionPanelDetails>
                  </OuterExpansionPanel>
                )
                || (
                  <div style={{
                    width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 144,
                  }}
                  >
                    <CircularProgress style={{ color: 'white' }} />
                  </div>
                )
              }
              <OuterExpansionPanel defaultExpanded>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography color="textSecondary" variant="h6">Recent</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  {
                    !loading
                    && mapAssets(getRecentAssets())
                  }
                </ExpansionPanelDetails>
              </OuterExpansionPanel>
            </ContentLeft>
          )
        }
        <ContentRight>
          <RightInner>
            <div style={{ height: isMobile && '240px' || '500px', width: '100%' }}>
              {
                userAccess === 'all'
                && (
                  <KeepAliveGoogleMap
                    onChange={({ center: newCenter, zoom: newZoom }) => setMapPosition({ center: newCenter, zoom: newZoom })}
                    center={
                      center
                    }
                    zoom={
                      zoom
                    }
                    options={createMapOptions}
                  >
                    {
                      assetMarkers.map(marker => (
                        <AssetComponent
                          style={{ zIndex: 100 }}
                          lat={marker.lat}
                          id={marker.id}
                          size={mapOption.assetSize}
                          lng={marker.lng}
                          course={marker.course}
                          name={marker.name}
                          icon={marker.icon}
                          group={marker.whichigroup}
                          marker={marker}
                          color={marker.whichigroup && marker.whichigroup.color}
                        />
                      ))
                    }
                  </KeepAliveGoogleMap>
                )
                || (
                  <div />
                )
              }
            </div>
            <Blinder isMobile={isMobile} />
            {
              selectedAsset
              && (
                <>
                  <SelectedAssetInfo isMobile={isMobile}>
                    <div>
                      <div>
                        <p>Asset Name</p>
                        {
                          onEditAsset
                          && <SquareTextField onChange={event => onChangeAssetInfoInput(event.target.value, 'name')} value={assetInfoInput.name} />
                          || <p>{selectedAsset.name}</p>
                        }
                      </div>
                      <div>
                        <p>Group</p>
                        {
                          onEditAsset
                          && configAccess === 'all'
                          && (
                          <SquareSelect
                            displayEmpty
                            value={assetInfoInput.group}
                            onChange={event => onChangeAssetInfoInput(event.target.value, 'group')}
                            color
                          >
                            <MenuItem value="">Ungroup</MenuItem>
                            {
                              Object.keys(groupedAssets).filter(groupKey => groupKey !== 'Ungroup' && (groupedAssets[groupKey].length || assetInfoInput.group === groupKey)).map(groupKey => (
                                <MenuItem value={groupKey}>{groupKey}</MenuItem>
                              ))
                            }
                            <MenuItem value="CREATE_NEW_GROUP">Add New...</MenuItem>
                          </SquareSelect>
                          )
                          || <p><GroupIcon color={selectedAsset.whichigroup && selectedAsset.whichigroup.color}>{selectedAsset.whichigroup && firstUpperLetter(selectedAsset.whichigroup.name)}</GroupIcon>{selectedAsset.whichigroup && selectedAsset.whichigroup.name || 'Ungroup'}</p>
                        }
                      </div>
                      <div>
                        <p>Type</p>
                        {
                          onEditAsset
                          && configAccess === 'all'
                          && (
                          <SquareSelect
                            displayEmpty
                            value={assetInfoInput.type}
                            color
                            onChange={event => onChangeAssetInfoInput(event.target.value, 'type')}
                          >
                            <MenuItem value="">None</MenuItem>
                            {
                              assetInfoTypes.map(type => (
                                <MenuItem value={type}>{type}</MenuItem>
                              ))
                            }
                          </SquareSelect>
                          )
                          || <p>{selectedAsset.type || '-'}</p>
                        }
                      </div>
                      <div>
                        <p>Usage</p>
                        {
                          onEditAsset
                          && configAccess === 'all'
                          && (
                          <SquareSelect
                            displayEmpty
                            value={assetInfoInput.usage}
                            color
                            onChange={event => onChangeAssetInfoInput(event.target.value, 'usage')}
                          >
                            <MenuItem value="">Select Usage</MenuItem>
                            {
                              (assetInfoUsage[assetInfoInput.type] || ['None']).map(usage => (
                                <MenuItem value={usage}>{usage}</MenuItem>
                              ))
                            }
                          </SquareSelect>
                          )
                          || <p>{selectedAsset.usage || '-'}</p>
                        }
                      </div>
                      <div>
                        <p>Pilot</p>
                        {
                          onEditAsset
                          && configAccess === 'all'
                          && <SquareTextField placeholder="Pilot" onChange={event => onChangeAssetInfoInput(event.target.value, 'pilot')} value={assetInfoInput.pilot} />
                          || <p>{selectedAsset.pilot || '-'}</p>
                        }
                      </div>
                      <div>
                        <p>Status</p>
                        <p>{selectedAsset.status && capitalize(selectedAsset.status) || '-'}</p>
                      </div>
                    </div>
                    <img alt="" style={{ backgroundImage: `url(${selectedAsset && selectedAsset.profilepicture && photoURL(selectedAsset.profilepicture) || TruckProfile})`, backgroundSize: '100% 100%' }} />
                    <div
                      style={{
                        width: 16, height: 16, position: 'absolute', top: 4, right: 4, borderRadius: 16, background: 'red', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center',
                      }}
                      onClick={deleteProfile}
                    >
                      <Close style={{ fontSize: '0.8rem' }} />
                    </div>
                    <AssetInfoEdits isMobile={isMobile}>
                      <span onClick={() => openUpload(true)}>
                        <CloudUpload />
                        Upload Picture
                      </span>
                      <span onClick={() => setOnAssetIconPick(true)}>
                        <Face />
                        Edit Icon
                      </span>
                      <span onClick={startEditAsset}>
                        <Edit />
                        Edit Asset
                      </span>
                    </AssetInfoEdits>
                  </SelectedAssetInfo>
                  <InformationBlock isMobile={isMobile}>
                    <span>
                      <span>
                      Information
                      </span>
                      {
                        onEditAsset
                        && <SquareTextField placeholder="Information" onChange={event => onChangeAssetInfoInput(event.target.value, 'information')} value={assetInfoInput.information} />
                        || selectedAsset.information || '-'
                      }
                    </span>
                  </InformationBlock>
                  <InformationBlock isMobile={isMobile}>
                    <span>
                      <span>
                      IMEI
                      </span>
                      {
                        onEditAsset && profileData.role !== 'customer'
                        && <SquareTextField placeholder="IMEI" onChange={event => onChangeAssetInfoInput(event.target.value, 'imei')} value={assetInfoInput.imei} />
                        || selectedAsset.imei || '-'
                      }
                    </span>
                  </InformationBlock>
                  <InformationBlock isMobile={isMobile}>
                    <span>
                      <span>
                      Serial Number
                      </span>
                      {
                        onEditAsset && profileData.role !== 'customer'
                        && <SquareTextField placeholder="Serial Number" onChange={event => onChangeAssetInfoInput(event.target.value, 'serialNumber')} value={assetInfoInput.serialNumber} />
                        || selectedAsset.serialNumber || '-'
                      }
                    </span>
                  </InformationBlock>
                  <InformationBlock isMobile={isMobile}>
                    <span>
                      <span>
                      Service Plan
                      </span>
                      {
                        onEditAsset && profileData.role !== 'customer'
                        && (
                        <SquareSelect
                          displayEmpty
                          value={assetInfoInput.servicePlan}
                          color
                          onChange={event => onChangeAssetInfoInput(event.target.value, 'servicePlan')}
                        >
                          <MenuItem value="">None</MenuItem>
                          {
                            servicesPlans.map(servicePlan => (
                              <MenuItem value={servicePlan}>{servicePlan}</MenuItem>
                            ))
                          }
                        </SquareSelect>
                        )
                        || selectedAsset.servicePlan || 'Not Selected'
                      }
                    </span>
                  </InformationBlock>
                  <InformationBlock isMobile={isMobile}>
                    <span>
                      <span>
                      Data Usage
                      </span>
                      {
                        !usageLoading && usageInnerData
                      && <>{(usageMonthData.tx || 0) + (usageMonthData.rx || 0)} Bytes (TX: {usageMonthData.tx ? `${usageMonthData.tx} Bytes` : '-'} | RX: {usageMonthData.rx ? `${usageMonthData.rx} Bytes` : '-'})</>
                        || <CircularProgress size={24} />
                      }

                      <DatePickerThemed
                        style={{ marginLeft: 16 }}
                        openTo="year"
                        views={['year', 'month']}
                        disableFuture
                        error={false}
                        helperText=""
                        value={usageMonth.format('YYYY/MM')}
                        onChange={(date) => {
                          setUsageMonth(moment(date));
                          getWhichiUsage({
                            variables: {
                              whichiId: selectedAsset.id,
                              yearMonth: date.format('YYYY.MM'),
                            },
                          });
                        }}
                      />
                    </span>
                  </InformationBlock>
                  {
                    onEditAsset
                    && (
                    <InformationBlock style={{ borderBottom: 'none' }}>
                      <Button disabled={editAssetLoading} onClick={() => saveOrQuitEditAsset(true)} style={{ marginRight: 24 }} variant="text" color="secondary">
                        <Cancel style={{ marginRight: 12 }} /> Discard Changes
                      </Button>
                      <Button disabled={editAssetLoading} onClick={() => saveOrQuitEditAsset(false)} variant="text" color="secondary">
                        <Check style={{ marginRight: 12 }} /> Save Changes
                      </Button>
                    </InformationBlock>
                    )
                  }
                </>
              )
              || selectedGroup
              && (
                <>
                  <SelectedAssetInfo>
                    <div>
                      <div>
                        <p>Group Name</p>
                        {
                          onEditGroup
                          && <SquareTextField onChange={event => onChangeGroupInfoInput(event.target.value, 'name')} value={groupInfoInput.name} />
                          || <p>{selectedGroup.name}</p>
                        }
                      </div>
                      <div>
                        <p>Group Indicator</p>
                        {
                          onEditGroup
                          && <p><GroupIcon color={groupInfoInput.color}>{groupInfoInput.name.slice(0, 1).toUpperCase()}</GroupIcon></p>
                          || <p><GroupIcon color={selectedGroup.color}>{selectedGroup.name.slice(0, 1).toUpperCase()}</GroupIcon></p>
                        }
                      </div>
                      <div>
                        <p>Group Color</p>
                        {
                          onEditGroup
                          && <p onClick={() => setOnColorPick(true)} style={{ cursor: 'pointer' }}><GroupIcon color={groupInfoInput.color} /><Edit /></p>
                          || <p><GroupIcon color={selectedGroup.color} /></p>
                        }
                      </div>
                      <div>
                        <p>Group Alphabet</p>
                        <p>{
                          onEditGroup
                          && firstUpperLetter(groupInfoInput.name)
                          || selectedGroup.name.slice(0, 1).toUpperCase()
                        }
                        </p>
                      </div>
                      <div>
                        <p>Group Default Map Icon</p>
                        {
                          onEditGroup
                          && (
                          <p
                            onClick={() => setOnGroupIconPick(true)}
                            style={{
                              cursor: 'pointer', display: 'flex', alignItems: 'center', marginTop: 12,
                            }}
                          ><AssetSVG imgOnly color={groupInfoInput.color} style={{ height: 32, width: 24 }} icon={groupInfoInput.icon} /><Edit />
                          </p>
                          )
                          || <p><AssetSVG imgOnly color={selectedGroup.color} style={{ height: 32, width: 24, marginTop: 24 }} icon={selectedGroup.icon} /></p>
                        }
                      </div>
                    </div>
                    <img src="" alt="" style={{ backgroundImage: `url(${selectedGroup && selectedGroup.profilePhoto && photoURL(selectedGroup.profilePhoto) || TruckProfile})`, backgroundSize: '100% 100%' }} />
                    <div
                      style={{
                        width: 16, height: 16, position: 'absolute', top: 4, right: 4, borderRadius: 16, background: 'red', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center',
                      }}
                      onClick={deleteProfile}
                    >
                      <Close style={{ fontSize: '0.8rem' }} />
                    </div>
                    <AssetInfoEdits isMobile={isMobile}>
                      <span onClick={() => openUpload(true)}>
                        <CloudUpload />
                        Upload Picture
                      </span>
                      <span onClick={() => setOnDeleteGroup(true)}>
                        <Delete />
                        Delete Group
                      </span>
                      <span onClick={startEditGroup}>
                        <Edit />
                        Edit Group
                      </span>
                    </AssetInfoEdits>
                  </SelectedAssetInfo>
                  <InformationBlock isMobile={isMobile}>
                    <span>
                      <span>
                      Manager
                      </span>
                      {
                        onEditGroup
                        && <SquareTextField placeholder="Manager" onChange={event => onChangeGroupInfoInput(event.target.value, 'manager')} value={groupInfoInput.manager} />
                        || selectedGroup.manager || '-'
                      }
                    </span>
                  </InformationBlock>
                  <InformationBlock isMobile={isMobile}>
                    <span>
                      <span>
                      Pilots
                      </span>
                      {
                        onEditGroup
                        && <SquareTextField placeholder="Pilots" onChange={event => onChangeGroupInfoInput(event.target.value, 'pilots')} value={groupInfoInput.pilots} />
                        || selectedGroup.pilots || '-'
                      }
                      {
                        !onEditGroup && <ArrowForwardIos />
                      }
                    </span>
                  </InformationBlock>
                  {
                    onEditGroup
                    && (
                    <InformationBlock style={{ borderBottom: 'none' }}>
                      <Button disabled={editGroupLoading} onClick={() => saveOrQuitEditGroup(true)} style={{ marginRight: 24 }} variant="text" color="secondary">
                        <Cancel style={{ marginRight: 12 }} /> Discard Changes
                      </Button>
                      <Button disabled={editGroupLoading} onClick={() => saveOrQuitEditGroup(false)} variant="text" color="secondary">
                        <Check style={{ marginRight: 12 }} /> Save Changes
                      </Button>
                    </InformationBlock>
                    )
                  }
                </>
              )
              || (
              <div style={{
                width: '100%', margin: 48, height: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: 1,
              }}
              >
                <Pageview style={{ fontSize: 48, color: 'white' }} />
                <Typography color="textSecondary">Please select the asset from left navigation.</Typography>
              </div>
              )
            }
          </RightInner>
        </ContentRight>
      </Contents>
      <Dialog
        open={onColorPick}
        onClose={() => setOnColorPick(false)}
      >
        <DialogTitle>Pick Color</DialogTitle>
        <StyledColorPicker
          color={groupInfoInput.color || '#7F7F7F'}
          onChange={(color) => {
            if (color) {
              onChangeGroupInfoInput(color.hex, 'color');
              setOnColorPick(false);
            }
          }}
        />
      </Dialog>
      <Dialog
        open={onGroupIconPick}
        onClose={() => setOnGroupIconPick(false)}
      >
        <DialogTitle>Pick Icon</DialogTitle>
        <IconSelector>
          {
            mapIconSelects((icon) => {
              onChangeGroupInfoInput(icon, 'icon');
              setOnGroupIconPick(false);
            })
          }
        </IconSelector>
      </Dialog>
      <Dialog
        open={onAssetIconPick}
        onClose={() => setOnAssetIconPick(false)}
      >
        <DialogTitle>Pick Icon</DialogTitle>
        <IconSelector>
          {
            mapIconSelects(async (icon) => {
              setOnAssetIconPick('loading');
              await commitEditAsset({
                variables: {
                  imei: selectedAsset.imei,
                  icon,
                },
                refetchQueries: () => ([{ query: GET_WHICHIS }]),
                awaitRefetchQueries: true,
              });

              setAssetMarkers([{ ...assetMarkers[0], icon }]);
              setOnEditAsset(false);
              setIsAssetUpdated(true);
              setOnAssetIconPick(false);
            })
          }
        </IconSelector>
        {
          onAssetIconPick === 'loading'
          && (
            <DialogActions>
              <CircularProgress size={24} />
            </DialogActions>
          )
          || (
            <DialogActions>
              <Button onClick={() => setOnAssetIconPick(false)} color="primary">
                Cancel
              </Button>
              <Button
                onClick={async () => {
                  setOnAssetIconPick('loading');
                  await commitEditAsset({
                    variables: {
                      imei: selectedAsset.imei,
                      icon: null,
                    },
                    refetchQueries: () => ([{ query: GET_WHICHIS }]),
                    awaitRefetchQueries: true,
                  });

                  setAssetMarkers([{ ...assetMarkers[0], icon: 'null' }]);
                  setOnEditAsset(false);
                  setIsAssetUpdated(true);
                  setOnAssetIconPick(false);
                }}
                color="secondary"
              >
                  Use Default
              </Button>
            </DialogActions>
          )
        }
      </Dialog>
      <Dialog open={openNewGroup} onClose={addGroupLoading ? () => {} : () => setOpenNewGroup(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create new group</DialogTitle>
        <DialogContent>
          <Typography>
            Please enter the name of new group.
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            value={groupNameInput}
            onChange={event => setGroupNameInput(event.target.value)}
            id="name"
            label="Group Name"
            type="email"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={addGroupLoading ? () => {} : () => setOpenNewGroup(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={addGroupLoading ? () => {} : addAndSelectGroup} color="primary">
            {
              addGroupLoading
              && <CircularProgress size={24} />
              || 'Apply'
            }
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={onDeleteGroup} onClose={deleteGroupLoading ? () => {} : () => setOnDeleteGroup(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Delete group</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete the group {selectedGroup && selectedGroup.name}?<br />
            Once you confirm the deletion, it cannot be reversed.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteGroupLoading ? () => {} : () => setOnDeleteGroup(false)}>
            Cancel
          </Button>
          <Button onClick={deleteGroupLoading ? () => {} : deleteSelectedGroup} color="secondary">
            {
              deleteGroupLoading
              && <CircularProgress size={24} />
              || 'Delete'
            }
          </Button>
        </DialogActions>
      </Dialog>
      <HiddenInput ref={hiddenUploadInputRef} onChange={uploadProfile} accept=".png, .jpg, .jpeg" className="hidden" type="file" id="logoUploadInput" />
    </main>
  );
}
