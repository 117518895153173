import gql from 'graphql-tag';

export const EDIT_WHICH = gql`
  mutation EditWhichi(
    $imei: String!
    $name: String 
    $pilot: String
    $type: String
    $usage: String
    $status: String
    $whichigroup: Int
    $icon: String
    $information: String
    $mission: String
    $activity: String
    $destination: String
    $onMap: Boolean
    $servicePlan: String
    $serialNumber: String
  ) {
    EditWhichi(
      imei: $imei
      name: $name
      pilot: $pilot
      type: $type
      usage: $usage
      status: $status
      information: $information
      mission: $mission
      activity: $activity
      destination: $destination
      onMap: $onMap
      whichigroup: $whichigroup
      servicePlan: $servicePlan
      serialNumber: $serialNumber
      icon: $icon
    ) {
      ok
      error
    }
  }
`;

export const GET_WHICHIS = gql`
{
  GetUsersWhichis {
    whichis {
      id
      imei
      name
      whichigroup {
        id
        name
        icon
        color
        pilots
        manager
                profilePhoto
      }
      placeUpdatedAt
      pilot
      icon
      type
      usage
      status
      information
      mission
      activity
      destination
      servicePlan
      profilepicture
      serialNumber
      last_position {
        lat
        lng
        speed
        course
        utc
        alt
        kind
        createdAt
      }
      last_message {
        createdAt
        message_body
        txrx
      }
      updatedAt
    }
  }
}
`;

export const GET_WHICHI_GROUPS = gql`
  {
    GetMyWhichiGroups {
      ok
      error
      whichigroups {
        id
        name
        icon
        color
        manager
        profilePhoto
        pilots
        createdAt
      }
    }
  }
`;

export const GET_WHICHI_MESSAGES = gql`
  query GetWhichMessages($whichiIds: [Int]) {
    GetMessages(whichiIds: $whichiIds, kind: ["message_tx", "message_rx"]) {
      messages {
        id
        txrx
        message_body
        payload_length
        createdAt
      }
    }
  }
`;

export const SEND_WHICHI_MESSAGE = gql`
  mutation SendWhichiMessage($imei: String!, $message_body: String!) {
    AddTxMessage(imei: $imei, message_body: $message_body)
    {
      ok
      error
    }
  }
`;

export const GET_WHICHI_LOCATIONS = gql`
  query getLocations($whichiId: Int!, $from: Date, $to: Date) {
    GetMyPlaces(whichiId: $whichiId, from: $from, to: $to) {
      ok
      error
      places {
        id
        utc
        lat
        lng
        alt
        speed
        course
        imei
        kind
        createdAt
      }
    }
  }
`;

export const EDIT_WHICHI_GROUP = gql`
  mutation editWhichiGroup (
    $id: Int!
    $name: String
    $icon: String
    $color: String
    $manager: String
    $pilots: String
  ) {
    EditWhichiGroup (
      id: $id
      name: $name
      icon: $icon
      color: $color
      manager: $manager
      pilots: $pilots
    ) {
      ok
      error
    }
  }
`;

export const GET_WHOLETREE_WHICHIS = gql`
  query {
    GetUsers(wholeTree:true){
      ok
      error
      users{
        id
        userid
        nickname
        firstName
        lastName
        role
        usersAsUser{
          nickname
          role
          firstName
          lastName
          usersAsUser{
            nickname
            role
          firstName
          lastName
            uwpipe{
              id
              imei
              name
              whichigroup {
                id
                name
                icon
                color
                pilots
                manager
                profilePhoto
              }
              placeUpdatedAt
              pilot
              icon
              type
              usage
              status
              information
              mission
              activity
              destination
              servicePlan
              profilepicture
              serialNumber
              last_position {
                lat
                lng
                speed
                course
                utc
                alt
                kind
                createdAt
              }
              last_message {
                createdAt
                message_body
                txrx
              }
              updatedAt
            }
            usersAsUser{
              nickname
              role
              firstName
              lastName
              uwpipe{
                id
                imei
                name
                whichigroup {
                  id
                  name
                  icon
                  color
                  pilots
                  manager
                profilePhoto
                }
                placeUpdatedAt
                pilot
                icon
                type
                usage
                status
                information
                mission
                activity
                destination
                servicePlan
                profilepicture
                serialNumber
                last_position {
                  lat
                  lng
                  speed
                  course
                  utc
                  alt
                  createdAt
                  kind
                }
                last_message {
                  createdAt
                  message_body
                  txrx
                }
                updatedAt
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_WHICHI_REPORT_INFO = gql`
  query getWhichiReports (
    $whichiId: Int!
    $whichiIds: [Int]!
    $from: Date
    $to: Date
  ) {
    GetDailyDistance (
      whichiId: $whichiId
      from: $from
      to: $to
    ) {
      ok
      error
      kmsum
      feetsum
      knotsum
      speedavg
      resultArr {
        km
        feet
        knot
        speed
        date
      }
    }
    GetMyPlaces: GetMessages(
      whichiIds: $whichiIds
      from: $from
      place: true
      io_port_status: true
      kind: ["place_rx_ask"]
      to: $to
    ) {
      ok
      error
      messages {
        place {
          lat
          lng
          utc
          alt
          speed
          course
          imei
          createdAt
        }
        ips {
          port_num
          out_status
          io_status
        }
        createdAt
      }
    }
    GetMessages (
      whichiIds: $whichiIds
      kind: ["report_rx_ask", "place_rx_poll", "distress_tx_set", "distress_rx", "distress_rx_on", "distress_rx_off", "ioport_tx_ask", "poll_tx_ask", "report_tx_ask", "report_tx_set", "poweron_rx", "message_tx", "message_rx", "user_active", "user_inactive", "ioport_rx_success", "report_rx_success", "reportsioport_tx_set", "reportsioport_rx_set", "place_rx_ask"]
      from: $from
      to: $to
    ) {
      ok
      error
      messages {
        id
        kind
        createdAt
        message_body
      }
    }
  }
`;

export const SAVE_ASSET_SETTINGS = gql`
  mutation SAVE_ASSET_SETTINGS (
    $imei: String!
    $report_masking_bit_1: String!
    $period_time_1: String!
    $io_masking_bit_1: String!
    $report_masking_bit_2: String!
    $period_time_2: String!
    $io_masking_bit_2: String!
    $report_masking_bit_3: String!
    $period_time_3: String!
    $io_masking_bit_3: String!
    $report_masking_bit_4: String!
    $period_time_4: String!
    $io_masking_bit_4: String!
    $IOPort1_IO_status: String
    $IOPort1_out_status: String
    $IOPort2_IO_status: String
    $IOPort2_out_status: String
    $IOPort3_IO_status: String
    $IOPort3_out_status: String
    $IOPort4_IO_status: String
    $IOPort4_out_status: String
  ) {
    AddTxReportsIoPortSet(
      imei: $imei
      report_masking_bit_1: $report_masking_bit_1
      period_time_1: $period_time_1
      io_masking_bit_1: $io_masking_bit_1
      report_masking_bit_2: $report_masking_bit_2
      period_time_2: $period_time_2
      io_masking_bit_2: $io_masking_bit_2
      report_masking_bit_3: $report_masking_bit_3
      period_time_3: $period_time_3
      io_masking_bit_3: $io_masking_bit_3
      report_masking_bit_4: $report_masking_bit_4
      period_time_4: $period_time_4
      io_masking_bit_4: $io_masking_bit_4
      IOPort1_IO_status: $IOPort1_IO_status
      IOPort1_out_status: $IOPort1_out_status
      IOPort2_IO_status: $IOPort2_IO_status
      IOPort2_out_status: $IOPort2_out_status
      IOPort3_IO_status: $IOPort3_IO_status
      IOPort3_out_status: $IOPort3_out_status
      IOPort4_IO_status: $IOPort4_IO_status
      IOPort4_out_status: $IOPort4_out_status
      ) {
      ok
      error
    }
  }
`;

export const SAVE_IOPORT_SETTINGS = gql`
  mutation SAVE_IOPORT (
    $imei: String!
    $IOPort1_IO_status: String!
    $IOPort1_out_status: String!
    $IOPort2_IO_status: String!
    $IOPort2_out_status: String!
    $IOPort3_IO_status: String!
    $IOPort3_out_status: String!
    $IOPort4_IO_status: String!
    $IOPort4_out_status: String!
  ) {
    AddTxIoPort(
      imei: $imei
      IOPort1_IO_status: $IOPort1_IO_status
      IOPort1_out_status: $IOPort1_out_status
      IOPort2_IO_status: $IOPort2_IO_status
      IOPort2_out_status: $IOPort2_out_status
      IOPort3_IO_status: $IOPort3_IO_status
      IOPort3_out_status: $IOPort3_out_status
      IOPort4_IO_status: $IOPort4_IO_status
      IOPort4_out_status: $IOPort4_out_status
    ) {
      ok
      error
    }
  }
`;

export const GET_RECENT_SETTINGS = gql`
  query GET_RECENT_SETTTINGS (
    $imei: String!
  ) {
    GetRecentestSetting(
      imei: $imei
    ) {
      ok
      error
      recentestSetting {
        report1_enable
        report1_utc_on
        report1_gps_on
        report1_alt_on
        report1_speed_on
        report1_course_on
        report1_period_time
        report1_io1_on
        report1_io2_on
        report1_io3_on
        report1_io4_on
        report2_enable
        report2_utc_on
        report2_gps_on
        report2_alt_on
        report2_speed_on
        report2_course_on
        report2_period_time
        report2_io1_on
        report2_io2_on
        report2_io3_on
        report2_io4_on
        report3_enable
        report3_utc_on
        report3_gps_on
        report3_alt_on
        report3_speed_on
        report3_course_on
        report3_period_time
        report3_io1_on
        report3_io2_on
        report3_io3_on
        report3_io4_on
        report4_enable
        report4_utc_on
        report4_gps_on
        report4_alt_on
        report4_speed_on
        report4_course_on
        report4_period_time
        report4_io1_on
        report4_io2_on
        report4_io3_on
        report4_io4_on
        port1_out_status
        port1_io_status
        port1_ADC
        port2_out_status
        port2_io_status
        port2_ADC
        port3_out_status
        port3_io_status
        port3_ADC
        port4_out_status
        port4_io_status
        port4_ADC
        createdAt
        updatedAt
      }
    }
  }
`;
