import styled from 'styled-components';
import { Input } from '@material-ui/core';

export const SquareTextField = styled(Input)`
  height: 24px;
  margin-top: 0;
  font-size: 1em;
  color: white;
  width: ${props => props.width || 'calc(100% - 24px)'};

  &:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid rgba(255, 255, 255, 0.85);
  }

  &:before {
    border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  }

  &:after {
    border-bottom: 2px solid white;
  }
`;

export const FilterWrapper = styled.div`
width: calc(100% + 48px);
margin-left: -24px;
display: flex;
align-items: center;
padding: 8px 8px;
color: #fff !important;

> div {
  color: #fff
}
`;

export const HiddenInput = styled.input`
  position: fixed;
  top: -1000px;
  right: -1000px;
`;
