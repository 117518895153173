import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#000',
      contrastText: '#fff',
    },
    secondary: {
      main: '#06A1AF',
      contrastText: '#fff',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#0E0F1A',
    },
    text: {
      primary: '#000',
      secondary: '#fff',
    },
  },
  typography: {
    color: '#fff',
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#303030',
        padding: 10,
      },
    },
  },
});

export default theme;
