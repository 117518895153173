import React, { useEffect, useState } from 'react';
// import PointSVGCompf44336 from './assets/#f44336/Layer -3.png';
// import YachtSVGCompf44336 from './assets/#f44336/Layer 2.png';
// import Truck01SVGCompf44336 from './assets/#f44336/Layer -1.png';
// import Truck02SVGCompf44336 from './assets/#f44336/Layer -2.png';
// import AirplaneSVGCompf44336 from './assets/#f44336/Layer -6.png';
// import CargoshipSVGCompf44336 from './assets/#f44336/Layer -5.png';
// import HelicopterSVGCompf44336 from './assets/#f44336/Layer -4.png';
// import PointSVGCompe91e63 from './assets/#e91e63/Layer -3.png';
// import YachtSVGCompe91e63 from './assets/#e91e63/Layer 2.png';
// import Truck01SVGCompe91e63 from './assets/#e91e63/Layer -1.png';
// import Truck02SVGCompe91e63 from './assets/#e91e63/Layer -2.png';
// import AirplaneSVGCompe91e63 from './assets/#e91e63/Layer -6.png';
// import CargoshipSVGCompe91e63 from './assets/#e91e63/Layer -5.png';
// import HelicopterSVGCompe91e63 from './assets/#e91e63/Layer -4.png';
// import PointSVGComp9c27b0 from './assets/#9c27b0/Layer -3.png';
// import YachtSVGComp9c27b0 from './assets/#9c27b0/Layer 2.png';
// import Truck01SVGComp9c27b0 from './assets/#9c27b0/Layer -1.png';
// import Truck02SVGComp9c27b0 from './assets/#9c27b0/Layer -2.png';
// import AirplaneSVGComp9c27b0 from './assets/#9c27b0/Layer -6.png';
// import CargoshipSVGComp9c27b0 from './assets/#9c27b0/Layer -5.png';
// import HelicopterSVGComp9c27b0 from './assets/#9c27b0/Layer -4.png';
// import PointSVGComp673ab7 from './assets/#673ab7/Layer -3.png';
// import YachtSVGComp673ab7 from './assets/#673ab7/Layer 2.png';
// import Truck01SVGComp673ab7 from './assets/#673ab7/Layer -1.png';
// import Truck02SVGComp673ab7 from './assets/#673ab7/Layer -2.png';
// import AirplaneSVGComp673ab7 from './assets/#673ab7/Layer -6.png';
// import CargoshipSVGComp673ab7 from './assets/#673ab7/Layer -5.png';
// import HelicopterSVGComp673ab7 from './assets/#673ab7/Layer -4.png';
// import PointSVGComp3f51b5 from './assets/#3f51b5/Layer -3.png';
// import YachtSVGComp3f51b5 from './assets/#3f51b5/Layer 2.png';
// import Truck01SVGComp3f51b5 from './assets/#3f51b5/Layer -1.png';
// import Truck02SVGComp3f51b5 from './assets/#3f51b5/Layer -2.png';
// import AirplaneSVGComp3f51b5 from './assets/#3f51b5/Layer -6.png';
// import CargoshipSVGComp3f51b5 from './assets/#3f51b5/Layer -5.png';
// import HelicopterSVGComp3f51b5 from './assets/#3f51b5/Layer -4.png';
// import PointSVGComp2196f3 from './assets/#2196f3/Layer -3.png';
// import YachtSVGComp2196f3 from './assets/#2196f3/Layer 2.png';
// import Truck01SVGComp2196f3 from './assets/#2196f3/Layer -1.png';
// import Truck02SVGComp2196f3 from './assets/#2196f3/Layer -2.png';
// import AirplaneSVGComp2196f3 from './assets/#2196f3/Layer -6.png';
// import CargoshipSVGComp2196f3 from './assets/#2196f3/Layer -5.png';
// import HelicopterSVGComp2196f3 from './assets/#2196f3/Layer -4.png';
// import PointSVGComp03a9f4 from './assets/#03a9f4/Layer -3.png';
// import YachtSVGComp03a9f4 from './assets/#03a9f4/Layer 2.png';
// import Truck01SVGComp03a9f4 from './assets/#03a9f4/Layer -1.png';
// import Truck02SVGComp03a9f4 from './assets/#03a9f4/Layer -2.png';
// import AirplaneSVGComp03a9f4 from './assets/#03a9f4/Layer -6.png';
// import CargoshipSVGComp03a9f4 from './assets/#03a9f4/Layer -5.png';
// import HelicopterSVGComp03a9f4 from './assets/#03a9f4/Layer -4.png';
// import PointSVGComp00bcd4 from './assets/#00bcd4/Layer -3.png';
// import YachtSVGComp00bcd4 from './assets/#00bcd4/Layer 2.png';
// import Truck01SVGComp00bcd4 from './assets/#00bcd4/Layer -1.png';
// import Truck02SVGComp00bcd4 from './assets/#00bcd4/Layer -2.png';
// import AirplaneSVGComp00bcd4 from './assets/#00bcd4/Layer -6.png';
// import CargoshipSVGComp00bcd4 from './assets/#00bcd4/Layer -5.png';
// import HelicopterSVGComp00bcd4 from './assets/#00bcd4/Layer -4.png';
// import PointSVGComp009688 from './assets/#009688/Layer -3.png';
// import YachtSVGComp009688 from './assets/#009688/Layer 2.png';
// import Truck01SVGComp009688 from './assets/#009688/Layer -1.png';
// import Truck02SVGComp009688 from './assets/#009688/Layer -2.png';
// import AirplaneSVGComp009688 from './assets/#009688/Layer -6.png';
// import CargoshipSVGComp009688 from './assets/#009688/Layer -5.png';
// import HelicopterSVGComp009688 from './assets/#009688/Layer -4.png';
// import PointSVGComp4caf50 from './assets/#4caf50/Layer -3.png';
// import YachtSVGComp4caf50 from './assets/#4caf50/Layer 2.png';
// import Truck01SVGComp4caf50 from './assets/#4caf50/Layer -1.png';
// import Truck02SVGComp4caf50 from './assets/#4caf50/Layer -2.png';
// import AirplaneSVGComp4caf50 from './assets/#4caf50/Layer -6.png';
// import CargoshipSVGComp4caf50 from './assets/#4caf50/Layer -5.png';
// import HelicopterSVGComp4caf50 from './assets/#4caf50/Layer -4.png';
// import PointSVGComp8bc34a from './assets/#8bc34a/Layer -3.png';
// import YachtSVGComp8bc34a from './assets/#8bc34a/Layer 2.png';
// import Truck01SVGComp8bc34a from './assets/#8bc34a/Layer -1.png';
// import Truck02SVGComp8bc34a from './assets/#8bc34a/Layer -2.png';
// import AirplaneSVGComp8bc34a from './assets/#8bc34a/Layer -6.png';
// import CargoshipSVGComp8bc34a from './assets/#8bc34a/Layer -5.png';
// import HelicopterSVGComp8bc34a from './assets/#8bc34a/Layer -4.png';
// import PointSVGCompcddc39 from './assets/#cddc39/Layer -3.png';
// import YachtSVGCompcddc39 from './assets/#cddc39/Layer 2.png';
// import Truck01SVGCompcddc39 from './assets/#cddc39/Layer -1.png';
// import Truck02SVGCompcddc39 from './assets/#cddc39/Layer -2.png';
// import AirplaneSVGCompcddc39 from './assets/#cddc39/Layer -6.png';
// import CargoshipSVGCompcddc39 from './assets/#cddc39/Layer -5.png';
// import HelicopterSVGCompcddc39 from './assets/#cddc39/Layer -4.png';
// import PointSVGCompffeb3b from './assets/#ffeb3b/Layer -3.png';
// import YachtSVGCompffeb3b from './assets/#ffeb3b/Layer 2.png';
// import Truck01SVGCompffeb3b from './assets/#ffeb3b/Layer -1.png';
// import Truck02SVGCompffeb3b from './assets/#ffeb3b/Layer -2.png';
// import AirplaneSVGCompffeb3b from './assets/#ffeb3b/Layer -6.png';
// import CargoshipSVGCompffeb3b from './assets/#ffeb3b/Layer -5.png';
// import HelicopterSVGCompffeb3b from './assets/#ffeb3b/Layer -4.png';
// import PointSVGCompffc107 from './assets/#ffc107/Layer -3.png';
// import YachtSVGCompffc107 from './assets/#ffc107/Layer 2.png';
// import Truck01SVGCompffc107 from './assets/#ffc107/Layer -1.png';
// import Truck02SVGCompffc107 from './assets/#ffc107/Layer -2.png';
// import AirplaneSVGCompffc107 from './assets/#ffc107/Layer -6.png';
// import CargoshipSVGCompffc107 from './assets/#ffc107/Layer -5.png';
// import HelicopterSVGCompffc107 from './assets/#ffc107/Layer -4.png';
// import PointSVGCompff9800 from './assets/#ff9800/Layer -3.png';
// import YachtSVGCompff9800 from './assets/#ff9800/Layer 2.png';
// import Truck01SVGCompff9800 from './assets/#ff9800/Layer -1.png';
// import Truck02SVGCompff9800 from './assets/#ff9800/Layer -2.png';
// import AirplaneSVGCompff9800 from './assets/#ff9800/Layer -6.png';
// import CargoshipSVGCompff9800 from './assets/#ff9800/Layer -5.png';
// import HelicopterSVGCompff9800 from './assets/#ff9800/Layer -4.png';
// import PointSVGCompff5722 from './assets/#ff5722/Layer -3.png';
// import YachtSVGCompff5722 from './assets/#ff5722/Layer 2.png';
// import Truck01SVGCompff5722 from './assets/#ff5722/Layer -1.png';
// import Truck02SVGCompff5722 from './assets/#ff5722/Layer -2.png';
// import AirplaneSVGCompff5722 from './assets/#ff5722/Layer -6.png';
// import CargoshipSVGCompff5722 from './assets/#ff5722/Layer -5.png';
// import HelicopterSVGCompff5722 from './assets/#ff5722/Layer -4.png';
// import PointSVGComp795548 from './assets/#795548/Layer -3.png';
// import YachtSVGComp795548 from './assets/#795548/Layer 2.png';
// import Truck01SVGComp795548 from './assets/#795548/Layer -1.png';
// import Truck02SVGComp795548 from './assets/#795548/Layer -2.png';
// import AirplaneSVGComp795548 from './assets/#795548/Layer -6.png';
// import CargoshipSVGComp795548 from './assets/#795548/Layer -5.png';
// import HelicopterSVGComp795548 from './assets/#795548/Layer -4.png';
// import PointSVGComp607d8b from './assets/#607d8b/Layer -3.png';
// import YachtSVGComp607d8b from './assets/#607d8b/Layer 2.png';
// import Truck01SVGComp607d8b from './assets/#607d8b/Layer -1.png';
// import Truck02SVGComp607d8b from './assets/#607d8b/Layer -2.png';
// import AirplaneSVGComp607d8b from './assets/#607d8b/Layer -6.png';
// import CargoshipSVGComp607d8b from './assets/#607d8b/Layer -5.png';
// import HelicopterSVGComp607d8b from './assets/#607d8b/Layer -4.png';
import styled from 'styled-components';
import { WICHI_MAPOPTION_KEY } from '../constants';

// export const Truck01SVG = styled(Truck01SVGComp)`

//   path {
//     fill: ${props => props.color};
//   }

//   .cls-2 {
//     fill: white;
//   }
// `;

// export const Truck02SVG = styled(Truck02SVGComp)`
//   .cls-1 {
//     fill: ${props => props.color};
//   }

//   rect {
//     fill: ${props => props.color};
//   }
// `;

// export const AirplaneSVG = styled(AirplaneSVGComp)`
//   path {
//     fill: ${props => props.color};
//   }
// `;

// export const CargoshipSVG = styled(CargoshipSVGComp)`
//   .cls-1 {
//     fill: ${props => props.color};
//   }
// `;

// export const HelicopterSVG = styled(HelicopterSVGComp)`
//   .cls-1 {
//     fill: ${props => props.color};
//   }
// `;

// export const PointSVG = styled(PointSVGComp)`
// .cls-1 {
//   fill: ${props => props.color};
// }
// `;

// export const YachtSVG = styled(YachtSVGComp)`
// .cls-1 {
//   fill: ${props => props.color};
// }
// `;

export const allAssetIcons = [
  {
    name: 'truck01',
  },
  {
    name: 'truck02',
  },
  {
    name: 'airplane',
  },
  {
    name: 'cargoship',
  },
  {
    name: 'helicopter',
  },
  {
    name: 'point',
  },
  {
    name: 'yacht',
  },
];

const AssetImgContainer = styled.div`
  width: ${props => props.size || 48}px;
  height: ${props => props.size || 48}px;
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const AssetImg = props => (props.imgOnly ? <img alt="asset" {...props} style={{ ...(props.style || {}), maxHeight: '100%', maxWidth: '100%' }} /> : (
  <AssetImgContainer className="asset-img" size={props.size}>
    <img alt="asset" {...props} />
  </AssetImgContainer>
));


export const AssetSVG = ({ icon, color, ...otherProps }) => {
  const [defaultColor, setDefaultColor] = useState(window.defaultColorCache || null);

  useEffect(() => {
    if (defaultColor) return;

    try {
      const savedMapOption = JSON.parse(localStorage.getItem(WICHI_MAPOPTION_KEY));

      setDefaultColor(savedMapOption.defaultColor);
    } catch (err) {
      // console.log(err);
    }
  }, []);

  // import PointSVGComp607d8b from './assets/#607d8b/Layer -3.png';
  // import YachtSVGComp607d8b from './assets/#607d8b/Layer 2.png';
  // import Truck01SVGComp607d8b from './assets/#607d8b/Layer -1.png';
  // import Truck02SVGComp607d8b from './assets/#607d8b/Layer -2.png';
  // import AirplaneSVGComp607d8b from './assets/#607d8b/Layer -6.png';
  // import CargoshipSVGComp607d8b from './assets/#607d8b/Layer -5.png';
  // import HelicopterSVGComp607d8b from './assets/#607d8b/Layer -4.png';

  switch (icon) {
    case ('truck01'): {
      return <AssetImg src={`${process.env.PUBLIC_URL}/images/assets/${color && color.replace('#', '').toLowerCase() || defaultColor && defaultColor.replace('#', '').toLowerCase() || '7f7f7f'}/Layer -1.png`} {...otherProps} />;
    }
    case ('truck02'): {
      return <AssetImg src={`${process.env.PUBLIC_URL}/images/assets/${color && color.replace('#', '').toLowerCase() || defaultColor && defaultColor.replace('#', '').toLowerCase() || '7f7f7f'}/Layer -2.png`} {...otherProps} />;
    }
    case ('airplane'): {
      return <AssetImg src={`${process.env.PUBLIC_URL}/images/assets/${color && color.replace('#', '').toLowerCase() || defaultColor && defaultColor.replace('#', '').toLowerCase() || '7f7f7f'}/Layer -6.png`} {...otherProps} />;
    }
    case ('cargoship'): {
      return <AssetImg src={`${process.env.PUBLIC_URL}/images/assets/${color && color.replace('#', '').toLowerCase() || defaultColor && defaultColor.replace('#', '').toLowerCase() || '7f7f7f'}/Layer -5.png`} {...otherProps} />;
    }
    case ('helicopter'): {
      return <AssetImg src={`${process.env.PUBLIC_URL}/images/assets/${color && color.replace('#', '').toLowerCase() || defaultColor && defaultColor.replace('#', '').toLowerCase() || '7f7f7f'}/Layer -4.png`} {...otherProps} />;
    }
    case ('point'): {
      return <AssetImg src={`${process.env.PUBLIC_URL}/images/assets/${color && color.replace('#', '').toLowerCase() || defaultColor && defaultColor.replace('#', '').toLowerCase() || '7f7f7f'}/Layer -3.png`} {...otherProps} />;
    }
    case ('yacht'): {
      return <AssetImg src={`${process.env.PUBLIC_URL}/images/assets/${color && color.replace('#', '').toLowerCase() || defaultColor && defaultColor.replace('#', '').toLowerCase() || '7f7f7f'}/Layer 2.png`} {...otherProps} />;
    }
    default: {
      return <AssetImg src={`${process.env.PUBLIC_URL}/images/assets/${color && color.replace('#', '').toLowerCase() || defaultColor && defaultColor.replace('#', '').toLowerCase() || '7f7f7f'}/Layer -1.png`} {...otherProps} />;
    }
  }
};
