import styled from 'styled-components';

export const MobileAssetListSearch = styled.div`
width: 100%;
position: absolute;
top: 0;
left: 0;
height: 40px;
display: flex;
align-items: center;
padding: 0px 24px;
z-index: 600;
background-color: rgba(0, 0, 0, 0.4);

> svg {
  flex-shrink: 0;
  color: white;

  &:first-child {
    margin-right: 8px;
  }
}

> div {
  flex-grow: 1;

  > input {
    color: white;
  }
}
`;

export const MobileAssetList = styled.div`
width: 100%;
background: rgba(0, 0, 0, 0.8);
position: absolute;
top: 40px;
overflow: auto;
height: calc(100% - 40px);
z-index: 600;
display: flex;
flex-direction: column;
`;
