import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Background from 'images/background.png';
import Logo from 'images/logo_login.png';
import { useHistory } from 'react-router-dom';
import { Button, CircularProgress } from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import authHandler from '../libs/authHandler';
import { onEnter } from '../libs/helpers';

const SIGN_IN = gql`
  mutation SignIn($userId: String!, $password: String!) {
    SignIn(
      userid: $userId
      password: $password
    )
    {
      ok
      error
      token
    }
  }
`;

const SignInWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${Background});
  background-size: cover;
  background-position: center;
`;

const ContainedInput = styled.input`
  margin-top: 24px;
  height: 40px;
  background: white;
  outline: none; 
  border-radius: 8px;
  box-shadow: none;
  border: none;
  padding: 8px;
  width: 240px;

  &::placeholder {
    color: #bbb;
  }
`;

const SignInButton = styled(Button)`
  &.MuiButton-root {
    width: 240px;
    margin-top: 24px;
    border-radius: 8px;
    height: 40px;
  }
`;

function SignIn() {
  const history = useHistory();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [signIn, { loading }] = useMutation(SIGN_IN, {
    onCompleted: (data) => {
      // console.log(data);
      if (data.SignIn.token && !data.SignIn.error) {
        authHandler.signIn(data.SignIn.token);
        history.push('/dashboard');
      } else if (data.SignIn.error) {
        if (data.SignIn.error === 'unactivate') {
          return alert('Your account has been deactivated for some reason. Please contact to your MODOOTEL service provider.');
        }
        return alert('Please check your UserID or PW');
      }
    },
  });

  useEffect(() => {
    if (authHandler.accessToken) {
      console.log('ACCESS TOKEN EXISTS');
      history.push('/dashboard');
    }
  });

  const signInWithVariables = () => signIn({ variables: { userId: username, password } });

  return (
    <SignInWrapper>
      <img src={Logo} alt="Logo" style={{ width: 160 }} />
      <ContainedInput value={username} onChange={event => setUsername(event.target.value)} placeholder="Username" />
      <ContainedInput onKeyDown={onEnter(signInWithVariables)} type="password" value={password} onChange={event => setPassword(event.target.value)} placeholder="Password" />
      <SignInButton onClick={signInWithVariables} color="secondary" variant="contained">{loading ? <CircularProgress size={24} style={{ color: 'white', fontSize: 24 }} /> : 'Sign In'}</SignInButton>
    </SignInWrapper>
  );
}

export default SignIn;
