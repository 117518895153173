import gql from 'graphql-tag';

export const UPLOAD_FILE = gql`
  mutation UpdateProfile(
    $file: Upload
    $userId: Int
    $whichiId: Int
    $whichigroupId: Int
  ) {
    SingleFileUpload(
      file: $file
      userId: $userId
      whichiId: $whichiId
      whichigroupId: $whichigroupId
    ) {
      ok
      error
    }
  }
`;

export const other = '';
