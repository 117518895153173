import gql from 'graphql-tag';

export const REPORT_QUERY = gql`
  mutation AddTxReport($imei: String!) {
    AddTxReport(
      imei: $imei
    ) {
      ok
      error
    }
  }
`;

export const GET_REPORT_QUERY_RESPONSE = gql`
  query GetReportResponse(
    $imeis: [String]
    $from: Date
    $to: Date
  ) {
    GetMessages(
      report_setting:true
      io_port_status:true
      kind: ["report_rx_ask"]
      from: $from
      to: $to
      imeis: $imeis
    ) {
      ok
      error
      messages {
        createdAt
        message_body
        id
        reports {
          id
          report_number
          enable
          utc_on
          gps_on
          alt_on
          speed_on
          course_on
          period_time
        }
        ips {
          id
          port_num
          out_status
          io_status
          ADC
        }
      }
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation UpdateProfile(
    $email: String
    $age: Int
  ) {
    UpdateMyProfile(
      email: $email
      age: $age
    ) {
      ok
      error
    }
  }
`;

export const SEND_POLL_REQUEST = gql`
  mutation sendPollRequest(
    $imei: String!
    $reportNum: String!
  ) {
    AddTxPoll(
      imei: $imei
      reportNum: $reportNum
    ) {
      ok
      error
    }
  }
`;

export const TURN_DISTRESS_OFF = gql`
  mutation AddTxDistress (
    $imei: String!
  ) {
    AddTxDistress (
      imei: $imei
      on: "00"
    ) {
      ok
      error
    }
  }
`;
