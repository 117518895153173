import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Typography, CircularProgress, TextField as MUITextField, Select, MenuItem, Button, Dialog, DialogTitle, DialogContent, DialogActions, Switch,
} from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  CancelPresentation, Search, Edit, Delete, ArrowDownward, KeyboardArrowDown, KeyboardArrowUp, Close, Check,
} from '@material-ui/icons';
import moment from 'moment';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Link, useRouteMatch } from 'react-router-dom';
import TruckProfile from '../images/truck.png';
import { drawerWidth } from '../constants';
import { WichiTabs, WichiTab } from '../components/tabs';
import { WichiPrimaryButton } from '../components/buttons';
import {
  ReportAssetFilters, ReportListHeader,
} from '../components/reports';
import {
  GET_USER_LIST, GET_CHILD_USER_LIST, ADD_USER, DELETE_USER, EDIT_USER, CHECK_DUPLICATE, ADD_WHICHI, CONNECT_WHICHI_TO_USER, CHANGE_PASSWORD,
} from '../graphql/users';
import {
  getFullName, filterBlankToNull, capitalize, debounce, photoURL,
} from '../libs/helpers';
import { userRoles, servicesPlans } from '../libs/userInfoFilters';
import { SquareTextField } from '../components/input';
import apolloClient from '../libs/apolloClient';
import { assetInfoTypes, assetInfoUsage } from '../libs/assetInfoFilters';
import { EDIT_WHICH } from '../graphql/whichi';

const TextField = styled(MUITextField)`

& > label, & > div > div > p {
    color: #666;
  }
`;

const AdminAssetHeader = styled.div`
height: 48px;
background: white;
display: flex;
padding: 8px;
align-items: center;

> div {
  width: 200px;
  margin-right: 24px;
  display: flex;
  align-items: center;
}
`;

const ListItemLayout = styled.div`
display: flex;
align-items: center;
width: 100%;
height: 100%;
padding-left: 24px;
padding-right: 24px;
> p:first-child {
  flex-grow: 1;
}

> p {
  width: 16.6666%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props => props.mini && 'width: 25%;'}
}
`;

const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    padding: 0,
    paddingRight: 48,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    marginTop: 64,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const RightInner = styled.div`
  height: calc(100% - 48px);
  width: 100%;
  margin: 24px;
  display: flex;
  flex-direction: column;
  background: #252525;
  position: relative;
  align-items: center;

  >p:first-child {
    display: flex;
    padding: 8px 16px;
  }
`;

const ReportInner = styled.div`
display: flex;
width: 100%;
height: 100%;
flex-direction: column;

> div {
  width: 100%;
  flex-shrink: 0;
}
`;

const Contents = styled.div`
  display: flex;
  height: calc(100vh - 64px);
`;

const ContentRight = styled.div`
  height: 100%;
  flex-grow: 1;
  background: white;
  display: flex;
  background: #111111;
`;

const AdminHeader = styled.div`
  > p {
    width: 100%;
    padding: 8px 16px;
    position: relative;
    > button {
      position: absolute;
      right: 0;
      top: 8px;
    }
  }
`;

const ActiveCircle = styled.span`
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #F53036;
  margin-right: 8px;

  ${props => props.active && 'background: #5ADE9C;'}
`;


const ReportList = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  ${props => !props.noBackground && 'background: white;'}

  > div {
    height: 48px;
    flex-shrink: 0;
    cursor: pointer;
  }

  >* {
    &:hover {
      background: #DDD;
    }
  }


  >*:nth-child(even) {
    background: #DBEAFE;
    &:hover {
      background: #6999D9;
    }
  }
    
  ::-webkit-scrollbar {
    width: 16px;
  }
  ::-webkit-scrollbar-track {
  ${props => !props.noBackground && 'background: white;'}
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.4);
    width: 16px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 8px;
  }
`;

const NoHover = styled.div`
  &:hover {
    background: white !important;
    cursor: initial !important;
  }
`;

const DeleteButton = styled(Button)`
  color: #F53036 !important;
`;

const EditButton = styled(Button)`
  color: #06A1AF !important;
`;

const SelectedUser = styled.div`
  background: #0E0F1A;
  display: flex;
  flex-direction: column;

  > div {
    width: 100%:
  }

  > div:first-child {
    height: 48px;
    border-bottom: 1px solid #999;
    display: flex;
    align-items: center;
    padding: 0px 24px;
    font-size: 1.5rem;
  }

  > div + div {
    display: flex;
    height: 400px;

    > div {
      width: 50%;
      height: 100%;
    }

    > div + div {
      display: flex;
      flex-direction: column;
    }

    > div:first-child {
      display: flex;
      

      > div {
        flex-shrink: 0;
      }

      > div:first-child {
        width: 25%;
        display: flex;
        flex-direction: column
        > div:first-child {
          width: 100%;
          padding-bottom: 100%;
          height: 0;
          flex-shrink: 0;
          background-size: 100%;
        }

        > button {
          width: 100%;
          text-transform: none;
          color: white;
          padding: 8px;
          flex-grow: 1;

          > span {
            justify-content: flex-start;
            align-items: center;
            font-size: 1.2rem;
          }

          > span > svg {
            font-size: 1.25rem;
            margin-left: 8px;
          }
        }
      }

      > div + div {
        width: 75%;
        padding: 16px 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        > p {
          text-align: left;
          > span:first-child {
            display: inline-block;
            width: 200px;
          }

          > div {
            width: calc(100% - 200px);
          }
        }
      }
    }
  }
`;

const SelectedUserHeader = styled.div`
  position: relative;

  svg {
    position: absolute;
    right: 16px;
  }
`;

const roleScoreMap = {
  administrator: 4,
  agent: 3,
  customer: 2,
  asset: 1,
};

const DEFAULT_ADD_USER_INPUT = {
  userid: '',
  role: '',
  firstName: '',
  lastName: '',
  email: '',
  nickname: '',
  password: '',
  confirmPassword: '',
  phoneNumber: '',
  assetParentId: '',
  registrant: '',
  companyCode: '',
  fax: '',
  address: '',
  postalCode: '',
};

const DEFAULT_ADD_ASET_INPUT = {
  name: '',
  imei: '',
  serialNumber: '',
  type: '',
  usage: '',
  servicePlan: '',
};


export default function ReportPage(props) {
  const {
    open, profileData, isRightAssetEnabled,
  } = props;
  const { url } = useRouteMatch();
  const classes = useStyles();
  const [keywordFilter, setKeywordFilter] = useState('');
  const [typeFilter, setTypeFilter] = useState('');
  const [issuerFilter, setIssuerFilter] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [sort, setSort] = useState('');
  const [onAddUser, setOnAddUser] = useState(false);
  const [onDeleteUser, setOnDeleteUser] = useState(false);
  const [onEditUser, _setOnEditUser] = useState(false);
  const [editUserInput, setEditUserInput] = useState({});
  const [addUserInput, setAddUserInput] = useState(DEFAULT_ADD_USER_INPUT);
  const [isUserIdChecked, setIsUserIdChecked] = useState(false);
  const [addAssetInput, setAddAssetInput] = useState(DEFAULT_ADD_ASET_INPUT);
  const [isNicknameChecked, setIsNicknameChecked] = useState(false);
  const [passwordChangeModal, setPasswordChangeModal] = useState(false);
  const [changePassword, setChangePassword] = useState({ password: '', confirm: '' });
  const [editAssetInput, setEditAssetInput] = useState({});
  const { loading: getUsersLoading, data: getUsersData, refetch: getUserRefetch } = useQuery(GET_USER_LIST, {
    fetchPolicy: 'no-cache',
  });
  const [
    getChildUsers,
    {
      loading: getChildLoading,
      data: getChildData,
    },
  ] = useLazyQuery(GET_CHILD_USER_LIST, {
    fetchPolicy: 'no-cache',
  });
  const [
    commitAddUser,
    {
      loading: addUserLoading,
    },
  ] = useMutation(ADD_USER);
  const [
    commitAddWhichi,
  ] = useMutation(ADD_WHICHI);
  const [
    commitConnectWhichi,
  ] = useMutation(CONNECT_WHICHI_TO_USER);
  const [
    commitEditUser,
    {
      loading: editUserLoading,
    },
  ] = useMutation(EDIT_USER);
  const [
    commitChangePassword,
    {
      loading: changePasswordLoading,
    },
  ] = useMutation(CHANGE_PASSWORD);
  const [
    commitDeleteUser,
    {
      loading: deleteUserLoading,
    },
  ] = useMutation(DELETE_USER);
  const [commitEditAsset] = useMutation(EDIT_WHICH);

  const loadUser = (user) => {
    setSelectedUser(user);
    getChildUsers({
      variables: {
        userid: user.id,
      },
    });
  };

  const changeAddUserInput = (value, key) => {
    setAddUserInput({
      ...addUserInput,
      [key]: value,
    });
  };

  const changeAddAssetInput = (value, key) => {
    setAddAssetInput({
      ...addAssetInput,
      [key]: value,
    });
  };

  const setOnEditUser = (value) => {
    if (!selectedUser || !value) return _setOnEditUser(false);

    setEditUserInput({
      ...selectedUser,
    });

    if (selectedUser.role === 'asset') {
      const uwpipe = selectedUser.uwpipe && selectedUser.uwpipe[0];

      if (uwpipe) {
        setEditAssetInput(uwpipe);
      } else {
        setEditAssetInput({
          name: '',
          imei: '',
          serialNumber: '',
          servicePlan: '',
        });
      }
    }

    _setOnEditUser(value);
  };

  const getAgentChildTreeUsers = () => {
    if (!getUsersData) return [];
    const currentUsers = [];

    const recursivelyAddUser = (user) => {
      currentUsers.push(user);
      // console.log('RAU', user);
      if (user.usersAsUser && user.usersAsUser.length) {
        user.usersAsUser.forEach(innerUser => recursivelyAddUser({ ...innerUser, parentUser: user.nickname }));
      }
    };

    getUsersData.GetUsers.users.forEach((user) => {
      recursivelyAddUser(user);
    });

    return currentUsers;
  };


  const mapUsers = () => {
    if (getUsersLoading) {
      return (
        <NoHover style={{
          width: '100%', height: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: 1,
        }}
        >
          <CircularProgress />
        </NoHover>
      );
    }

    if (!getUsersData.GetUsers.ok || !getUsersData.GetUsers.users.length) {
      return (
        <NoHover style={{
          width: '100%', height: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: 1,
        }}
        >
          <CancelPresentation />
          <Typography variant="h6">No Users found.</Typography>
          <Typography variant="body1">If there&apos;s any problem, please contact us.</Typography>
        </NoHover>
      );
    }

    let currentUsers = getUsersData.GetUsers.users;


    if (profileData && profileData.role === 'agent') {
      currentUsers = getAgentChildTreeUsers();
    }

    let filteredUsers = currentUsers.filter((user) => {
      if (typeFilter) {
        if (user.role !== typeFilter) return false;
      }
      if (issuerFilter && !typeFilter) {
        if (!user.issuerUser || user.issuerUser.id !== issuerFilter) return false;
      }
      if (keywordFilter) {
        if (!(getFullName(user).includes(keywordFilter) || user.nickname && user.nickname.includes(keywordFilter))) return false;
      }
      return true;
    });

    if (sort) {
      filteredUsers = filteredUsers.sort((prev, next) => {
        let actPrev = next;
        let actNext = prev;

        if (sort.direction === 'asc') {
          actPrev = prev;
          actNext = next;
        }
        if (sort.type === 'name') {
          return (actPrev.nickname || getFullName(actPrev)).localeCompare((actNext.nickname || getFullName(actNext)));
        }
        if (sort.type === 'type') {
          return roleScoreMap[actPrev.role] - roleScoreMap[actNext.role];
        }
        return 0;
      });
    }

    // console.log(currentUsers);

    return (
      filteredUsers.map(user => (
        <ListItemLayout onClick={onEditUser ? () => {} : () => loadUser(user)}>
          <Typography>
            {
              user.nickname || getFullName(user)
            }
          </Typography>
          <Typography>
            {
              capitalize(user.role)
            }
          </Typography>
          <Typography>
            {
              user.issuerUser
              && (user.issuerUser.nickname || getFullName(user.issuerUser))
              || '-'
            }
          </Typography>
          <Typography>
            {
              moment(user.createdAt).format('YYYY/MM/DD HH:mm:ss')
            }
          </Typography>
          <Typography>
            {
              moment(user.updatedAt).format('YYYY/MM/DD HH:mm:ss')
            }
          </Typography>
          <Typography>
            {
              user.activate
              && <><ActiveCircle active />Activating</>
              || <><ActiveCircle />Deactivated</>
            }
          </Typography>
        </ListItemLayout>
      ))
    );
  };

  const mapChildUsers = () => {
    if (selectedUser.role === 'asset') return null;

    if (getChildLoading) {
      return (
        <NoHover style={{
          width: '100%', height: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: 1,
        }}
        >
          <CircularProgress />
        </NoHover>
      );
    }

    // console.log(getChildData);

    if (!getChildData || !getChildData.GetChildUsers.ok || !getChildData.GetChildUsers.users.length) {
      return (
        <NoHover style={{
          width: '100%', height: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: 1,
        }}
        >
          <CancelPresentation />
          <Typography variant="h6">No Child Users found.</Typography>
          <Typography variant="body1">If there&apos;s any problem, please contact us.</Typography>
        </NoHover>
      );
    }
    return (
      getChildData.GetChildUsers.users.map((originUser) => {
        const user = getUsersData.GetUsers.users.find(innerUser => innerUser.id === originUser.id) || originUser;
        return (
          <ListItemLayout onClick={onEditUser ? () => {} : () => loadUser(user)} mini>
            <Typography>
              {
                user.nickname || getFullName(user)
              }
            </Typography>
            <Typography>
              {
                capitalize(user.role)
              }
            </Typography>
            <Typography>
              {
                user.issuerUser
                && (user.issuerUser.nickname || getFullName(user.issuerUser))
                || '-'
              }
            </Typography>
            <Typography>
              {
                user.activate
                && <><ActiveCircle active />Activating</>
                || <><ActiveCircle />Deactivated</>
              }
            </Typography>
          </ListItemLayout>
        );
      })
    );
  };

  const issuerFilterList = [];

  if (!getUsersLoading && getUsersData.GetUsers.ok) {
    getUsersData.GetUsers.users.map((user) => {
      if (['administrator', 'agent'].includes(user.role)) {
        issuerFilterList.push(user);
      }
    });
  }

  const changeSort = (sortType) => {
    if (!sort || sort.type !== sortType) {
      return setSort({
        type: sortType,
        direction: 'desc',
      });
    }
    if (sort.direction === 'desc') {
      return setSort({
        type: sortType,
        direction: 'asc',
      });
    }
    return setSort(null);
  };

  const addUser = async () => {
    if (!(addUserInput.password === addUserInput.confirmPassword)) return;

    if (addUserInput.role !== 'asset') {
      await commitAddUser({
        variables: {
          ...filterBlankToNull(addUserInput),
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_USER_LIST }],
      });
      await getUserRefetch();

      setOnAddUser(false);
      setAddUserInput(DEFAULT_ADD_USER_INPUT);
    } else if (addUserInput.role === 'asset') {
      const { data: { SignUp: { newUser: { id: addedUserId } } } } = (await commitAddUser({
        variables: {
          ...addUserInput,
          firstName: addAssetInput.name,
          lastName: addAssetInput.name,
          nickname: addAssetInput.name,
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_USER_LIST }],
      }));

      try {
        const { data: { AddWhichi: { newWhichi: { id, imei: addedWhichiImei } } } } = (await commitAddWhichi({
          variables: {
            ...addAssetInput,
          },
        }));

        await commitConnectWhichi({
          variables: {
            id: addedUserId,
            imei: addedWhichiImei,
          },
        });
        await getUserRefetch();

        setOnAddUser(false);
        setAddUserInput(DEFAULT_ADD_USER_INPUT);
        setAddAssetInput(DEFAULT_ADD_ASET_INPUT);
      } catch (err) {
        // console.log(err);

        alert('Failed to add User Account.');
      }
    }
  };

  // console.log(issuerFilter);

  const deleteUser = async () => {
    await commitDeleteUser({
      variables: {
        userid: selectedUser.id,
      },
      awaitRefetchQueries: true,
      refetchQueries: [{ query: GET_USER_LIST }],
    });
    setOnDeleteUser(false);
    setSelectedUser(null);
    await getUserRefetch();
  };

  const editUser = async () => {
    const result = await commitEditUser({
      variables: {
        ...filterBlankToNull(editUserInput),
        userId: editUserInput.id,
      },
      awaitRefetchQueries: true,
      refetchQueries: [{ query: GET_USER_LIST }],
    });

    if (selectedUser.role === 'asset' && editAssetInput) {
      await commitEditAsset({
        variables: {
          ...filterBlankToNull(editAssetInput),
        },
      });
    }

    await getUserRefetch();

    // console.log(result);

    if (result.data.UpdateProfileByAdmin.ok) {
      if (selectedUser.role === 'asset' && editAssetInput) {
        setSelectedUser({
          ...editUserInput,
          uwpipe: [{
            ...editAssetInput,
          }],
        });
      } else {
        setSelectedUser({
          ...editUserInput,
        });
      }
      setOnEditUser(false);
    } else {
      alert('Error occured on Edit User API. Please kindly report this to us.');
    }
  };

  const closeChangePassword = () => {
    setChangePassword({ password: '', confirm: '' });
    setPasswordChangeModal(false);
  };

  const applyChangePassword = async () => {
    if (!changePassword.password) return alert('No password entered.');
    const result = await commitChangePassword({
      variables: {
        newPassword: changePassword.password,
        confirmPassword: changePassword.confirm,
        userid: selectedUser.id,
      },
    });

    // console.log(result);

    if (result.data.ChangePasswordByAdmin.ok) {
      closeChangePassword();
    } else {
      alert('Error occured on Edit User API. Please kindly report this to us.');
    }
  };


  const getParentUsersList = () => {
    if ((!getUsersData || !getUsersData.GetUsers || !getUsersData.GetUsers.users || !addUserInput.role)) return [];
    let currentUsers;
    if (profileData && profileData.role === 'agent') {
      currentUsers = getAgentChildTreeUsers();
    } else {
      currentUsers = getUsersData.GetUsers.users;
    }

    if (addUserInput.role === 'customer') {
      return currentUsers.filter(user => user.role === 'agent').map(user => (
        <MenuItem value={user.id}>{user.nickname || getFullName(user)}</MenuItem>
      ));
    }
    if (addUserInput.role === 'asset') {
      return currentUsers.filter(user => user.role === 'customer').map(user => (
        <MenuItem value={user.id}>{user.nickname || getFullName(user)}</MenuItem>
      ));
    }
  };

  const onChangeEditUserInput = (value, key) => {
    setEditUserInput({
      ...editUserInput,
      [key]: value,
    });
  };

  // console.log(editUserInput);

  const checkUserIdDuplicate = async (value) => {
    try {
      const { data: { CheckUserSameField: { has: isDuplicate } } } = (await apolloClient.query({
        query: CHECK_DUPLICATE,
        variables: {
          userid: value,
        },
      }));

      if (isDuplicate) {
        setIsUserIdChecked('duplicate');
      } else {
        setIsUserIdChecked('checked');
      }
    } catch (error) {
      // console.log(error);
      setIsUserIdChecked(false);
    }
  };

  const checkNicknameDuplicate = async (value) => {
    try {
      const { data: { CheckUserSameField: { has: isDuplicate } } } = (await apolloClient.query({
        query: CHECK_DUPLICATE,
        variables: {
          nickname: value,
        },
      }));

      if (isDuplicate) {
        setIsNicknameChecked('duplicate');
      } else {
        setIsNicknameChecked('checked');
      }
    } catch (error) {
      // console.log(error);
      setIsNicknameChecked(false);
    }
  };

  const changeUserId = async (value, key) => {
    changeAddUserInput(value, key);

    setIsUserIdChecked('loading');

    debounce({
      key: 'CHECK_DUPLICATE_USERID',
      callback: () => checkUserIdDuplicate(value),
      timeout: 100,
    });
  };

  const changeNickname = async (value, key) => {
    changeAddUserInput(value, key);

    setIsNicknameChecked('loading');

    debounce({
      key: 'CHECK_DUPLICATE_NICKNAME',
      callback: () => checkNicknameDuplicate(value),
      timeout: 100,
    });
  };

  const isUserAddable = (
    (
      isUserIdChecked === 'checked'
      && addUserInput.password === addUserInput.confirmPassword
      && addUserInput.userid
      && addUserInput.role
    )
    && addUserInput.role !== 'asset'
    && addUserInput.firstName
    && addUserInput.lastName
    && addUserInput.email
    && addUserInput.password
    && addUserInput.phoneNumber
    && (
      addUserInput.nickname
      || !['agent', 'customer'].includes(addUserInput.role)
    )
    || addUserInput.role === 'asset'
    && addAssetInput.name
    && addAssetInput.imei
  );

  const changePasswordInput = (value, key) => {
    setChangePassword({
      ...changePassword,
      [key]: value,
    });
  };

  const onChangeEditAssetInput = (value, key) => {
    setEditAssetInput({
      ...editAssetInput,
      [key]: value,
    });
  };

  return (
    <main
      className={clsx(classes.content, {
        [classes.contentShift]: open,
      })}
      style={isRightAssetEnabled ? {} : { paddingRight: 0 }}
    >
      <Contents>
        <ContentRight>
          <RightInner>
            <ReportInner>
              <AdminHeader>
                <Typography style={{ width: '100%' }} color="textSecondary">
                  <span>
                    User Management Console
                  </span>
                  <WichiPrimaryButton onClick={() => { setOnAddUser(true); }} width="240px">+ Add New User</WichiPrimaryButton>
                </Typography>
              </AdminHeader>
              <AdminAssetHeader>
                <div>
                  <Search />
                  <TextField
                    value={keywordFilter}
                    onChange={event => setKeywordFilter(event.target.value)}
                  />
                </div>
                <span style={{ fontSize: '1rem', marginRight: 12 }}>Authority Role</span>
                <div>
                  <Select
                    style={{ width: '100%' }}
                    displayEmpty
                    value={typeFilter}
                    onChange={event => setTypeFilter(event.target.value)}
                  >
                    <MenuItem value="">Show All</MenuItem>
                    {
                      (profileData && profileData.role === 'agent'
                      && userRoles.filter(role => role !== 'administrator')
                      || userRoles).map(role => (
                        <MenuItem style={{ textTransform: 'capitalize' }} key={role} value={role}>{capitalize(role)}</MenuItem>
                      ))
                    }
                  </Select>
                </div>
                <span style={{ fontSize: '1rem', marginRight: 12, color: typeFilter ? '#BBB' : 'unset' }}>Issuer</span>
                <div>
                  <Select
                    style={{ width: '100%' }}
                    displayEmpty
                    value={issuerFilter}
                    disabled={!!typeFilter}
                    onChange={event => setIssuerFilter(event.target.value)}
                  >
                    <MenuItem value="">Show All</MenuItem>
                    {
                      issuerFilterList.map(user => (
                        <MenuItem key={user.id} value={user.id}>
                          {
                            user.nickname || getFullName(user)
                          }
                        </MenuItem>
                      ))
                    }
                  </Select>
                </div>
              </AdminAssetHeader>
              <ReportListHeader>
                <ListItemLayout>
                  <Typography style={{ cursor: 'pointer' }} onClick={() => changeSort('name')} color="textSecondary">
                    Name
                    {
                      sort && sort.type === 'name'
                      && (
                        sort.direction === 'desc'
                        && <KeyboardArrowDown />
                        || <KeyboardArrowUp />
                      )
                      || <KeyboardArrowDown style={{ opacity: 0.4 }} />
                    }
                  </Typography>
                  <Typography style={{ cursor: 'pointer' }} onClick={() => changeSort('type')} color="textSecondary">
                    Type
                    {
                      sort && sort.type === 'type'
                      && (
                        sort.direction === 'desc'
                        && <KeyboardArrowDown />
                        || <KeyboardArrowUp />
                      )
                      || <KeyboardArrowDown style={{ opacity: 0.4 }} />
                    }
                  </Typography>
                  <Typography color="textSecondary">
                    ID Issuer
                  </Typography>
                  <Typography color="textSecondary">
                    Date Issued
                  </Typography>
                  <Typography color="textSecondary">
                    Date Modified
                  </Typography>
                  <Typography color="textSecondary">
                    Activate
                  </Typography>
                </ListItemLayout>
              </ReportListHeader>
              <ReportList style={{ flexShrink: 1, flexGrow: 1 }}>
                {
                  mapUsers()
                }
              </ReportList>
              {
                selectedUser
                && (
                <SelectedUser>
                  <SelectedUserHeader>
                    <Typography color="textSecondary">{selectedUser.role} &gt; {getFullName(selectedUser)}</Typography>
                    <Close
                      onClick={() => {
                        setOnEditUser(false);
                        setSelectedUser(null);
                      }}
                      style={{ color: 'white', cursor: 'pointer' }}
                    />
                  </SelectedUserHeader>
                  <div>
                    <div>
                      <div>
                        <div style={{ background: `url(${TruckProfile})`, backgroundSize: '100%' }}>
                          <img src={photoURL(selectedUser.profilePhoto)} alt="" style={{ width: '100%' }} />
                        </div>
                        {
                          onDeleteUser
                          && (
                            <>
                              <Button disabled={deleteUserLoading} onClick={() => setOnDeleteUser(false)} variant="text">Cancel Delete</Button>
                              <DeleteButton disabled={deleteUserLoading} onClick={deleteUser} variant="text">Confirm Delete <Delete /></DeleteButton>
                            </>
                          )
                          || onEditUser
                          && (
                            <>
                              <Button disabled={editUserLoading} onClick={() => { setOnEditUser(false); setEditAssetInput(null); }} variant="text">Cancel Edit</Button>
                              <EditButton disabled={editUserLoading} onClick={editUser} variant="text">Save Changes <Check /></EditButton>
                            </>
                          )
                          || (
                            <>
                              <Button onClick={() => setOnEditUser(true)} variant="text">Edit <Edit /></Button>
                              <Button onClick={() => setOnDeleteUser(true)} variant="text">Delete <Delete /></Button>
                            </>
                          )
                        }
                      </div>
                      <div style={{ position: 'relative' }}>
                        <Typography color="textSecondary">
                          <span>{capitalize(selectedUser.role)} User Name</span>
                          {
                            onEditUser
                            && <SquareTextField onChange={event => onChangeEditUserInput(event.target.value, 'nickname')} value={editUserInput.nickname} />
                            || selectedUser.nickname
                            && selectedUser.nickname
                            || '-'
                          }
                        </Typography>
                        {
                          selectedUser.role === 'asset'
                          && (
                            <Typography color="textSecondary">
                              <span>Asset Name</span>
                              {
                                onEditUser
                                && <SquareTextField onChange={event => onChangeEditAssetInput(event.target.value, 'name')} value={editAssetInput.name} />
                                || selectedUser && selectedUser.uwpipe && selectedUser.uwpipe[0]
                                && selectedUser.uwpipe[0].name
                                || '-'
                              }
                            </Typography>
                          )
                        }
                        <Typography color="textSecondary">
                          <span>UserID</span>
                          {
                            selectedUser.userid
                          }
                        </Typography>
                        <Typography color="textSecondary">
                          <span>Type</span>
                          {
                            capitalize(selectedUser.role)
                          }
                        </Typography>
                        <Typography color="textSecondary">
                          <span>ID Issuer</span>
                          {
                            selectedUser.issuerUser
                            && (selectedUser.issuerUser.nickname || getFullName(selectedUser.issuerUser))
                            || '-'
                          }
                        </Typography>
                        {
                          ['asset', 'customer'].includes(selectedUser.role) && (
                            <Typography color="textSecondary">
                              <span>Parent User</span>
                              {
                                selectedUser.parentUser
                                && (selectedUser.parentUser.nickname || selectedUser.parentUser)
                                || '-'
                              }
                            </Typography>
                          )
                        }
                        <Typography color="textSecondary">
                          <span>Date Issued</span>
                          {
                            moment(selectedUser.createdAt).format('YYYY/MM/DD HH:mm:ss')
                          }
                        </Typography>
                        <Typography color="textSecondary">
                          <span>Date Modified</span>
                          {
                            moment(selectedUser.updatedAt).format('YYYY/MM/DD HH:mm:ss')
                          }
                        </Typography>
                        {
                          selectedUser.role === 'asset'
                          && (
                            <>
                              <Typography color="textSecondary">
                                <span>IMEI</span>
                                {
                                  onEditUser
                                  && <SquareTextField onChange={event => onChangeEditAssetInput(event.target.value, 'imei')} value={editAssetInput.imei} />
                                  || selectedUser && selectedUser.uwpipe && selectedUser.uwpipe[0]
                                  && selectedUser.uwpipe[0].imei || '-'
                                }
                              </Typography>
                              <Typography color="textSecondary">
                                <span>Serial Number</span>
                                {
                                  onEditUser
                                  && <SquareTextField onChange={event => onChangeEditAssetInput(event.target.value, 'serialNumber')} value={editAssetInput.serialNumber} />
                                  || selectedUser && selectedUser.uwpipe && selectedUser.uwpipe[0]
                                  && selectedUser.uwpipe[0].serialNumber || '-'
                                }
                              </Typography>
                              <Typography color="textSecondary">
                                <span>Type</span>
                                {
                                  selectedUser && selectedUser.uwpipe && selectedUser.uwpipe[0]
                                  && selectedUser.uwpipe[0].type || '-'
                                }
                              </Typography>
                              <Typography color="textSecondary">
                                <span>Usage</span>
                                {
                                  selectedUser && selectedUser.uwpipe && selectedUser.uwpipe[0]
                                  && selectedUser.uwpipe[0].usage || '-'
                                }
                              </Typography>
                              <Typography color="textSecondary">
                                <span>Service Plan</span>
                                {
                                  onEditUser
                                  && <SquareTextField onChange={event => onChangeEditAssetInput(event.target.value, 'servicePlan')} value={editAssetInput.servicePlan} />
                                  || selectedUser && selectedUser.uwpipe && selectedUser.uwpipe[0]
                                  && selectedUser.uwpipe[0].servicePlan || 'Not Selected'
                                }
                              </Typography>
                              <Typography color="textSecondary">
                                <span>Data Usage</span>
                                <Link to={`/dashboard/assets?selected=${selectedUser && selectedUser.uwpipe && selectedUser.uwpipe[0] && selectedUser.uwpipe[0].id}`}>
                                  View from Asset page
                                </Link>
                              </Typography>
                            </>
                          ) || (
                            <>
                              <Typography color="textSecondary">
                                <span>Registrant Name</span>
                                {
                                  onEditUser
                                  && (
                                    <>
                                      <SquareTextField style={{ width: 'calc(50% - 104px)', marginRight: 8 }} onChange={event => onChangeEditUserInput(event.target.value, 'firstName')} value={editUserInput.firstName} />
                                      <SquareTextField style={{ width: 'calc(50% - 104px)' }} onChange={event => onChangeEditUserInput(event.target.value, 'lastName')} value={editUserInput.lastName} />
                                    </>
                                  )
                                  || getFullName(selectedUser)
                                }
                              </Typography>
                              <Typography color="textSecondary">
                                <span>Company Code</span>
                                {
                                  onEditUser
                                  && <SquareTextField onChange={event => onChangeEditUserInput(event.target.value, 'companyCode')} value={editUserInput.companyCode} />
                                  || selectedUser.companyCode
                                  && selectedUser.companyCode
                                  || '-'
                                }
                              </Typography>
                              <Typography color="textSecondary">
                                <span>Phone</span>
                                {
                                  onEditUser
                                  && <SquareTextField onChange={event => onChangeEditUserInput(event.target.value, 'phoneNumber')} value={editUserInput.phoneNumber} />
                                  || selectedUser.phoneNumber
                                  && selectedUser.phoneNumber
                                  || '-'
                                }
                              </Typography>
                              <Typography color="textSecondary">
                                <span>FAX</span>
                                {
                                  onEditUser
                                  && <SquareTextField onChange={event => onChangeEditUserInput(event.target.value, 'fax')} value={editUserInput.fax} />
                                  || selectedUser.fax
                                  && selectedUser.fax
                                  || '-'
                                }
                              </Typography>
                              <Typography color="textSecondary">
                                <span>E-mail</span>
                                {
                                  onEditUser
                                  && <SquareTextField onChange={event => onChangeEditUserInput(event.target.value, 'email')} value={editUserInput.email} />
                                  || selectedUser.email
                                  && selectedUser.email
                                  || '-'
                                }
                              </Typography>
                              <Typography color="textSecondary">
                                <span>Address</span>
                                {
                                  onEditUser
                                  && <SquareTextField onChange={event => onChangeEditUserInput(event.target.value, 'address')} value={editUserInput.address} />
                                  || selectedUser.address
                                  && selectedUser.address
                                  || '-'
                                }
                              </Typography>
                              <Typography color="textSecondary">
                                <span>Postal Code</span>
                                {
                                  onEditUser
                                  && <SquareTextField onChange={event => onChangeEditUserInput(event.target.value, 'postalCode')} value={editUserInput.postalCode} />
                                  || selectedUser.postalCode
                                  && selectedUser.postalCode
                                  || '-'
                                }
                              </Typography>
                            </>
                          )
                        }
                        <Typography color="textSecondary">
                          <span>Activate</span>
                          {
                            (onEditUser ? editUserInput.activate : selectedUser.activate)
                            && <><ActiveCircle active />Activating</>
                            || <><ActiveCircle />Deactivated</>
                          }
                          {
                            onEditUser
                            && <Switch checked={editUserInput.activate} onChange={() => onChangeEditUserInput(!editUserInput.activate, 'activate')} />
                          }
                        </Typography>
                        {
                          !onEditUser && profileData.role === 'administrator'
                          && <Button style={{ position: 'absolute', right: 16, bottom: 16 }} onClick={() => setPasswordChangeModal(true)} color="secondary" variant="contained" size="small">Change Password</Button>
                        }
                      </div>
                    </div>
                    {
                      selectedUser && selectedUser.role !== 'asset'
                      && (
                        <div>
                          <ReportListHeader style={{ background: '#252525' }}>
                            <ListItemLayout mini>
                              <Typography color="textSecondary">
                                Name
                              </Typography>
                              <Typography color="textSecondary">
                                Type
                              </Typography>
                              <Typography color="textSecondary">
                                ID Issuer
                              </Typography>
                              <Typography color="textSecondary">
                                Activate
                              </Typography>
                            </ListItemLayout>
                          </ReportListHeader>
                          <ReportList style={{ flexGrow: 1 }}>
                            {
                              mapChildUsers()
                            }
                          </ReportList>
                        </div>
                      )
                    }
                  </div>
                </SelectedUser>
                )
              }
            </ReportInner>
          </RightInner>
        </ContentRight>
      </Contents>
      <Dialog fullWidth open={onAddUser} onClose={addUserLoading ? () => {} : () => setOnAddUser(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add new user</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            * Required fields
          </Typography>
          <br />
          <Typography>
            Authority *
          </Typography>
          <Select
            margin="dense"
            displayEmpty
            value={addUserInput.role}
            onChange={event => changeAddUserInput(event.target.value, 'role')}

            label="Authority"
            fullWidth
          >
            <MenuItem value="">Select Authority Role...</MenuItem>
            {
              (profileData && profileData.role === 'agent'
              && userRoles.filter(role => role !== 'administrator')
              || userRoles).map(role => (
                <MenuItem value={role}>{capitalize(role)}</MenuItem>
              ))
            }
          </Select>
          {
            ['agent', 'customer'].includes(addUserInput.role)
            && (
              <>
                <Typography style={{ marginTop: 20 }}>
                  {addUserInput.role === 'agent' ? 'Agent Name' : 'Customer Name'} *
                </Typography>
                <TextField
                  autoFocus
                  margin="dense"
                  value={addUserInput.nickname}
                  error={isNicknameChecked === 'duplicate'}
                  helperText={isNicknameChecked === 'duplicate' && 'Already exists.' || isNicknameChecked === 'checked' && <span style={{ color: '#444' }}>Available Name</span> || ''}
                  onChange={event => changeNickname(event.target.value, 'nickname')}
                  label="User ID"
                  fullWidth
                />
              </>
            )
          }
          <Typography style={{ marginTop: 20 }}>
            User ID *
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            value={addUserInput.userid}
            error={isUserIdChecked === 'duplicate'}
            helperText={isUserIdChecked === 'duplicate' && 'Already exists.' || isUserIdChecked === 'checked' && <span style={{ color: '#444' }}>Available ID</span> || ''}
            onChange={event => changeUserId(event.target.value, 'userid')}
            label="User ID"
            fullWidth
          />
          {
            (
              addUserInput.role === 'asset'
              || addUserInput.role === 'customer' && profileData && profileData.role === 'administrator'
            )
            && (
              <>
                <Typography style={{ marginTop: 20 }}>
                  Parent User ID *
                </Typography>
                <Select
                  margin="dense"
                  displayEmpty
                  value={addUserInput[`${addUserInput.role}ParentId`]}
                  onChange={event => changeAddUserInput(event.target.value, `${addUserInput.role}ParentId`)}
                  label="Parent"
                  fullWidth
                >
                  <MenuItem value="">Select Parent ID</MenuItem>
                  {
                   getParentUsersList()
                  }
                </Select>
              </>
            )
          }
          <Typography style={{ marginTop: 20 }}>
            Password *
          </Typography>
          <TextField
            margin="dense"
            value={addUserInput.password}
            onChange={event => changeAddUserInput(event.target.value, 'password')}
            label="Password"
            type="password"
            fullWidth
          />
          <Typography style={{ marginTop: 20 }}>
            Confirm Password *
          </Typography>
          <TextField
            margin="dense"
            value={addUserInput.confirmPassword}
            error={addUserInput.confirmPassword !== addUserInput.password}
            onChange={event => changeAddUserInput(event.target.value, 'confirmPassword')}
            label="Repeat Password"
            type="password"
            fullWidth
          />
          {
            addUserInput.role === 'asset'
            && (
              <>
                <Typography style={{ marginTop: 20 }}>
                  Asset Name *
                </Typography>
                <TextField
                  margin="dense"
                  value={addAssetInput.name}
                  onChange={event => changeAddAssetInput(event.target.value, 'name')}
                  label="Asset Name"
                  fullWidth
                />
                <Typography style={{ marginTop: 20 }}>
                  IMEI *
                </Typography>
                <TextField
                  margin="dense"
                  value={addAssetInput.imei}
                  onChange={event => changeAddAssetInput(event.target.value, 'imei')}
                  label="IMEI"
                  fullWidth
                />
                <Typography style={{ marginTop: 20 }}>
                  Serial Number
                </Typography>
                <TextField
                  margin="dense"
                  value={addAssetInput.serialNumber}
                  onChange={event => changeAddAssetInput(event.target.value, 'serialNumber')}
                  label="Serial Number"
                  fullWidth
                />
                <Typography style={{ marginTop: 20 }}>
                  Asset Type
                </Typography>
                <Select
                  margin="dense"
                  displayEmpty
                  value={addAssetInput.type}
                  onChange={event => changeAddAssetInput(event.target.value, 'type')}
                  label="Type"
                  fullWidth
                >
                  <MenuItem value="">Select Type</MenuItem>
                  {
                   assetInfoTypes.map(type => (
                     <MenuItem value={type}>{type}</MenuItem>
                   ))
                  }
                </Select>
                <Typography style={{ marginTop: 20 }}>
                  Usage
                </Typography>
                <Select
                  margin="dense"
                  displayEmpty
                  value={addAssetInput.usage}
                  onChange={event => changeAddAssetInput(event.target.value, 'usage')}
                  label="Usage"
                  fullWidth
                >
                  <MenuItem value="">Select Usage</MenuItem>
                  {
                   (assetInfoUsage[addAssetInput.type] || ['None']).map(usage => (
                     <MenuItem value={usage}>{usage}</MenuItem>
                   ))
                  }
                </Select>
                <Typography style={{ marginTop: 20 }}>
                  Service Plan
                </Typography>
                <Select
                  margin="dense"
                  displayEmpty
                  value={addAssetInput.servicePlan}
                  onChange={event => changeAddAssetInput(event.target.value, 'servicePlan')}
                  label="Parent"
                  fullWidth
                >
                  <MenuItem value="">Select Service Plan</MenuItem>
                  {
                   servicesPlans.map(plan => (
                     <MenuItem key={plan} value={plan}>{plan}</MenuItem>
                   ))
                  }
                </Select>
              </>
            )
            || (
              <>
                <Typography style={{ marginTop: 20 }}>
                  Registrant First Name *
                </Typography>
                <TextField
                  margin="dense"
                  value={addUserInput.firstName}
                  onChange={event => changeAddUserInput(event.target.value, 'firstName')}
                  label="First Name"
                  fullWidth
                />
                <Typography style={{ marginTop: 20 }}>
                  Registrant Last Name *
                </Typography>
                <TextField
                  margin="dense"
                  value={addUserInput.lastName}
                  onChange={event => changeAddUserInput(event.target.value, 'lastName')}
                  label="Last Name"
                  fullWidth
                />
                <Typography style={{ marginTop: 20 }}>
                  E-Mail *
                </Typography>
                <TextField
                  margin="dense"
                  value={addUserInput.email}
                  onChange={event => changeAddUserInput(event.target.value, 'email')}
                  label="E-Mail"
                  fullWidth
                />
                <Typography style={{ marginTop: 20 }}>
                  Phone Number *
                </Typography>
                <TextField
                  margin="dense"
                  value={addUserInput.phoneNumber}
                  onChange={event => changeAddUserInput(event.target.value, 'phoneNumber')}
                  label="Phone Number"
                  fullWidth
                />
                <Typography style={{ marginTop: 20 }}>
                  Company Code
                </Typography>
                <TextField
                  margin="dense"
                  value={addUserInput.companyCode}
                  onChange={event => changeAddUserInput(event.target.value, 'companyCode')}
                  label="Company Code"
                  fullWidth
                />
                <Typography style={{ marginTop: 20 }}>
                  Fax
                </Typography>
                <TextField
                  margin="dense"
                  value={addUserInput.fax}
                  onChange={event => changeAddUserInput(event.target.value, 'fax')}
                  label="Fax"
                  fullWidth
                />
                <Typography style={{ marginTop: 20 }}>
                  Address
                </Typography>
                <TextField
                  margin="dense"
                  value={addUserInput.address}
                  onChange={event => changeAddUserInput(event.target.value, 'address')}
                  label="Address"
                  fullWidth
                />
                <Typography style={{ marginTop: 20 }}>
                  Postal Code
                </Typography>
                <TextField
                  margin="dense"
                  value={addUserInput.postalCode}
                  onChange={event => changeAddUserInput(event.target.value, 'postalCode')}
                  label="Postal Code"
                  fullWidth
                />
              </>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={addUserLoading ? () => {} : () => setOnAddUser(false)} color="primary">
            Cancel
          </Button>
          <div onClick={!isUserAddable ? () => alert('Please fill out all required fields.') : () => {}}>
            <Button disabled={!isUserAddable} onClick={addUserLoading ? () => {} : addUser} color="primary">
              {
                addUserLoading
                && <CircularProgress size={24} />
                || 'Apply'
              }
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth open={passwordChangeModal} onClose={changePasswordLoading ? () => {} : closeChangePassword} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
        <DialogContent>
          Changing password for user {selectedUser && (selectedUser.nickname || getFullName(selectedUser))}
          <Typography style={{ marginTop: 20 }}>
            New password
          </Typography>
          <TextField
            margin="dense"
            value={changePassword.password}
            onChange={event => changePasswordInput(event.target.value, 'password')}
            label="Password"
            fullWidth
            type="password"
          />
          <Typography style={{ marginTop: 20 }}>
            Confirm password
          </Typography>
          <TextField
            margin="dense"
            value={changePassword.confirm}
            error={changePassword.confirm !== changePassword.password}
            onChange={event => changePasswordInput(event.target.value, 'confirm')}
            label="Confirm"
            fullWidth
            type="password"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={changePasswordLoading ? () => {} : closeChangePassword} color="primary">
            Cancel
          </Button>
          <div onClick={changePassword.confirm !== changePassword.password ? () => alert('Please fill out all required fields.') : () => {}}>
            <Button disabled={changePassword.confirm !== changePassword.password} onClick={changePasswordLoading ? () => {} : applyChangePassword} color="primary">
              {
                changePasswordLoading
                && <CircularProgress size={24} />
                || 'Apply'
              }
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </main>
  );
}
