import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const WichiPrimaryButton = styled(Button)`
  height: 24px;
  color: white !important;
  width: ${props => props.width || 'calc(50% - 8px)'};
  margin: 0px 8px;
  background: #06A1AF;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  padding: 0;
  text-decoration: none;
  text-transform: none;

  &:hover {
  background-color: #06A1AF;
  }
`;

export const other = '';
