import styled from 'styled-components';
import Select from '@material-ui/core/Select';

export const SquareSelect = styled(Select)`
  height: 24px;
  font-size: 1em;
  margin-top: 0;
  color: white;
  width: ${props => props.width || 'calc(100% - 24px)'};

  > svg {
    color: rgba(255, 255, 255, 0.7);;
  }

  &:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid rgba(255, 255, 255, 0.85);
  }

  &:before {
    border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  }

  &:after {
    border-bottom: 2px solid white;
  }
`;

export const other = '';
