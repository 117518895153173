import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Typography, CircularProgress, Button, InputBase, useMediaQuery,
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  ExpandMore, Pageview, KeyboardArrowRight, CancelPresentation, KeyboardArrowDown, KeyboardArrowUp, Search, Close, List,
} from '@material-ui/icons';
import { useLazyQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import {
  GroupIcon, AssetListItem, AdminInnerExpansionPanel, AdminOuterExpansionPanel,
} from '../components/views';
import { drawerWidth, API_HOST } from '../constants';
import { GET_WHICHI_REPORT_INFO, GET_WHOLETREE_WHICHIS } from '../graphql/whichi';
import { WichiTabs, WichiTab } from '../components/tabs';
import {
  firstUpperLetter, getFullName, getUTCTime, preventDefault,
} from '../libs/helpers';
import { WichiPrimaryButton } from '../components/buttons';
import {
  AverageReportLayout, PositionReportLayout, ReportInner, ReportAssetHeader, ReportAssetFilters, ReportAssetTimeline, GrowPadder, ReportListHeader, EventReportLayout, ReportList,
} from '../components/reports';
import messageKindMap from '../libs/messageKindMaps';
import { MobileAssetList, MobileAssetListSearch } from '../components/mobile';

function useLocationQuery() {
  return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    padding: 0,
    paddingRight: 48,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    marginTop: 64,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const RightInner = styled.div`
  ${
  props => !props.isMobile && `
    height: calc(100% - 48px);
    margin: 24px;
  `
}
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #252525;
  position: relative;
  align-items: center;

  >p:first-child {
    display: flex;
    padding: 8px 16px;
  }
`;

const ExpansionPanel = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
    alignItems: 'center',
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles({
  root: {
    padding: 0,
    paddingLeft: 12,
    flexDirection: 'column',
  },
})(MuiExpansionPanelDetails);

const Contents = styled.div`
  display: flex;
  height: calc(100vh - 64px);
`;

const ContentLeft = styled.div`
  width: 300px;
  background: #0E0F1A;
  height: 100%;
  border-left: 4px solid black;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 16px;
  }
  ::-webkit-scrollbar-track {
    background: #0E0F1A;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.4);
    width: 16px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 8px;
  }
`;

const ContentRight = styled.div`
${props => !props.isMobile && `
  height: 100%
` || `
  height: calc(100% - 40px);
  margin-top: 40px;
`}
  flex-grow: 1;
  background: white;
  display: flex;
  background: #111111;
`;


const OuterExpansionPanel = styled(ExpansionPanel)`
  background-color: transparent;
  box-shadow: none;

  > div {
    > div:first-child {
      margin-left: 32px;
    }
    > div + div {
      color: white;
      color: white;
      margin-right: 0;
      position: absolute;
      left: 12px;
    }
  }
`;

const InnerExpansionPanel = styled(ExpansionPanel)`
  background-color: transparent;
  box-shadow: none;
  width: 100%;

  > div {
    > div:first-child {
      margin-left: 32px;
    }
    > div + div {
      color: white;
      margin-right: 0;
      position: absolute;
      left: 12px;
    }
  }
`;


export default function ReportPage(props) {
  const {
    open, assets, loading, isRightAssetEnabled, profileData, mapOption,
  } = props;
  const classes = useStyles();
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [sort, setSort] = useState('');
  const [isInitialized, setInitialized] = useState(false);
  const [selectedReportTab, setSelectedReportTab] = useState('event');
  const [selectedStartDate, setStartDate] = useState(moment().subtract(7, 'day'));
  const [selectedEndDate, setEndDate] = useState(moment());
  const [recentAssets, setRecentAssets] = useState(localStorage.getItem(`${profileData ? profileData.id : 'null'}whichiten-report-recent`) ? JSON.parse(localStorage.getItem(`${profileData ? profileData.id : 'null'}whichiten-report-recent`)) : []);
  const [currentUserId, setCurrentUserId] = useState(profileData ? profileData.id : null);
  const [isPreSelected, setIsPreSelected] = useState(false);
  const [showMobileAssetList, setShowMobileAssetList] = useState(false);
  const [assetSearchKeyword, setAssetSearchKeyword] = useState('');
  const query = useLocationQuery();
  const isMobile = useMediaQuery('(max-width: 500px)');

  useEffect(() => {
    if (currentUserId !== (profileData ? profileData.id : null)) {
      setCurrentUserId(profileData ? profileData.id : null);
      setRecentAssets(localStorage.getItem(`${profileData ? profileData.id : 'null'}whichiten-report-recent`) ? JSON.parse(localStorage.getItem(`${profileData ? profileData.id : 'null'}whichiten-report-recent`)) : []);
    }
  });

  const [getReportInfo, {
    data: reportInfoData, loading: reportInfoLoading,
  }] = useLazyQuery(GET_WHICHI_REPORT_INFO, {
    fetchPolicy: 'no-cache',
  });

  const groupedAssets = {};
  const groups = {};

  const addOrCreateToGroup = (asset, groupName, group) => {
    if (!groupedAssets[groupName]) {
      groupedAssets[groupName] = [];

      if (groupName !== 'Ungroup') {
        groups[groupName] = group;
      }
    }
    groupedAssets[groupName].push(asset);
  };

  if (!loading) {
    assets.map((asset) => {
      if (assetSearchKeyword) {
        if (!(asset.name && asset.name.includes(assetSearchKeyword))) {
          return;
        }
      }
      if (!asset.whichigroup) {
        addOrCreateToGroup(asset, 'Ungroup');
      } else {
        addOrCreateToGroup(asset, asset.whichigroup.name, asset.whichigroup);
      }
    });
  }

  const addRecent = (whichiId) => {
    const savedRecents = localStorage.getItem(`${profileData ? profileData.id : 'null'}whichiten-report-recent`);
    const currentRecent = savedRecents ? JSON.parse(savedRecents) : [];

    const newRecent = currentRecent.filter(recent => recent !== whichiId);
    newRecent.push(whichiId);
    localStorage.setItem(`${profileData ? profileData.id : 'null'}whichiten-report-recent`, JSON.stringify(newRecent.slice(-5)));
    setRecentAssets(newRecent.slice(-5));
  };

  const loadAsset = async (asset) => {
    await getReportInfo({
      variables: {
        whichiId: asset.id,
        whichiIds: [asset.id],
        from: moment(selectedStartDate.format('YYYY/MM/DD 00:00:00')),
        to: moment(selectedEndDate.format('YYYY/MM/DD 23:59:59')),
      },
    });
    setSelectedAsset(asset);
    addRecent(asset.id);
  };

  const refetchReportInfo = async () => {
    const asset = selectedAsset;
    // console.log(selectedStartDate, selectedEndDate);
    await getReportInfo({
      variables: {
        whichiId: asset.id,
        whichiIds: [asset.id],
        from: moment(selectedStartDate.format('YYYY/MM/DD 00:00:00')),
        to: moment(selectedEndDate.format('YYYY/MM/DD 23:59:59')),
      },
    });
    setSelectedAsset(asset);
    addRecent(asset.id);
  };

  const changeSort = (sortType) => {
    if (!sort || sort.type !== sortType) {
      return setSort({
        type: sortType,
        direction: 'desc',
      });
    }
    if (sort.direction === 'desc') {
      return setSort({
        type: sortType,
        direction: 'asc',
      });
    }
    return setSort(null);
  };

  const getRecentAssets = () => recentAssets.map(whichiId => assets.find(asset => asset.id === whichiId)).filter(asset => asset).reverse();

  const mapAssets = innerAssets => innerAssets.filter(asset => asset).map(asset => <AssetListItem selected={selectedAsset && selectedAsset.id === asset.id} onClick={() => loadAsset(asset)} variant="text">{asset.name}</AssetListItem>);

  const mapGroupedAssets = () =>
    // console.log(groupedAssets);
    Object.keys(groupedAssets).sort((prev, next) => prev === 'Ungroup' && 1 || next === 'Ungroup' && -1 || 0).map((groupKey) => {
      const currentGroup = groups[groupKey] || {};
      if (!groupedAssets[groupKey] || !groupedAssets[groupKey].length) return;
      return (
        <InnerExpansionPanel>
          <ExpansionPanelSummary
            style={{ position: 'relative' }}
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <GroupIcon style={{ color: 'white', fontWeight: 'bold' }} color={currentGroup.color}>{currentGroup && currentGroup.name ? currentGroup.name.slice(0, 1).toUpperCase() : ''}</GroupIcon>
            <Typography color="textSecondary" variant="body1">{groupKey}<GroupIcon color="white" style={{ color: 'black', marginLeft: 8, fontSize: '0.7rem' }}>+{groupedAssets[groupKey].length}</GroupIcon></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            {
              mapAssets(groupedAssets[groupKey])
            }
          </ExpansionPanelDetails>
        </InnerExpansionPanel>
      );
    });
  const mapReports = () => {
    if (reportInfoLoading) {
      return (
        <div style={{
          width: '100%', height: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: 1,
        }}
        >
          <CircularProgress />
        </div>
      );
    }
    let selectedReports = [];

    try {
      if (selectedReportTab === 'average') {
        selectedReports = reportInfoData.GetDailyDistance.resultArr.sort((prev, next) => moment(next.date) - moment(prev.date));
      } else if (selectedReportTab === 'position') {
        selectedReports = reportInfoData.GetMyPlaces.messages.sort((prev, next) => moment(next.createdAt) - moment(prev.createdAt));

        if (sort && sort.type === 'createdAt') {
          selectedReports = selectedReports.sort((prev, next) => {
            let actPrev = next;
            let actNext = prev;

            if (sort.direction === 'asc') {
              actPrev = prev;
              actNext = next;
            }
            if (sort.type === 'createdAt') {
              return new Date(actPrev.createdAt) - new Date(actNext.createdAt);
            }
            return 0;
          });
        }
      } else if (selectedReportTab === 'event') {
        selectedReports = reportInfoData.GetMessages.messages.sort((prev, next) => moment(next.createdAt) - moment(prev.createdAt));

        if (sort) {
          selectedReports = selectedReports.sort((prev, next) => {
            let actPrev = next;
            let actNext = prev;

            if (sort.direction === 'asc') {
              actPrev = prev;
              actNext = next;
            }
            if (sort.type === 'createdAt') {
              return new Date(actPrev.createdAt) - new Date(actNext.createdAt);
            }
            if (sort.type === 'kind') {
              return (messageKindMap[actPrev.kind].status).localeCompare(messageKindMap[actNext.kind].status);
            }
            return 0;
          });
        }
      }

      // console.log(selectedReports);
    } catch (error) {
      // console.log(error);
      selectedReports = [];
    }

    if (!selectedReports.length) {
      return (
        <div style={{
          width: '100%', height: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: 1,
        }}
        >
          <CancelPresentation />
          <Typography variant="h6">No reports found.</Typography>
          <Typography variant="body1">If there&apos;s any problem, please contact us.</Typography>
        </div>
      );
    } if (selectedReportTab === 'average') {
      return (
        selectedReports.sort((prev, next) => moment(next.date) - moment(prev.date)).map(report => (
          <AverageReportLayout isMobile={isMobile}>
            <Typography>
              {
                moment(report.date).format('YYYY/MM/DD')
              }
            </Typography>
            <Typography>
              {parseFloat(report.km).toFixed(2)}km
            </Typography>
            {
              !isMobile && (
                <Typography>
                  {parseFloat(report.feet).toFixed(2)}feet
                </Typography>
              )
            }
            <Typography>
              {parseFloat(report.speed).toFixed(2)} knot
            </Typography>
            {
              !isMobile && (
                <Typography>
                  {(parseFloat(report.speed) * 1.852).toFixed(2)} km/h
                </Typography>
              )
            }
          </AverageReportLayout>
        ))
      );
    } if (selectedReportTab === 'position') {
      return (
        selectedReports.map((report) => {
          let io1; let io2; let io3; let
            io4;
          if (report.ips && report.ips.length) {
            io1 = report.ips.find(ip => ip.port_num === 1);
            io2 = report.ips.find(ip => ip.port_num === 2);
            io3 = report.ips.find(ip => ip.port_num === 3);
            io4 = report.ips.find(ip => ip.port_num === 4);
          }
          const utcTime = getUTCTime(report.place, { separateDateHour: true });
          return (
            <PositionReportLayout isMobile={isMobile}>
              <Typography>
                {
                  moment(report.createdAt).format('YYYY/MM/DD')
                }
                <br />
                {
                  moment(report.createdAt).format('HH:mm:ss')
                }
              </Typography>
              {
                !isMobile && (
                  <Typography>
                    {
                      report.place && utcTime
                      && (
                        <>
                          {
                            utcTime[0]
                          }
                          <br />
                          {
                            utcTime[1]
                          }
                        </>
                      )
                      || '-'
                    }
                  </Typography>
                )
              }
              <Typography>
                {report.place && report.place.lat ? `${parseFloat(report.place.lat).toFixed(2)}°` : '-'}
              </Typography>
              <Typography>
                {report.place && report.place.lng ? `${parseFloat(report.place.lng).toFixed(2)}°` : '-'}
              </Typography>
              <Typography>
                {report.place && report.place.alt && `${parseFloat(mapOption.altUnit !== 'feet' ? report.place.alt : report.place.alt * 3.281).toFixed(2)}${mapOption.altUnit !== 'feet' ? 'm' : 'feet'}` || '-'}
              </Typography>
              <Typography>
                {report.place && report.place.speed && `${parseFloat(mapOption.speedUnit !== 'km/h' ? report.place.speed : report.place.speed * 1.852).toFixed(2)}${mapOption.speedUnit}` || '-'}
              </Typography>
              <Typography>
                {report.place && report.place.course && `${report.place.course}°` || '-'}
              </Typography>
              {!isMobile && (
                <>
                  <Typography>
                    {
                      io1
                      && (
                        <>
                          Out: {io1.out_status}<br />
                          IO: {io1.io_status}
                        </>
                      )
                      || '-'
                    }
                  </Typography>
                  <Typography>
                    {
                      io2
                      && (
                        <>
                          Out: {io2.out_status}<br />
                          IO: {io2.io_status}
                        </>
                      )
                      || '-'
                    }
                  </Typography>
                  <Typography>
                    {
                      io3
                      && (
                        <>
                          Out: {io3.out_status}<br />
                          IO: {io3.io_status}
                        </>
                      )
                      || '-'
                    }
                  </Typography>
                  <Typography>
                    {
                      io4
                      && (
                        <>
                          Out: {io4.out_status}<br />
                          IO: {io4.io_status}
                        </>
                      )
                      || '-'
                    }
                  </Typography>
                </>
              )}
            </PositionReportLayout>
          );
        })
      );
    } if (selectedReportTab === 'event') {
      return (
        selectedReports.map((report, index) => {
          let currentReportKind = messageKindMap[report.kind] || {};

          if (typeof (currentReportKind) === 'function') {
            currentReportKind = currentReportKind(report);
          }
          return (
            <EventReportLayout isMobile={isMobile} style={{ backgroundColor: currentReportKind.color, opacity: index % 2 === 0 ? 0.8 : 1 }}>
              <Typography>
                {
                  moment(report.createdAt).format('YYYY/MM/DD HH:mm:ss')
                }
              </Typography>
              <Typography>
                {
                  currentReportKind.status
                }
              </Typography>
              <Typography>
                {
                  currentReportKind.event
                }
              </Typography>
            </EventReportLayout>
          );
        })
      );
    }
  };

  // Use another user list query for administrator users.

  const [isAdminInitialized, setIsAdminInitialized] = useState(false);
  const [allUserList, setAllUserList] = useState(null);

  const [getAllUsers] = useLazyQuery(GET_WHOLETREE_WHICHIS, {
    onCompleted: (data) => {
      // console.log('GETALLUSER', data);
      const uwPipes = [];
      const findUWPipe = (user) => {
        if (user.uwpipe && user.uwpipe.length) {
          uwPipes.push(...user.uwpipe);
        }

        if (user.usersAsUser && user.usersAsUser.length) {
          user.usersAsUser.forEach(innerUser => findUWPipe(innerUser));
        }
      };
      if (data.GetUsers.ok && data.GetUsers.users) {
        setAllUserList(data.GetUsers.users);
        if (data.GetUsers.users.length) {
          data.GetUsers.users.map(user => findUWPipe(user));

          if (uwPipes.length) {
            if (recentAssets && recentAssets.length) {
              // console.log('INRECENT', recentAssets, uwPipes);
              const foundLastAsset = uwPipes.find(asset => asset.id === recentAssets[recentAssets.length - 1]);

              if (foundLastAsset) {
                loadAsset(foundLastAsset);
              }
            }
          }
        }
      } else {
        alert('Failed to fetch user list. Please check server status or connection status.');
      }
    },
    fetchPolicy: 'no-cache',
  });

  const initializeAllUserData = async () => {
    // console.log('EFFECT', isAdminInitialized);
    if (isAdminInitialized) return;

    setIsAdminInitialized(true);
    // console.log('INVOKE: getAllUsers');
    await getAllUsers();
  };

  useEffect(() => {
    if (profileData && ['administrator', 'agent'].includes(profileData.role)) {
      initializeAllUserData();
    }
  });

  const loadAdminAsset = (targetAsset) => {
    const foundOriginAsset = assets.find(asset => asset.id === targetAsset.id);

    if (foundOriginAsset) return loadAsset(foundOriginAsset);

    return loadAsset(targetAsset);
  };

  const mapUsersAsUser = (usersAsUser, level, noFilter) => {
    // console.log(usersAsUser);
    if (assetSearchKeyword && !noFilter) {
      const uwPipes = [];

      const findUwPipes = (user) => {
        if (user.uwpipe && user.uwpipe.length) {
          uwPipes.push(...user.uwpipe);
        }

        if (user.usersAsUser && user.usersAsUser.length) {
          user.usersAsUser.forEach(findUwPipes);
        }
      };
      usersAsUser.forEach(findUwPipes);
      return uwPipes.filter(whichi => whichi.name && whichi.name.includes(assetSearchKeyword)).map(whichi => <AssetListItem selected={selectedAsset && selectedAsset.id === whichi.id} onClick={() => loadAdminAsset(whichi)} variant="text">{whichi.name}</AssetListItem>);
    }
    if (level > 1) {
      return usersAsUser.map((user) => {
        if (!user.usersAsUser || !user.usersAsUser.length) return null;
        if (level === 3) {
          let isLeafExist = false;

          user.usersAsUser.forEach((child) => {
            if (child.usersAsUser && child.usersAsUser.length) {
              isLeafExist = true;
            }
          });

          if (!isLeafExist) {
            return null;
          }
        }
        return (
          <AdminInnerExpansionPanel>
            <ExpansionPanelSummary
              style={{ position: 'relative' }}
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography color="textSecondary" variant="body1">{user.nickname || getFullName(user)}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              {
                mapUsersAsUser(user.usersAsUser, level - 1)
              }
            </ExpansionPanelDetails>
          </AdminInnerExpansionPanel>
        );
      });
    }

    return usersAsUser.filter(user => user.uwpipe && user.uwpipe.length).map((assetUser) => {
      const currentUserWhichi = assetUser.uwpipe[0];

      return (
        <AssetListItem selected={selectedAsset && selectedAsset.id === currentUserWhichi.id} onClick={() => loadAdminAsset(currentUserWhichi)} variant="text">{currentUserWhichi.name}</AssetListItem>
      );
    });
  };

  // END OF CODES FOR ADMINISTRATOR USER LIST QUERY //

  useEffect(() => {
    if (!isInitialized && profileData) {
      setInitialized(true);
      const currentRecentAssets = localStorage.getItem(`${profileData ? profileData.id : 'null'}whichiten-asset-recent`) ? JSON.parse(localStorage.getItem(`${profileData ? profileData.id : 'null'}whichiten-asset-recent`)) : [];

      setRecentAssets(currentRecentAssets);

      if (assets && assets.length) {
        if (currentRecentAssets && currentRecentAssets.length) {
          const foundLastAsset = assets.find(asset => asset.id === currentRecentAssets[currentRecentAssets.length - 1]);

          if (foundLastAsset) {
            loadAsset(foundLastAsset);
          }
        }
      }
    }
  });

  useEffect(() => {
    if (assets && !isPreSelected && query.get('selected')) {
      setIsPreSelected(true);
      const preSelectAsset = assets.find(asset => asset.id === parseFloat(query.get('selected')));
      if (preSelectAsset) {
        loadAsset(preSelectAsset);
      }
    }
  });

  // Mobile


  return (
    <main
      className={clsx(classes.content, {
        [classes.contentShift]: open,
      })}
      style={isRightAssetEnabled && !isMobile ? {} : { paddingRight: 0, position: 'relative' }}
    >
      <Contents isMobile={isMobile}>
        {
          isMobile && (
            <>
              <MobileAssetListSearch onClick={() => setShowMobileAssetList(true)}>
                <Search />
                <InputBase
                  value={assetSearchKeyword}
                  onChange={(event) => {
                    setAssetSearchKeyword(event.target.value);
                  }}
                />
                {
                  showMobileAssetList && (
                  <Close
                    onClick={(event) => {
                      preventDefault(event);
                      setShowMobileAssetList(false);
                    }}
                  />
                  )
                  || <List />
                }
              </MobileAssetListSearch>
              {
                showMobileAssetList && (
                  <MobileAssetList>
                    {
                      allUserList
                      && allUserList.map(admin => (
                        <AdminOuterExpansionPanel defaultExpanded>
                          <ExpansionPanelSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography color="textSecondary" variant="h6">{!assetSearchKeyword && 'User List' || 'Search Result'}</Typography>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            {
                              admin.usersAsUser && admin.usersAsUser.length
                              && mapUsersAsUser(admin.usersAsUser, profileData.role === 'administrator' ? 3 : 2) || false
                            }
                          </ExpansionPanelDetails>
                        </AdminOuterExpansionPanel>
                      ))
                      || profileData && !['administrator', 'agent'].includes(profileData.role)
                      && (
                        <OuterExpansionPanel style={{ margin: 0 }} defaultExpanded>
                          <ExpansionPanelSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography color="textSecondary" variant="h6">All</Typography>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            {
                              loading
                              && (
                              <div style={{
                                width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 144,
                              }}
                              >
                                <CircularProgress style={{ color: 'white' }} />
                              </div>
                              )
                              || mapGroupedAssets()
                            }
                          </ExpansionPanelDetails>
                        </OuterExpansionPanel>
                      )
                      || (
                        <div style={{
                          width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 144,
                        }}
                        >
                          <CircularProgress style={{ color: 'white' }} />
                        </div>
                      )
                    }
                    <OuterExpansionPanel style={{ margin: 0 }} defaultExpanded>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography color="textSecondary" variant="h6">Recent</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        {
                          !loading
                          && mapAssets(getRecentAssets())
                        }
                      </ExpansionPanelDetails>
                    </OuterExpansionPanel>
                  </MobileAssetList>
                )
              }
            </>
          ) || (
            <ContentLeft>
              {
                allUserList
                && allUserList.map(admin => (
                  <AdminOuterExpansionPanel defaultExpanded>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography color="textSecondary" variant="h6">{!assetSearchKeyword && 'User List' || 'Search Result'}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      {
                        admin.usersAsUser && admin.usersAsUser.length
                        && mapUsersAsUser(admin.usersAsUser, profileData.role === 'administrator' ? 3 : 2) || false
                      }
                    </ExpansionPanelDetails>
                  </AdminOuterExpansionPanel>
                ))
                || profileData && !['administrator', 'agent'].includes(profileData.role)
                && (
                  <OuterExpansionPanel defaultExpanded>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography color="textSecondary" variant="h6">All</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      {
                        loading
                        && (
                        <div style={{
                          width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 144,
                        }}
                        >
                          <CircularProgress style={{ color: 'white' }} />
                        </div>
                        )
                        || mapGroupedAssets()
                      }
                    </ExpansionPanelDetails>
                  </OuterExpansionPanel>
                )
                || (
                  <div style={{
                    width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 144,
                  }}
                  >
                    <CircularProgress style={{ color: 'white' }} />
                  </div>
                )
              }
              <OuterExpansionPanel defaultExpanded>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography color="textSecondary" variant="h6">Recent</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  {
                    !loading
                    && mapAssets(getRecentAssets())
                  }
                </ExpansionPanelDetails>
              </OuterExpansionPanel>
            </ContentLeft>
          )
        }
        <ContentRight isMobile={isMobile}>
          <RightInner isMobile={isMobile}>
            {
              selectedAsset
              && (
              <ReportInner isMobile={isMobile}>
                <div>
                  <WichiTabs padding={32} value={selectedReportTab} onChange={(event, newValue) => setSelectedReportTab(newValue)}>
                    <WichiTab minWidth="120px" style={{ color: '#fff' }} value="event" label="Event" />
                    <WichiTab minWidth="120px" style={{ color: '#fff' }} value="average" label="Average" />
                    <WichiTab minWidth="120px" style={{ color: '#fff' }} value="position" label="Position" />
                  </WichiTabs>
                </div>
                {
                  !isMobile
                  && (
                    <ReportAssetHeader>
                      <Typography>
                        <span>
                          <GroupIcon
                            group={selectedAsset.whichigroup}
                          >
                            {selectedAsset.whichigroup && firstUpperLetter(selectedAsset.whichigroup.name)}
                          </GroupIcon>
                          {
                            selectedAsset.whichigroup
                            && selectedAsset.whichigroup.name
                            || 'Ungroup'
                          }
                          <KeyboardArrowRight />
                        </span>
                        <span>
                          {
                            selectedAsset.name
                          }
                        </span>
                        {
                          selectedReportTab === 'event'
                          && <WichiPrimaryButton href={`${API_HOST}/event_download?whichiid=${selectedAsset && selectedAsset.id}&from=${selectedStartDate && selectedStartDate.toISOString()}&to=${selectedEndDate && selectedEndDate.toISOString()}`} width="160px">Export Excel File</WichiPrimaryButton>
                          || selectedReportTab === 'average' && <WichiPrimaryButton href={`${API_HOST}/distance_download?whichiid=${selectedAsset && selectedAsset.id}&from=${selectedStartDate && selectedStartDate.toISOString()}&to=${selectedEndDate && selectedEndDate.toISOString()}`} width="160px">Export Excel File</WichiPrimaryButton>
                          || selectedReportTab === 'position' && <WichiPrimaryButton href={`${API_HOST}/position_download?whichiid=${selectedAsset && selectedAsset.id}&from=${selectedStartDate && selectedStartDate.toISOString()}&to=${selectedEndDate && selectedEndDate.toISOString()}`} width="160px">Export Excel File</WichiPrimaryButton>
                        }
                      </Typography>
                    </ReportAssetHeader>
                  )
                }
                <ReportAssetFilters>
                  <ReportAssetTimeline>
                    <DatePicker
                      label="Start Date"
                      value={selectedStartDate}
                      onChange={date => setStartDate(date)}
                      format="YYYY/MM/DD"
                      placeholder="Start Date"
                    />
                  </ReportAssetTimeline>
                  <ReportAssetTimeline>
                    <DatePicker
                      label="End Date"
                      color="secondary"
                      value={selectedEndDate}
                      onChange={date => setEndDate(moment(date.format('YYYY/MM/DD 23:59:59')))}
                      format="YYYY/MM/DD"
                      placeholder="End Date"
                    />
                  </ReportAssetTimeline>
                  <GrowPadder />
                  {!isMobile && <WichiPrimaryButton onClick={refetchReportInfo} width="160px">Applys</WichiPrimaryButton>}
                </ReportAssetFilters>
                {isMobile && <WichiPrimaryButton onClick={refetchReportInfo} width="calc(100% - 16px)" style={{ marginBottom: 12 }}>Applys</WichiPrimaryButton>}
                <ReportListHeader>
                  {
                    selectedReportTab === 'event'
                    && (
                    <EventReportLayout style={{ height: '100%' }} isMobile={isMobile}>
                      <Typography
                        style={{
                          cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'left',
                        }}
                        onClick={() => changeSort('createdAt')}
                        color="textSecondary"
                      >
                        Date
                        {
                          sort && sort.type === 'createdAt'
                          && (
                            sort.direction === 'desc'
                            && <KeyboardArrowDown />
                            || <KeyboardArrowUp />
                          )
                          || <KeyboardArrowDown style={{ opacity: 0.4 }} />
                        }
                      </Typography>
                      <Typography
                        style={{
                          cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'left',
                        }}
                        onClick={() => changeSort('kind')}
                        color="textSecondary"
                      >
                        {!isMobile && 'Event '}Status
                        {
                          sort && sort.type === 'kind'
                          && (
                            sort.direction === 'desc'
                            && <KeyboardArrowDown />
                            || <KeyboardArrowUp />
                          )
                          || <KeyboardArrowDown style={{ opacity: 0.4 }} />
                        }
                      </Typography>
                      <Typography color="textSecondary">
                        Description
                      </Typography>
                    </EventReportLayout>
                    )
                    || selectedReportTab === 'average'
                    && (
                    <AverageReportLayout style={{ height: '100%' }} isMobile={isMobile}>
                      <Typography color="textSecondary">
                        Date
                      </Typography>
                      <Typography color="textSecondary">
                        Distance Moved {!isMobile && '(km)'}
                      </Typography>
                      {
                        !isMobile && (
                          <Typography color="textSecondary">
                            Distance Moved (feet)
                          </Typography>
                        )
                      }
                      <Typography color="textSecondary">
                        Average Speed {!isMobile && '(knot)'}
                      </Typography>
                      {
                        !isMobile && (
                          <Typography color="textSecondary">
                            Average Speed (km/h)
                          </Typography>
                        )
                      }
                    </AverageReportLayout>
                    )
                    || selectedReportTab === 'position'
                    && (
                    <PositionReportLayout isMobile={isMobile}>
                      <Typography
                        style={{
                          cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'left', height: '100%',
                        }}
                        onClick={() => changeSort('createdAt')}
                        color="textSecondary"
                      >
                        Date
                        {
                          sort && sort.type === 'createdAt'
                          && (
                            sort.direction === 'desc'
                            && <KeyboardArrowDown />
                            || <KeyboardArrowUp />
                          )
                          || <KeyboardArrowDown style={{ opacity: 0.4 }} />
                        }
                      </Typography>
                      {
                        !isMobile && (
                          <Typography color="textSecondary">
                            UTC
                          </Typography>
                        )
                      }
                      <Typography color="textSecondary">
                        {isMobile && 'Lat' || 'Latitude'}
                      </Typography>
                      <Typography color="textSecondary">
                        {isMobile && 'Lng' || 'Longitude'}
                      </Typography>
                      <Typography color="textSecondary">
                        {isMobile && 'Alt' || 'Altitude'}
                      </Typography>
                      <Typography color="textSecondary">
                        Speed
                      </Typography>
                      <Typography color="textSecondary">
                        Course
                      </Typography>
                      {
                        !isMobile && (
                          <>
                            <Typography color="textSecondary">
                              IO1
                            </Typography>
                            <Typography color="textSecondary">
                              IO2
                            </Typography>
                            <Typography color="textSecondary">
                              IO3
                            </Typography>
                            <Typography color="textSecondary">
                              IO4
                            </Typography>
                          </>
                        )
                      }
                    </PositionReportLayout>
                    )
                  }
                </ReportListHeader>
                <ReportList>
                  {
                    mapReports()
                  }
                </ReportList>
              </ReportInner>
              )
              || (
                <div style={{
                  width: '100%', height: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: 1,
                }}
                >
                  <Pageview style={{ fontSize: 48, color: 'white' }} />
                  <Typography color="textSecondary">Please select the asset from left navigation.</Typography>
                </div>
              )
            }
          </RightInner>
        </ContentRight>
      </Contents>
    </main>
  );
}
